const EnvVars = {
  API_URL: 'API_URL',
  PORTAL_API_URL: 'PORTAL_API_URL',
  CLIENT_API_BASE: 'CLIENT_API_BASE',
  LD_CLIENT_ID: 'LD_CLIENT_ID',
  LD_FAKE_USER_ID: 'LD_FAKE_USER_ID',
  COOKIE_DOMAIN: 'COOKIE_DOMAIN',
  LOGIN_URL: 'LOGIN_URL',
  GA_ACCOUNT_ID: 'GA_ACCOUNT_ID',
  GTM_ACCOUNT_ID: 'GTM_ACCOUNT_ID',
  PENDO_APP_ID: 'PENDO_APP_ID',
  GITLAB_MERGE_ID: 'GITLAB_MERGE_ID',
  GITLAB_PROJECT_ID: 'GITLAB_PROJECT_ID',
  WORKDAY_TIMESHEET_URL: 'WORKDAY_TIMESHEET_URL',
  MY_APPS_URL: 'MY_APPS_URL',
  CLIENT_URL: 'CLIENT_URL',
  COOKIE_PRO_ID: 'COOKIE_PRO_ID',
  CLIENT_BENCH_URL: 'CLIENT_BENCH_URL',
  CLIENT_FIND_MORE_TALENT: 'CLIENT_FIND_MORE_TALENT',
  LOGGLY_CLIENT_ID: 'LOGGLY_CLIENT_ID',
  ENVIRONMENT_NAME: 'ENVIRONMENT_NAME',
  ENABLE_LOGGING: 'ENABLE_LOGGING',
  END_OF_OPPORTUNITY_EMAIL: 'END_OF_OPPORTUNITY_EMAIL',
  ENVOY_URL: 'ENVOY_URL',
  S3_PUBLIC_FILE_BUCKET: 'S3_PUBLIC_FILE_BUCKET',
  S3_PUBLIC_BASE_URL: 'S3_PUBLIC_BASE_URL',
  VIEW_WORK_FEED_MAX_ROLL_OFF_DAYS: 'VIEW_WORK_FEED_MAX_ROLL_OFF_DAYS',
  GOOGLE_MAPS_API_URL: 'GOOGLE_MAPS_API_URL',
  CLEARBIT_AUTOCOMPLETE_API_URL: 'CLEARBIT_AUTOCOMPLETE_API_URL',
  CLEARBIT_LOGO_URL: 'CLEARBIT_LOGO_URL',
  WEEKLY_WORK_FEED_NOTIFICATIONS_DAY: 'WEEKLY_WORK_FEED_NOTIFICATIONS_DAY',
  WORK_FEED_MAX_INTERESTED_LIMIT: 'WORK_FEED_MAX_INTERESTED_LIMIT',
  ENABLE_SUGGESTIONS: 'ENABLE_SUGGESTIONS',
  REFER_CLIENT_URL: 'REFER_CLIENT_URL',
  REFER_LAWYER_URL: 'REFER_LAWYER_URL',
  ENABLE_CALENDAR_EVENTS_TODAY: 'ENABLE_CALENDAR_EVENTS_TODAY',
};
// decode query string special characters because something (yaml parser?) encodes them
const decodeEnvUrl = url => {
  if (!url) return '';
  return url.replaceAll(/(&#x3D;)/g, '=').replaceAll(/(&amp;)/g, '&');
};
let envs = {};
const EnvUtil = {
  initialize(env) {
    envs = this.pluckAllowedFrom(env);
  },

  getAllowedFrontendProcessVars() {
    return Object.values(EnvVars);
  },

  pluckAllowedFrom(obj) {
    const allowedProps = {};
    this.getAllowedFrontendProcessVars().forEach(propName => {
      allowedProps[propName] = obj[propName] || null;
    });

    return allowedProps;
  },

  get cookieDomain() {
    return envs[EnvVars.COOKIE_DOMAIN];
  },

  get apiUrl() {
    return envs[EnvVars.API_URL];
  },

  get apiPortalUrl() {
    return envs[EnvVars.PORTAL_API_URL];
  },

  get clientApiBase() {
    return envs[EnvVars.CLIENT_API_BASE] || '/api';
  },

  get loginUrl() {
    return envs[EnvVars.LOGIN_URL];
  },

  get envoyUrl() {
    return envs[EnvVars.ENVOY_URL];
  },

  get gaAccountId() {
    return envs[EnvVars.GA_ACCOUNT_ID];
  },

  get gtmAccountId() {
    return envs[EnvVars.GTM_ACCOUNT_ID];
  },

  get pendoAppId() {
    return envs[EnvVars.PENDO_APP_ID];
  },

  get clientBenchUrl() {
    return envs[EnvVars.CLIENT_BENCH_URL];
  },

  get logglyClientId() {
    return envs[EnvVars.LOGGLY_CLIENT_ID];
  },

  get environmentName() {
    return envs[EnvVars.ENVIRONMENT_NAME];
  },

  get myAppsUrl() {
    return envs[EnvVars.MY_APPS_URL];
  },

  get enableLogging() {
    return (
      envs[EnvVars.ENABLE_LOGGING] === true ||
      envs[EnvVars.ENABLE_LOGGING] === 'true'
    );
  },

  get endOfOpportunityEmail() {
    return envs[EnvVars.END_OF_OPPORTUNITY_EMAIL];
  },

  get publicS3Bucket() {
    return envs[EnvVars.S3_PUBLIC_FILE_BUCKET];
  },

  get publicBaseUrlS3() {
    return envs[EnvVars.S3_PUBLIC_BASE_URL];
  },

  get viewWorkFeedMaxRollOffDays() {
    return Number.parseInt(envs[EnvVars.VIEW_WORK_FEED_MAX_ROLL_OFF_DAYS], 10);
  },

  get googleMapsApiUrl() {
    return decodeEnvUrl(envs[EnvVars.GOOGLE_MAPS_API_URL]);
  },

  get clearbitAutocompleteApiUrl() {
    return decodeEnvUrl(envs[EnvVars.CLEARBIT_AUTOCOMPLETE_API_URL]);
  },

  get clearbitLogoUrl() {
    return decodeEnvUrl(envs[EnvVars.CLEARBIT_LOGO_URL]);
  },

  get workFeedMaxInterestedLimit() {
    return envs[EnvVars.WORK_FEED_MAX_INTERESTED_LIMIT];
  },

  get enableSuggestions() {
    return (
      envs[EnvVars.ENABLE_SUGGESTIONS] === true ||
      envs[EnvVars.ENABLE_SUGGESTIONS] === 'true'
    );
  },

  get referClientUrl() {
    return envs[EnvVars.REFER_CLIENT_URL];
  },

  get referLawyerUrl() {
    return envs[EnvVars.REFER_LAWYER_URL];
  },

  get enableCalendarEventsToday() {
    return (
      envs[EnvVars.ENABLE_CALENDAR_EVENTS_TODAY] === true ||
      envs[EnvVars.ENABLE_CALENDAR_EVENTS_TODAY] === 'true'
    ).toString();
  },
};

try {
  EnvUtil.initialize(window?.ENV || {});
  // eslint-disable-next-line no-empty
} catch {}
// eslint-disable-next-line unicorn/prefer-module
module.exports = {
  EnvUtil,
};
