export const RolesUtil = {
  isAllowed(user, roles) {
    if (!user) {
      return false;
    }

    if (
      Array.isArray(roles) &&
      roles.length > 0 &&
      !user.roles.some(role => roles.includes(role))
    ) {
      return false;
    }

    return true;
  },
};
