import { LocalStorage } from '@axiom/ui';

const STORE_NAMES = {
  workFeedFilters: 'work_feed_filters',
};

export const localStorageUtil = {
  workFeedFilters: (
    candidateId,
    practiceAreaId = [],
    countries = [],
    talentTypes = []
  ) => {
    if (!candidateId) {
      throw new Error('candidateId is required');
    }

    return LocalStorage(`${STORE_NAMES.workFeedFilters}:${candidateId}:v3`, {
      practiceAreaId,
      countries,
      talentTypes,
    });
  },
};
