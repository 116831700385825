import { Api } from '@axiom/ui';
import { CandidateAlertWorkFeed } from '@axiom/validation';

import { EnvUtil } from '../../../utils/env-util';

class CandidateAlertsWorkFeedApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  readCandidateAlerts(candidateId: string) {
    return super.read<{ data: CandidateAlertWorkFeed[] }>({
      endpoint: `/candidates/${candidateId}/alerts/workFeed`,
      method: 'GET',
    });
  }

  refreshCandidateAlerts(candidateId: string) {
    return super.invalidate(`/candidates/${candidateId}/alerts/workFeed`);
  }

  readCandidateAlert(candidateId: string, alertId: string) {
    return super.read<{ data: CandidateAlertWorkFeed }>({
      endpoint: `/candidates/${candidateId}/alerts/workFeed/${alertId}`,
      method: 'GET',
    });
  }

  refreshCandidateAlert(candidateId: string, alertId: string) {
    return super.invalidate(
      `/candidates/${candidateId}/alerts/workFeed/${alertId}`
    );
  }

  updateCandidateAlert(
    candidateId: string,
    alertId: string,
    body: CandidateAlertWorkFeed
  ) {
    return super.write<{ data: CandidateAlertWorkFeed }>({
      endpoint: `/candidates/${candidateId}/alerts/workFeed/${alertId}`,
      body,
      method: 'PATCH',
    });
  }
}

export const CandidateAlertsWorkFeedApi = new CandidateAlertsWorkFeedApiClass();
