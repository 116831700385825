import cookie from 'component-cookie';

import TokenRefresher from './token-refresher';

export default class CookieTokenRefresher extends TokenRefresher {
  constructor(options) {
    const { cookieDomain } = options;

    options.cookieName = options.cookieName || 'user';

    super({
      ...options,
      initialToken: cookie(options.cookieName),
      newTokenCallback: newToken => {
        const domain =
          typeof cookieDomain === 'function' ? cookieDomain() : cookieDomain;

        cookie(options.cookieName, newToken, { domain, path: '/' });
      },
    });
  }

  getToken() {
    return cookie(this.options.cookieName);
  }
}
