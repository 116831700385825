import { shape, string, bool } from 'prop-types';

import { getLoggedUser } from '../../api/protected/user/user';
import GenericStateHelper from '../../lib/generic-state-helper';

export const CURRENT_USER_NAMESPACE = 'CURRENT_USER';
const stateUser = new GenericStateHelper(CURRENT_USER_NAMESPACE, 'LOADED');

/**
 * Set up this way because of the next.js stuff
 * so we're doing low-level redux actionSet structure
 */
export const setUser = (store, user) => {
  store.dispatch({
    type: stateUser.getActionName(),
    user,
  });
};

export const CurrentUserStore = {
  /**
   * Reducers
   */
  getReducers() {
    return {
      [stateUser.getActionName()]: (state, user) => ({
        ...state,
        ...user,
      }),
    };
  },

  getStateShape() {
    return shape({
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      organizationId: string,
      candidateId: string,
      role: string,
      fullName: string,
      isSSO: bool,
    });
  },

  actionResetUser() {
    return dispatch =>
      getLoggedUser().then(user =>
        dispatch(CurrentUserStore.actionSetUser(user))
      );
  },

  actionSetUser(user) {
    return stateUser.actionSet(user);
  },

  /**
   *
   * Selectors
   */
  selectUser(state) {
    return stateUser.select(state);
  },
};
