import React from 'react';
import { Candidate } from '@axiom/validation';
import { Badge, useApi } from '@axiom/ui';

import { CandidateOpportunitiesApi } from '../../api/protected/candidates/candidate-opportunities';

export const EngagementsActionsCounter: React.FC<{
  candidateId: Candidate['id'];
}> = ({ candidateId }) => {
  const [
    {
      data: { unactionedOpportunitiesCount },
    },
  ] = useApi(
    CandidateOpportunitiesApi.readCandidateEngagementActionsCount(candidateId)
  );
  return unactionedOpportunitiesCount > 0 ? (
    <Badge background="orange" name="unactioned-opportunities-count">
      {unactionedOpportunitiesCount}
    </Badge>
  ) : null;
};
