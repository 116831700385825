import React from 'react';
import { HeaderBarLink } from '@axiom/ui';
import { Link, useLocation } from 'react-router-dom';

export const AuthenticatedHeaderMenuItem = ({
  children,
  name,
  target,
  url,
}: {
  children: React.ReactNode;
  name: string;
  target?: string;
  url: string;
}) => {
  const location = useLocation();
  return (
    <HeaderBarLink selected={location.pathname.includes(url)} name={name}>
      <Link to={url} target={target}>
        {children}
      </Link>
    </HeaderBarLink>
  );
};
