import { Api } from '@axiom/ui';
import { Candidate, Experience } from '@axiom/validation';

import ApiHelper from '../../../lib/api-helper';
import { CANDIDATE_EXPERIENCES_API_NAME } from '../../constants';
import { EnvUtil } from '../../../utils/env-util';

const api = new ApiHelper(CANDIDATE_EXPERIENCES_API_NAME);

class CandidateExperienceApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  readExperiences(candidateId: Candidate['id']) {
    return super.read<{ data: Experience[] }>({
      endpoint: `/candidates/${candidateId}/experiences`,
      method: 'GET',
    });
  }

  refreshExperiences(candidateId: string) {
    return super.invalidate(`/candidates/${candidateId}/experiences`);
  }
}

export const CandidateExperienceApi = new CandidateExperienceApiClass();

export const getExperiences = async (candidateId: Candidate['id']) => {
  const { data } = await api.GET(
    `${EnvUtil.apiPortalUrl}/candidates/${candidateId}/experiences`
  );
  return data;
};
