/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef } from 'react';
import {
  string,
  func,
  arrayOf,
  oneOfType,
  shape,
  number,
  bool,
  any,
} from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import { formatDataTestId } from '../../utils/dataTest';

import {
  DropDownListWrapper,
  KendoDropDownListWrapper,
} from './DropdownListStyles';

const OptionComponent = (li, itemProps) => {
  const liProps = {
    ...li.props,
    'data-value': itemProps.dataItem.value,
  };
  const itemChildren = <span>{li.props.children}</span>;

  return React.cloneElement(li, liProps, itemChildren);
};

const DropdownList = props => {
  const {
    className,
    options,
    dataItemKey,
    dataTest,
    disabled,
    id,
    name,
    onBlur,
    onChange,
    textField,
    valid,
    value,
    appendLocal,
  } = props;
  const dropdownParentRef = useRef();

  const createDataItems = () => {
    if (isEmpty(options) || isObject(options[0])) {
      return options;
    }

    return options.map(val => ({
      label: `${val}`,
      value: val,
    }));
  };

  const handleOnChange = event => {
    onChange(event.target.value.value);
  };

  const handleOnBlur = event => {
    onBlur(get(event.target.value, 'value', null));
  };

  const getValueFromOptions = opts =>
    opts.find(option => option.value === value) || null;

  const data = createDataItems();
  const val = getValueFromOptions(data);
  const dataValue = Array.isArray(val)
    ? val.map(v => v.value).join(',')
    : get(val, 'value', null);

  return (
    <DropDownListWrapper
      disabled={disabled}
      data-test={formatDataTestId(dataTest || name)}
      data-value={dataValue}
      ref={dropdownParentRef}
    >
      <KendoDropDownListWrapper
        {...props}
        className={className}
        data={data}
        dataItemKey={dataItemKey}
        disabled={disabled}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        textField={textField}
        value={val}
        itemRender={OptionComponent}
        popupSettings={{
          animate: false,
          ...(appendLocal ? { appendTo: dropdownParentRef.current } : {}),
        }}
      />
    </DropDownListWrapper>
  );
};

DropdownList.defaultProps = {
  className: null,
  options: [],
  dataItemKey: 'value',
  dataTest: '',
  disabled: false,
  id: null,
  name: undefined,
  onBlur: () => {},
  onChange: () => {},
  textField: 'label',
  valid: true,
  value: undefined,
  appendLocal: false,
};

DropdownList.propTypes = {
  className: string,
  options: arrayOf(oneOfType([string, number, shape({})])),
  dataItemKey: string,
  dataTest: string,
  disabled: bool,
  id: string,
  name: string,
  onBlur: func,
  onChange: func,
  textField: string,
  valid: bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
  appendLocal: bool,
};

export default DropdownList;
