import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  DateUtil,
  CardHeader,
  SmallHeader,
  HeaderLink,
  Gutter,
  CompanyLogo,
  Layout,
  LayoutItem,
  ParagraphHeader,
  Badge,
  Visible,
} from '@axiom/ui';
import { CandidateWorkFeedItem } from '@axiom/validation';

import { EnvUtil } from '../../utils/env-util';
import { WorkFeedUtil, WorkFeedViewsType } from '../../utils/work-feed-util';

export const WorkFeedCardHeader = ({
  workFeedItem,
  view,
}: {
  workFeedItem: CandidateWorkFeedItem;
  view: WorkFeedViewsType;
}) => {
  const location = useLocation();

  return (
    <CardHeader
      Badge={
        workFeedItem.candidateIsInterested && (
          <Badge
            name="INTERESTED_STATUS"
            background="light blue"
            flush="right"
          >{`Interested ${DateUtil.displayDateFullYearShortMonth(
            workFeedItem.addedToPoolAt
          )}`}</Badge>
        )
      }
    >
      <Layout position="middle" horizontalGutter="16px" wrap>
        <Visible only="largeScreen tablet">
          <LayoutItem>
            <CompanyLogo
              name="COMPANY_LOGO_IMAGE"
              src={`${EnvUtil.clearbitLogoUrl}/${workFeedItem.account?.website}`}
              alt={workFeedItem.account?.calculatedName}
            />
          </LayoutItem>
        </Visible>

        <LayoutItem>
          <HeaderLink>
            <SmallHeader name="JOB_NAME">
              {WorkFeedUtil.isSinglePageView(view) ? (
                workFeedItem.opportunity.jobName
              ) : (
                <Link
                  data-test="SPV_LINK"
                  to={`../${workFeedItem.id}`}
                  state={{ from: location }}
                >
                  {workFeedItem.opportunity.jobName}
                </Link>
              )}
            </SmallHeader>
          </HeaderLink>
          <Gutter bottom="8px" />
          <ParagraphHeader name="ACCOUNT_NAME">
            {workFeedItem.opportunity.accountName}
          </ParagraphHeader>
        </LayoutItem>
      </Layout>
    </CardHeader>
  );
};
