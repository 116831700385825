import styled from 'styled-components';
import { Text } from '@axiom/ui';

export const DSVCardWrapper = styled.div`
  & > div > div {
    padding: 15px 40px 0 40px !important;
    margin: 0 !important;
  }
`;
export const DSVDateWrapper = styled(Text)`
  text-transform: none;
`;

export const DSVPositionName = styled.span`
  display: block;
  text-transform: none;
  font-weight: 800;
  padding-bottom: 0.5rem;
`;

export const DSVQuickLinksWrapper = styled.div`
  margin-left: 10px;
`;
