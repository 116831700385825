import { get } from 'lodash';
import { bool, shape, number, string, arrayOf } from 'prop-types';

/**
 * USAGE
 * When performing a GET call to the server and expecting an array of objects as the payload
 *
 * const DONUTS_NAMESPACE = 'DONUTS';
 * const stateDonuts = new CollectionStateHelper(
 *  DONUTS_NAMESPACE,
 *  'FETCH' // This distinguishes this state in case other fetch collection states have to be added
 * );
 * export const DonutStore = {
 *    getReducers() {
 *      return {
 *        ...stateDonuts.generateReducers(), // Makes all redux reducers associated with the fetchCollection action
 *      }
 *    },
 *    // If you need to pull donuts from the server
 *    actionFetchDonuts() {
 *      return stateDonuts.fetchCollection(getDonuts()); // getDonuts() is an api call that returns [{}, {}]
 *    },
 *    // If you need to set donuts to the store manually
 *    actionSetDonuts(donuts) {
 *      return stateDonuts.setCollection(donuts);
 *    },
 *    selectDonutState(state) {
 *      return stateDonuts.select(state); // Pulls the root state from the store, which you pass to CollectionStateHelperTreatment
 *    },
 *    selectDonuts(state) {
 *      return stateDonuts.selectData(state); // Pulls donuts from the data prop of the store
 *    }
 * }
 *
 * REDUCER STATES
 * start fetch, fetch successful, fetch errored
 *
 * COMPATIBLE COMPONENTS
 *
 * CollectionStateHelperTreatment - Handles loading state of data before you use the data
 */

const CollectionStateHelperShapeStructure = {
  isLoading: bool,
  isFetched: bool,
  data: arrayOf(shape({})),
  error: shape({
    statusCode: number,
    errorMsg: string,
  }),
};

export const CollectionStateHelperShape = shape(
  CollectionStateHelperShapeStructure
);

export default class CollectionStateHelper {
  /**
   * @param {string} namespace - Store namespace
   * @param {string} eventName - Event namespace
   */
  constructor(namespace, eventName) {
    this.namespace = namespace;
    this.eventName = eventName;
  }

  generateReducers() {
    return {
      [this._getLoadingActionName()]: state => ({
        ...state,
        isLoading: true,
        data: state.data || [], // For subsequent fetches, we don't want to wipe whats in state
        error: null,
      }),
      [this._getSuccessActionName()]: (state, payload) => ({
        ...state,
        isLoading: false,
        isFetched: true,
        data: payload,
        error: null,
      }),
      [this._getErrorActionName()]: (state, error) => ({
        ...state,
        isLoading: false,
        data: null,
        error,
      }),
    };
  }

  getStructure(dataShape) {
    return {
      ...CollectionStateHelperShapeStructure,
      data: dataShape,
    };
  }

  /**
   * Have the success response put the data array into the store for you
   * @param {Promise} apiCall - Api call to fetch array of objects
   */
  fetchCollection(apiCall) {
    return dispatch => {
      dispatch({ type: this._getLoadingActionName() });

      apiCall
        .then(data => {
          dispatch(this._getSuccessAction(data));
        })
        .catch(e => {
          dispatch({ type: this._getErrorActionName(), error: e });
        });
    };
  }

  /**
   * Put the data array into the store manually
   * @param {Object[]} collection - Array of objects to set in the store
   */
  setCollection(collection) {
    if (!Array.isArray(collection)) {
      collection = [];
    }
    return this._getSuccessAction(collection);
  }

  /**
   * @param {Object} state - State object from redux
   * @returns {{}}
   */
  select(state) {
    return { ...get(state, this.namespace) };
  }

  /**
   * @param {Object} state - Data object from store
   * @returns {Object[]}
   */
  selectData(state) {
    return [...(get(state, `${this.namespace}.data`) || [])];
  }

  /**
   * @private
   */
  _getLoadingActionName() {
    return `${this.namespace}_${this.eventName}_START`;
  }

  /**
   * @private
   */
  _getSuccessActionName() {
    return `${this.namespace}_${this.eventName}_SUCCESS`;
  }

  /**
   * @private
   */
  _getErrorActionName() {
    return `${this.namespace}_${this.eventName}_ERROR`;
  }

  /**
   * @private
   */
  _getSuccessAction(payload) {
    return { type: this._getSuccessActionName(), payload };
  }
}
