import React, { useEffect, useState } from 'react';
import { Dropdown } from '@axiom/ui';

import ClearbitApi from '../../api/clearbit';

type ClearbitResponse = {
  name: string;
  domain: string;
};

export const getLabel = (name: string, domain: string) =>
  domain ? `${name} - ${domain}` : name;

export const CompanyTypeahead = ({
  initialValue = {},
  label,
  name,
  onChange,
}: {
  initialValue?: {
    name?: string;
    domain?: string;
  };
  label: string;
  name: string;
  onChange: (payload: ClearbitResponse) => void;
}) => {
  const initialAutoComplete = initialValue.domain
    ? []
    : [
        {
          label: getLabel(initialValue.name, initialValue.domain),
          value: getLabel(initialValue.name, initialValue.domain),
          payload: { name: initialValue.name, domain: initialValue.domain },
        },
      ];
  const [autoCompletions, setAutoCompletions] = useState(initialAutoComplete);

  const onCompanyNameChange = (value: string) => {
    ClearbitApi.getAutoCompletions(value).then(data => {
      const formatted = data.map((ac: ClearbitResponse) => ({
        label: getLabel(ac.name, ac.domain),
        value: getLabel(ac.name, ac.domain),
        payload: { name: ac.name, domain: ac.domain },
      }));
      setAutoCompletions(formatted);
    });
  };

  useEffect(() => {
    if (initialValue.domain) {
      onCompanyNameChange(initialValue.domain);
    }
  }, [initialValue]);

  const handleAddition = (value: string) => {
    setAutoCompletions([
      {
        label: value,
        value: `${value}`,
        payload: { name: value, domain: null },
      },
      ...autoCompletions,
    ]);
  };

  return (
    <Dropdown
      displayKey="label"
      label={label}
      name={name}
      onAddItem={handleAddition}
      onType={onCompanyNameChange}
      options={autoCompletions}
      onChange={(v, e, valueOption) => {
        const option = valueOption as Record<string, unknown>;
        if (valueOption) {
          onChange(option.payload as ClearbitResponse);
        } else {
          onChange({ name: v, domain: null } as ClearbitResponse);
        }
      }}
      valueKey="value"
    />
  );
};
