import { bool, shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { WindowUtil } from '../utils/window-util';

const FEATURE_FLAG_NAMES = {
  enablePendo: 'enable-portal-pendo',
  enablePdfDownloading: 'enable-pdf-downloading',
};

const DEFAULT_FLAGS = {
  [FEATURE_FLAG_NAMES.enablePendo]: true,
  [FEATURE_FLAG_NAMES.enablePdfDownloading]: true,
};

class PreloadedFeatureFlagsStoreClass extends GenericStore {
  bootstrap({ user = {}, candidate = {} } = {}) {
    this.load();
    WindowUtil.initializePendo({
      user,
      candidate,
    });
  }

  getDataShape() {
    return shape({
      [FEATURE_FLAG_NAMES.enablePendo]: bool,
      [FEATURE_FLAG_NAMES.enablePdfDownloading]: bool,
    });
  }

  /**
   *
   * @param flags - comes in as {"enable-envoy-pendo": value}
   */
  load(flags = {}) {
    return this.setState({
      ...DEFAULT_FLAGS,
      ...flags,
    });
  }

  select(state) {
    return super.select(state).data || { ...DEFAULT_FLAGS };
  }

  isPendoEnabled(flags) {
    return flags[FEATURE_FLAG_NAMES.enablePendo];
  }

  isPdfDownloadingEnabled(flags) {
    return flags[FEATURE_FLAG_NAMES.enablePdfDownloading];
  }
}

export const PreloadedFeatureFlagsStore = new PreloadedFeatureFlagsStoreClass();
