/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { func, bool, string, shape } from 'prop-types';
import { RadioIcon } from '@axiom/ui';

import {
  RadioFFLabel,
  RSInputHiddenWrapper,
  StyledSvgContainer,
} from './RadioFFStyles';

export class RadioFF extends React.Component {
  onClick = () => {
    this.props.input.onChange(this.props.input.value);
  };

  render() {
    return (
      <span className={this.props.input.checked ? '' : 'text-muted'}>
        <StyledSvgContainer onClick={this.onClick}>
          <RadioIcon checked={this.props.input.checked} />
        </StyledSvgContainer>
        <RadioFFLabel onClick={this.onClick}>
          {this.props.label}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <RSInputHiddenWrapper {...this.props.input} />
        </RadioFFLabel>
      </span>
    );
  }
}

RadioFF.defaultProps = {
  label: '',
};
RadioFF.propTypes = {
  label: string,
  input: shape({
    onChange: func,
    value: string,
    checked: bool,
  }).isRequired,
  meta: shape({}).isRequired,
};
