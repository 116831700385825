import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

export const NotificationsIconBadge = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 3px;
  right: 3px;
  background: #ff5a2d;
  border-radius: 8px;
  ${MediaQueryUtil.smallScreenOnly(css`
    top: 8px;
  `)}
`;

export const NotificationsIconWrapper = styled.div`
  position: relative;
`;
