import styled from 'styled-components';

export const SliderBackgroundBar = styled.div`
  background-color: ${({ theme }) => theme.neutrals.neutral20};
  width: 100%;
  z-index: 0;
`;

export const SliderForegroundBar = styled.div`
  z-index: 5;
  width: ${({ width }) => width};
  left: 0;
  background-color: ${({ theme }) => theme.colors.axiomOrange};
  transition:
    width 250ms linear,
    left 250ms linear;
`;

export const SliderDotContainer = styled.div`
  left: ${({ left }) => left};
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 12;
  cursor: grab;
`;

export const SliderDot = styled.div`
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.axiomOrange};
  box-shadow: 0 0 4px ${({ theme }) => theme.neutrals.neutral90};
`;

export const SliderLabel = styled.div`
  cursor: pointer;
`;

export const SliderLabelsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 1.5rem;
`;

export const grabbingClass = 'grabbing';

export const SliderContainer = styled.div`
  --dot-radius: ${({ dotRadius }) => dotRadius}px;
  position: relative;
  isolation: isolate;
  height: 4rem;
  margin: 0;

  &.${grabbingClass} > * {
    cursor: grabbing !important;
  }

  ${SliderBackgroundBar},
  ${SliderForegroundBar} {
    height: 6px;
    position: absolute;
    top: calc(var(--dot-radius) - 3px);
    border-radius: 8px;
    cursor: pointer;
  }

  ${SliderDot} {
    width: calc(var(--dot-radius) * 2);
    height: calc(var(--dot-radius) * 2);
  }
`;
