import { shape, arrayOf, string, bool } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';

import FormStateHelper from '../../lib/form-state-helper';
import { updateOpportunityByCandidate } from '../../api/protected/candidates/candidate-opportunities-legacy';
import { AppStore } from '../app-store';

import { CandidateOpportunitiesStore } from './candidate-opportunities-store';

export const FORM_NEW_OPPORTUNITY_YES_NAMESPACE = 'FORM_NEW_OPPORTUNITY_YES';

const formNewOppYesState = new FormStateHelper(
  FORM_NEW_OPPORTUNITY_YES_NAMESPACE,
  'MAIN'
);

export const FormNewOpportunityYesStore = {
  getReducers() {
    return {
      ...formNewOppYesState.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...formNewOppYesState.getStructure(this.getDataShape()),
    });
  },
  getDataShape() {
    return shape({
      opportunityId: string,
      experienceIds: arrayOf(string),
      candidateConflictsOfInterest: bool,
      candidatePlannedTimeOff: string,
      candidateEngagementStartAvailability: string,
      candidateExperienceHighlights: string,
      candidateExploringOpportunities: string,
    });
  },
  actionShowForm(opportunity, candidateExperiences) {
    const oppForm = {
      opportunityId: opportunity.id,
      experienceIds:
        opportunity.experienceIds || candidateExperiences.map(item => item.id),
      candidateConflictsOfInterest: opportunity.candidateConflictsOfInterest,
      candidatePlannedTimeOff: opportunity.candidatePlannedTimeOff,
      candidateEngagementStartAvailability:
        opportunity.candidateEngagementStartAvailability,
      candidateExperienceHighlights: opportunity.candidateExperienceHighlights,
      candidateExploringOpportunities:
        opportunity.candidateExploringOpportunities,
    };
    return formNewOppYesState.showForm(oppForm, opportunity.id);
  },
  actionSetForm(setObj) {
    return formNewOppYesState.setFormData(setObj);
  },
  actionHideForm() {
    return formNewOppYesState.hideForm();
  },
  actionSubmitForm(candidateId, oppYesFormData) {
    const payload = {
      experiences: oppYesFormData.experienceIds.map(id => ({
        id,
        isHighlighted: true,
      })),
      hasNoConflictOfInterest: oppYesFormData.hasNoConflictOfInterest,
      candidateStatus: CandidateOpportunitiesConst.CandidateStatuses.WarmedYes,
      candidatePlannedTimeOffNotes: oppYesFormData.candidatePlannedTimeOffNotes,
      candidateExploringOutsideRoles:
        oppYesFormData.candidateExploringOutsideRoles,
      candidateExperienceNotes: oppYesFormData.candidateExperienceNotes,
      candidateAvailabilityNotes: oppYesFormData.candidateAvailabilityNotes,
    };

    return dispatch =>
      dispatch(
        formNewOppYesState.submitForm(
          updateOpportunityByCandidate(
            candidateId,
            oppYesFormData.opportunityId,
            payload
          )
            .then(() => {
              const bodyText =
                'We will share your profile with the client. You can track the status of this opportunity in Submissions.';
              const headerText = 'Opt In Successful';
              dispatch(
                AppStore.actionShowConfirmDialog({ bodyText, headerText })
              );
              CandidateOpportunitiesStore.load(candidateId);
            })
            .catch(() => {
              dispatch(
                AppStore.actionShowErrorDialog(
                  'Submit Error',
                  'There was an error submitting your data. Please try again later.'
                )
              );
            })
        )
      );
  },

  selectFormState(state) {
    return formNewOppYesState.select(state);
  },
  selectFormData(state) {
    return formNewOppYesState.selectData(state);
  },
};
