import React from 'react';
import { bool } from 'prop-types';

import { RecordStateHelperShape } from '../../lib/record-state-helper';
import { ComponentShape } from '../../utils/prop-type-util';
import { LoadingCurtain } from '../LoadingCurtain/LoadingCurtain';
import { OldStateComponentUtil } from '../../utils/old-state-component-util';
import LoadingGradient from '../LoadingGradient/LoadingGradient';
import { ErrorBlurb } from '../ErrorBlurb/ErrorBlurb';

/**
 * Use this for handling when a RecordState store data is ready for use.
 *
 * Assuming you connect selectors look like this:
 * export default connect(state => ({
    donutState: DonutsStore.selectDonutState(state),
    donut: DonutsStore.selectDonutData(state)
  }))(ListOfDonutsPage);

 *
 * <RecordStateHelperTreatment recordState={donutState}>
 *    {donut.Baker.Name}
 *  </RecordStateHelperTreatment>
 *
 *  This will handle showing the loader while the data is fetched, then load the
 *  child component so that you won't get an undefined on Baker.Name because donut
 *  starts off as {}
 */

const RecordStateHelperTreatment = ({
  recordState,
  children,
  initialLoader,
  refetchLoader,
  showInitialLoader,
  showRefetchLoader,
  errorComponent,
}) => {
  /**
   * Need this console log, else it'll get swallowed.
   */
  if (recordState.error) {
    // eslint-disable-next-line no-console
    console.error(recordState.error);
  }

  return (
    <>
      {OldStateComponentUtil.shouldShowError(recordState) && errorComponent}
      {OldStateComponentUtil.shouldShowInitialLoader(recordState) &&
        showInitialLoader &&
        initialLoader}
      {OldStateComponentUtil.shouldShowBlockingLoader(recordState) &&
        showRefetchLoader &&
        refetchLoader}
      {OldStateComponentUtil.shouldShowComponent(recordState) && children}
    </>
  );
};

RecordStateHelperTreatment.propTypes = {
  recordState: RecordStateHelperShape.isRequired,
  children: ComponentShape,
  initialLoader: ComponentShape,
  refetchLoader: ComponentShape,
  errorComponent: ComponentShape,
  showInitialLoader: bool,
  showRefetchLoader: bool,
};

RecordStateHelperTreatment.defaultProps = {
  children: null,
  initialLoader: <LoadingGradient lines={3} />,
  refetchLoader: <LoadingCurtain />,
  errorComponent: <ErrorBlurb />,
  showInitialLoader: true,
  showRefetchLoader: true,
};

export default RecordStateHelperTreatment;
