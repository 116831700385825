import React, { memo } from 'react';
import orderBy from 'lodash/orderBy';
import { Layout, LayoutItem, Paragraph, ParagraphHeader } from '@axiom/ui';
import { CandidateLegalTechSkill, Taxonomy } from '@axiom/validation';

export const LegalTechSkillsDisplay: React.FC<{
  legalTechSkills: CandidateLegalTechSkill[];
  taxonomy: Taxonomy;
}> = memo(({ legalTechSkills, taxonomy }) => (
  <Layout
    direction="vertical"
    verticalGutter="16px"
    name="LEGAL_TECH_SKILLS_DISPLAY"
  >
    {Object.entries(
      legalTechSkills.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.legalTechCategory]: [
            ...(acc[curr.legalTechCategory] ?? []),
            {
              skill: curr.legalTechSkill,
              sort: curr.legalTechSkill.toLowerCase(),
            },
          ],
        }),
        {} as Record<string, { skill: string; sort: string }[]>
      )
    )
      .sort(([categoryA], [categoryB]) => categoryA.localeCompare(categoryB))
      .map(([category, skillsAndSorts]) => (
        <LayoutItem key={category}>
          <ParagraphHeader name="LEGAL_TECH_SKILL_CATEGORY_NAME">
            {taxonomy.legalTechSkillCategories[category].displayName}
          </ParagraphHeader>
          <Paragraph name={`LEGAL_TECH_SKILLS_LIST_${category}`}>
            {orderBy(skillsAndSorts, ['sort'])
              .map(({ skill }) => taxonomy.legalTechSkills[skill].displayName)
              .join('; ')}
          </Paragraph>
        </LayoutItem>
      ))}
  </Layout>
));
