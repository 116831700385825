import ApiHelper from '../../lib/api-helper';
import { EnvUtil } from '../../utils/env-util';

const api = new ApiHelper('SUBMISSIONS');

export const getSubmission = async submissionId => {
  const { data } = await api.GET(
    `${EnvUtil.apiPortalUrl}/submissions/${submissionId}`
  );
  return data;
};
