import { Api } from '@axiom/ui';

import { EnvUtil } from '../utils/env-util';

class PermissionsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  readPermissions(candidateId: string) {
    return super.read<{
      data: {
        lessonly: boolean;
        seismic: boolean;
        workday: boolean;
        myApps: boolean;
      };
    }>({
      endpoint: `/saml/permissions/${candidateId}`,
      method: 'GET',
    });
  }
}

export const PermissionsApi = new PermissionsApiClass();
