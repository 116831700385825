import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { EngagementsView } from '../components/EngagementsView/EngagementsView';
import { PageLayout } from '../layouts/PageLayout';

export const TalentEngagementsPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks>
      <EngagementsView />
    </PageLayout>
  </TalentDataProvider>
);

TalentEngagementsPage.propTypes = {};
