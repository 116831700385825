import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const SidedrawerSection = ({
  className,
  children,
  name,
}: {
  children?: React.ReactNode;
  className?: string;
  name?: string;
}) => (
  <div
    className={AttrsHelper.formatClassname('sidedrawer-section', className)}
    data-test={name}
  >
    {children}
  </div>
);
