import React from 'react';
import { z } from 'zod';
import {
  Button,
  Gutter,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Form,
  CondensedLarge,
} from '@axiom/ui';
import { Candidate, Opportunity } from '@axiom/validation';

import { WorkFeedUtil, WorkFeedViewsType } from '../../utils/work-feed-util';
import { WorkFeedApi } from '../../api/protected/candidates/candidate-workfeed';
import { CandidateOpportunitiesLegacyApi } from '../../api/protected/candidates/candidate-opportunities-legacy';

const formSchema = z.object({
  candidateId: z.string().uuid(),
  opportunityId: z.string().uuid(),
});

export const WorkFeedRemoveSavedOpportunityModal = ({
  candidateId,
  onClose,
  opportunityId,
  view,
}: {
  candidateId: Candidate['id'];
  onClose: () => void;
  opportunityId: Opportunity['id'];
  view: WorkFeedViewsType;
}) => (
  <Form
    schema={formSchema}
    initialValues={{ candidateId, opportunityId }}
    onSubmit={async () => {
      await CandidateOpportunitiesLegacyApi.deleteWorkFeedSaved(
        candidateId,
        opportunityId
      );
      await WorkFeedApi.refreshAllOfWorkFeed(
        candidateId,
        WorkFeedUtil.isSinglePageView(view) ? opportunityId : null,
        true
      );
      return onClose();
    }}
  >
    {({ fireSubmit }) => (
      <Modal name="REMOVE_SAVED_OPPORTUNITY" size="confirm">
        <ModalHeader onClose={onClose} name="REMOVE_SAVED_OPPORTUNITY_HEADER">
          Remove this opportunity?
        </ModalHeader>
        <ModalSection>
          <Gutter vertical="32px">
            <CondensedLarge className="text-align-center">
              Please confirm that you want to remove this opportunity from your
              saved opportunities. This does not remove it from the opportunity
              feed.
            </CondensedLarge>
          </Gutter>
        </ModalSection>
        <ModalFooter>
          <Button variation="outline" name="CANCEL" onClick={onClose}>
            Cancel
          </Button>

          <Button name="SUBMIT" onClick={fireSubmit}>
            Remove
          </Button>
        </ModalFooter>
      </Modal>
    )}
  </Form>
);
