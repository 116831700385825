import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import moment from 'moment';
import {
  Banner,
  Checkbox,
  DateInput,
  DateUtil,
  Dropdown,
  Flashy,
  Form,
  FormGroup,
  FormLabel,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Input,
  TaxonomyUtil,
  Textarea,
  Location,
  Layout,
} from '@axiom/ui';
import {
  Candidate,
  CandidateProfileIssue,
  Experience,
  ExperienceEditSchema,
  Taxonomy,
} from '@axiom/validation';
import { SchemaLocation, SchemaMonthYear } from '@axiom/types';

import {
  CompanyTypeahead,
  getLabel,
} from '../CompanyTypeahead/CompanyTypeahead';
import { CandidateExperienceApi } from '../../api/protected/candidates/candidate-experience';
import { ExperienceApi } from '../../api/protected/experiences/experience';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

import { ExperienceItemEditLayout } from './ExperienceItemEditLayout';

const formSchema = ExperienceEditSchema.omit({
  industryIsOverridden: true,
}).extend({
  location: SchemaLocation,
  currentlyWorking: z.boolean().nullish(),
  startDate: SchemaMonthYear,
  endDate: SchemaMonthYear.nullish(),
  skills: z.array(z.string()).nullish(),
  startMonthYearGroup: z.string().optional(),
  company: z.string(),
});

export const ExperienceItemNonAxiomEdit = ({
  candidateId,
  experience = {},
  taxonomy,
  issues,
}: {
  candidateId: Candidate['id'];
  experience?: Experience;
  taxonomy: Taxonomy;
  issues: CandidateProfileIssue[];
}) => {
  const navigate = useNavigate();
  const [hasSaved, setHasSaved] = useState(false);
  const { industries, generalSkills } = taxonomy;
  const generalSkillOptions =
    TaxonomyUtil.getGeneralSkillOptions(generalSkills);
  const industryOptions = TaxonomyUtil.getIndustryOptions(industries);
  return (
    <Form
      name="EXPERIENCE_ITEM_NON_AXIOM_EDIT_FORM"
      schema={formSchema}
      initialValues={{
        ...experience,
        candidateId: experience.candidateId ?? candidateId,
        currentlyWorking: !experience.endDate,
        location:
          experience.locationAddressComponents ||
          experience.locationName ||
          experience.locationPlaceId
            ? {
                locationAddressComponents: experience.locationAddressComponents,
                locationName: experience.locationName,
                locationPlaceId: experience.locationPlaceId,
              }
            : null,
        company: getLabel(experience.client, experience.clientDomain),
        tags: experience.tags?.map(t => t.id),
      }}
      onSubmit={async (formData, actions) => {
        const startDate = formData.startDate ?? experience.startDate;
        const endDate = formData.endDate ?? experience.endDate;

        if (moment(startDate).isAfter(moment())) {
          actions.setFieldError(
            'startMonthYearGroup',
            'Start date must be in the past'
          );
          return;
        }

        if (moment(startDate).isAfter(endDate)) {
          actions.setFieldError(
            'startMonthYearGroup',
            'This must be earlier than or equal to end date'
          );
          return;
        }

        const submitData = {
          candidateId,
          ...formData,
          // handles edge case when user changes clientDomain without modification to client
          // ex. Axiom as a new option without domain, then later changed to Axiom from the clearbit response
          ...(formData.clientDomain !== undefined && !formData.client
            ? { client: experience.client }
            : {}),
          ...formData.location,
        };

        if (experience.id) {
          // update experience
          await ExperienceApi.updateExperience(
            experience.id,
            ExperienceEditSchema.partial().parse(submitData)
          );
        } else {
          // create experience
          const response = await ExperienceApi.createExperience(
            candidateId,
            ExperienceEditSchema.partial().parse(submitData)
          );
          if (response.data.id) {
            navigate(`../${response.data.id}`);
          }
        }
        await CandidateExperienceApi.refreshExperiences(candidateId);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidateId
        );
        setHasSaved(true);
      }}
    >
      {({ dirty, fireSubmit, setValues, values }) => (
        <ExperienceItemEditLayout
          dirty={dirty}
          experience={experience}
          hasSaved={hasSaved}
          issues={issues}
          onProfileNav={() => {
            navigate('../../profile');
          }}
          onSave={fireSubmit}
        >
          <CompanyTypeahead
            name="company"
            label="Company Name"
            initialValue={{
              name: values.client,
              domain: values.clientDomain,
            }}
            onChange={payload => {
              setValues({
                ...values,
                client: payload.name,
                clientDomain: payload.domain,
              });
            }}
          />
          <Gutter bottom="16px" />
          <Input name="externalOpportunityName" label="Title" />
          <Gutter bottom="16px" />
          <Location
            name="location"
            label="Location"
            placeholder={experience.locationName}
          />
          <Gutter bottom="16px" />
          <Grid>
            <GridColumn largeScreenWidth={6} tabletWidth={12} mobileWidth={12}>
              <Grid columns={2}>
                <GridRow>
                  <GridColumn width={12}>
                    <DateInput name="startDate" label="Start Date" />
                  </GridColumn>
                  <GridColumn width={12}>
                    {/* for displaying custom error */}
                    <FormGroup name="startMonthYearGroup" />
                  </GridColumn>
                </GridRow>
              </Grid>
            </GridColumn>
            <GridColumn largeScreenWidth={6} tabletWidth={12} mobileWidth={12}>
              <Grid columns={2}>
                <GridColumn width={12}>
                  <DateInput
                    name="endDate"
                    label="End Date"
                    disabled={values.currentlyWorking}
                  />
                </GridColumn>
                <GridColumn width={12}>
                  <Gutter bottom="16px" />
                  <Checkbox
                    name="currentlyWorking"
                    displayValue="I currently work here"
                    onChange={v => {
                      if (v) {
                        setValues({ ...values, endDate: null });
                      } else {
                        setValues({
                          ...values,
                          endDate: DateUtil.formatAsDate(moment().date(1)),
                        });
                      }
                    }}
                  />
                </GridColumn>
              </Grid>
            </GridColumn>
          </Grid>
          <Gutter bottom="16px" />
          <Textarea
            name="description"
            label="Description of work"
            full
            enableSuggestionsFor="portal-ExperienceItemNonAxiomEdit-description"
          />
          <Gutter bottom="16px" />
          <FormLabel>Associated skills</FormLabel>
          <Gutter bottom="8px" />
          <Banner>
            <Flashy bold>Coming soon - new skills!</Flashy> We are developing a
            new skills system that is more accurate and will help clients to
            better understand your strengths and abilities.
          </Banner>
          <Gutter bottom="16px" />
          <FormGroup name="generalSkills" label="General Skills (optional)">
            <Layout horizontalGutter="8px" verticalGutter="8px" wrap>
              {generalSkillOptions.map(({ label, value }) => (
                <Checkbox
                  key={value}
                  name="generalSkills"
                  displayValue={label}
                  option={value}
                  mode="pill"
                />
              ))}
            </Layout>
          </FormGroup>
          <Gutter bottom="16px" />
          <Dropdown
            displayKey="label"
            name="industryValue"
            options={industryOptions}
            valueKey="value"
            label="Industry"
          />
        </ExperienceItemEditLayout>
      )}
    </Form>
  );
};
