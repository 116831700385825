import React from 'react';
import { uniq } from 'lodash';
import {
  Grid,
  GridColumn,
  GridRow,
  CondensedLabel,
  CondensedMedium,
  Gutter,
} from '@axiom/ui';
import { CandidateWorkFeedItem } from '@axiom/validation';

import { PositionUtil } from '../../utils/position-util';
import { DateUtil } from '../../utils/date-util';
import { LocationUtil } from '../../utils/location-util';

export const WorkFeedCardDataPoints = ({
  workFeedItem,
  index,
}: {
  workFeedItem: CandidateWorkFeedItem;
  index: number;
}) => {
  const isUSBased = () => {
    const location = workFeedItem?.opportunity?.locationCityStateCountry ?? '';
    const [country] = location.split(', ').slice(-1);
    return country === 'US';
  };

  const getLanguagesDataPoint = () => {
    const languages = workFeedItem?.opportunity?.languages ?? [];

    const langArr = languages.reduce(
      (acc: string[], curr: { language: { name: string } }) => {
        acc.push(curr.language.name);
        return acc;
      },
      []
    );

    return uniq(langArr).join(', ');
  };

  const shouldShowLanguages =
    !isUSBased() &&
    workFeedItem?.opportunity?.languages &&
    workFeedItem.opportunity.languages.length > 0;

  return (
    <Gutter bottom="16px" name="WORK_FEED_CARD_DATA_POINTS">
      <Grid stackable name="PROJECT_DETAILS_CONTENT">
        <GridRow>
          <GridColumn width={6}>
            <Grid>
              <GridRow>
                <GridColumn
                  largeScreenWidth={6}
                  tabletWidth={6}
                  mobileWidth={12}
                >
                  <CondensedLabel>Estimated Start Date</CondensedLabel>
                </GridColumn>
                <GridColumn
                  largeScreenWidth={4}
                  tabletWidth={4}
                  mobileWidth={12}
                >
                  <CondensedMedium name="ESTIMATED_START_DATE">
                    {DateUtil.displayDate(workFeedItem?.position?.startDate) ??
                      '--'}
                  </CondensedMedium>
                  <Gutter only="mobile" bottom="8px" />
                </GridColumn>
                <GridColumn
                  largeScreenWidth={6}
                  tabletWidth={6}
                  mobileWidth={12}
                >
                  <CondensedLabel>Engagement Length</CondensedLabel>
                </GridColumn>
                <GridColumn
                  largeScreenWidth={4}
                  tabletWidth={4}
                  mobileWidth={12}
                >
                  <CondensedMedium name="ENGAGEMENT_LENGTH">
                    {PositionUtil.getEngagementLength(workFeedItem.position) ??
                      '--'}
                  </CondensedMedium>
                  <Gutter only="mobile" bottom="8px" />
                </GridColumn>
                <GridColumn
                  largeScreenWidth={6}
                  tabletWidth={6}
                  mobileWidth={12}
                >
                  <CondensedLabel>Weekly Commitment</CondensedLabel>
                </GridColumn>
                <GridColumn
                  largeScreenWidth={4}
                  tabletWidth={4}
                  mobileWidth={12}
                >
                  <CondensedMedium name="WEEKLY_COMMITMENT">
                    {PositionUtil.getHoursPerWeek(workFeedItem.position) ??
                      '--'}
                  </CondensedMedium>
                  <Gutter only="mobile" bottom="8px" />
                </GridColumn>
                {shouldShowLanguages && (
                  <>
                    <GridColumn
                      largeScreenWidth={6}
                      tabletWidth={6}
                      mobileWidth={12}
                    >
                      <CondensedLabel>Language</CondensedLabel>
                    </GridColumn>
                    <GridColumn
                      largeScreenWidth={4}
                      tabletWidth={4}
                      mobileWidth={12}
                    >
                      <CondensedMedium name="LANGUAGE">
                        {getLanguagesDataPoint()}
                      </CondensedMedium>
                    </GridColumn>
                  </>
                )}
              </GridRow>
            </Grid>
          </GridColumn>
          <GridColumn width={6}>
            <Grid>
              <GridRow>
                <GridColumn
                  largeScreenWidth={4}
                  tabletWidth={4}
                  mobileWidth={12}
                >
                  <CondensedLabel>Location</CondensedLabel>
                </GridColumn>
                <GridColumn
                  largeScreenWidth={8}
                  tabletWidth={8}
                  mobileWidth={12}
                >
                  <CondensedMedium name="LOCATION">
                    {LocationUtil.displayFormattedJobLocation(
                      workFeedItem.opportunity,
                      workFeedItem.position
                    )}
                  </CondensedMedium>
                  <Gutter only="mobile" bottom="8px" />
                </GridColumn>
                <GridColumn
                  largeScreenWidth={4}
                  tabletWidth={4}
                  mobileWidth={12}
                >
                  <CondensedLabel>Practice Area</CondensedLabel>
                </GridColumn>
                <GridColumn
                  largeScreenWidth={8}
                  tabletWidth={8}
                  mobileWidth={12}
                >
                  <CondensedMedium name="PRACTICE_AREA">
                    {workFeedItem?.practiceArea?.name ?? '--'}
                  </CondensedMedium>
                  <Gutter only="mobile" bottom="8px" />
                </GridColumn>
                <GridColumn
                  largeScreenWidth={4}
                  tabletWidth={4}
                  mobileWidth={12}
                >
                  <div
                    data-test={
                      index > 0 ? 'TALENT_TYPE' : `TALENT_TYPE_FIRST_CARD`
                    }
                  >
                    <CondensedLabel>Legal Role</CondensedLabel>
                  </div>
                </GridColumn>
                <GridColumn
                  largeScreenWidth={8}
                  tabletWidth={8}
                  mobileWidth={12}
                >
                  <CondensedMedium name="LEGAL_ROLE">
                    {workFeedItem?.position?.calculatedTalentType ?? '--'}
                  </CondensedMedium>
                </GridColumn>
              </GridRow>
            </Grid>
          </GridColumn>
        </GridRow>
      </Grid>
    </Gutter>
  );
};
