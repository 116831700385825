import React, { useRef } from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { Button, DSCandidate, PDFExport, StringUtil } from '@axiom/ui';

import CollectionStateHelperTreatment from '../CollectionStateHelperTreatment/CollectionStateHelperTreatment';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { CandidateExperiencesStore } from '../../stores/protected/candidate-experiences-store';
import { PreloadedFeatureFlagsStore } from '../../stores/preloaded-feature-flags-store';
import { CandidateLanguagesStore } from '../../stores/protected/candidate-languages-store';

const BioView = ({
  candidate,
  experiences,
  experiencesState,
  featureFlags,
  isAnonView,
  languages,
  languagesState,
}) => {
  const pdfRoot = useRef(null);

  const { barredLocations, certifications, educationItems, record } = candidate;
  const lastName = isAnonView
    ? record.id
    : StringUtil.cleanStringForFileName(record.calculatedLastName);

  return (
    <CollectionStateHelperTreatment collectionState={experiencesState}>
      <CollectionStateHelperTreatment collectionState={languagesState}>
        <PDFExport
          fileName={`Axiom Bio_${StringUtil.cleanStringForFileName(
            lastName
          )}.pdf`}
          ref={pdfRoot}
        >
          <DSCandidate
            anonymous={isAnonView}
            barredLocations={barredLocations}
            candidate={record}
            certifications={certifications}
            degrees={educationItems}
            experiences={experiences}
            languages={languages}
            mode={DSCandidate.modes.bio}
            headerActionButtons={
              PreloadedFeatureFlagsStore.isPdfDownloadingEnabled(
                featureFlags
              ) && (
                <Button
                  className="float-right hide-mobile hide-print"
                  name="PROFILE_DOWNLOAD_BUTTON"
                  onClick={() => pdfRoot.current.save()}
                >
                  Download
                </Button>
              )
            }
          />
        </PDFExport>
      </CollectionStateHelperTreatment>
    </CollectionStateHelperTreatment>
  );
};

BioView.propTypes = {
  isAnonView: bool,
  candidate: CurrentCandidateStore.getDataShape().isRequired,
  experiences: CandidateExperiencesStore.getDataShape().isRequired,
  experiencesState: CandidateExperiencesStore.getStateShape().isRequired,
  featureFlags: PreloadedFeatureFlagsStore.getDataShape().isRequired,
  languages: CandidateLanguagesStore.getDataShape().isRequired,
  languagesState: CandidateLanguagesStore.getStateShape().isRequired,
};

BioView.defaultProps = {
  isAnonView: false,
};

export default connect(state => ({
  candidate: CurrentCandidateStore.selectCandidate(state),
  experiences: CandidateExperiencesStore.selectExperiences(state),
  experiencesState: CandidateExperiencesStore.selectExperienceState(state),
  featureFlags: PreloadedFeatureFlagsStore.select(state),
  languages: CandidateLanguagesStore.selectLanguages(state),
  languagesState: CandidateLanguagesStore.selectLanguagesState(state),
}))(BioView);
