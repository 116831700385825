import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { UserConst } from '@axiom/const';
import { Page } from '@axiom/ui';

import AppContainer from './components/AppContainer/AppContainer';
import { AuthRoute } from './routes/AuthRoute';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { TalentProfilePage } from './pages/TalentProfilePage';
import { TalentEngagementsPage } from './pages/TalentEngagementsPage';
import { DigitalSubmissionsPage } from './pages/DigitalSubmissionsPage';
import { WorkFeedPage } from './pages/WorkFeedPage';
import { SingleWorkFeedItemPage } from './pages/SingleWorkFeedItemPage';
import { AvailabilityPreferencesConfirmPage } from './pages/AvailabilityPreferencesConfirmPage';
import { BioPage } from './pages/BioPage';
import { TalentSubmissionPage } from './pages/TalentSubmissionPage';
import { AnonymousBioPage } from './pages/AnonymousBioPage';
import { WorkFeedFailWhalePage } from './pages/WorkFeedFailWhalePage';
import { CommunicationsPreferencesPage } from './pages/CommunicationsPreferencesPage';
import { ProfilePreferencesPage } from './pages/ProfilePreferencesPage';
import { WorkStylePreferencesPage } from './pages/WorkStylePreferencesPage';
import { ProfileVisibilityPreferencesPage } from './pages/ProfileVisibilityPreferencesPage';
import { ProgramsPreferencesPage } from './pages/ProgramsPreferencesPage';
// NOTE: hook needs to impersonate a React component to avoid hook linting errors since it's used in a callback
import { useDocumentTitle as LoadDocumentTitle } from './hooks/useDocumentTitle';
import { HelpCenterChangesPage } from './pages/HelpCenterChangesPage';
import { ExperienceEditPage } from './pages/ExperienceEditPage';
import { RedirectToEnvoyBios } from './components/RedirectToEnvoyBios/RedirectToEnvoyBios';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="talent/:candidateId?">
        <Route
          path="profile"
          element={
            <AuthRoute
              roles={[
                UserConst.Roles.LPUser,
                UserConst.Roles.EnvoyAdmin,
                UserConst.Roles.EnvoyUser,
              ]}
            >
              <Page title={() => LoadDocumentTitle('Profile')}>
                <AppContainer>
                  <Outlet />
                </AppContainer>
              </Page>
            </AuthRoute>
          }
        >
          <Route path="" element={<TalentProfilePage />} />
          <Route
            path="interview-availability"
            element={<TalentProfilePage />}
          />
        </Route>
        <Route
          path="engagements"
          element={
            <AuthRoute
              roles={[
                UserConst.Roles.LPUser,
                UserConst.Roles.EnvoyAdmin,
                UserConst.Roles.EnvoyUser,
              ]}
            >
              <Page title={() => LoadDocumentTitle('Engagements')}>
                <AppContainer>
                  <TalentEngagementsPage />
                </AppContainer>
              </Page>
            </AuthRoute>
          }
        />
        <Route path="experiences">
          <Route
            path="new"
            element={
              <AuthRoute
                roles={[
                  UserConst.Roles.LPUser,
                  UserConst.Roles.EnvoyAdmin,
                  UserConst.Roles.EnvoyUser,
                ]}
              >
                <Page title={() => LoadDocumentTitle('Add Experience')}>
                  <AppContainer>
                    <ExperienceEditPage />
                  </AppContainer>
                </Page>
              </AuthRoute>
            }
          />
          <Route
            path=":experienceId"
            element={
              <AuthRoute
                roles={[
                  UserConst.Roles.LPUser,
                  UserConst.Roles.EnvoyAdmin,
                  UserConst.Roles.EnvoyUser,
                ]}
              >
                <Page title={() => LoadDocumentTitle('Edit Experience')}>
                  <AppContainer>
                    <ExperienceEditPage />
                  </AppContainer>
                </Page>
              </AuthRoute>
            }
          />
        </Route>
        <Route
          path="opportunities"
          element={
            <AuthRoute
              roles={[
                UserConst.Roles.LPUser,
                UserConst.Roles.EnvoyAdmin,
                UserConst.Roles.EnvoyUser,
              ]}
            >
              <Page title={() => LoadDocumentTitle('Work Feed')}>
                <AppContainer>
                  <Outlet />
                </AppContainer>
              </Page>
            </AuthRoute>
          }
        >
          <Route path="" element={<Navigate to="all" />} />
          <Route path="all" element={<WorkFeedPage />}>
            <Route path="page">
              <Route path="" element={<Navigate to="../" replace />} />
              <Route path=":pageNumber" element={<WorkFeedPage />} />
            </Route>
          </Route>
          <Route path="saved" element={<WorkFeedPage />}>
            <Route path="page">
              <Route path="" element={<Navigate to="../" replace />} />
              <Route path=":pageNumber" element={<WorkFeedPage />} />
            </Route>
          </Route>
          <Route path="interested" element={<WorkFeedPage />}>
            <Route path="page">
              <Route path="" element={<Navigate to="../" replace />} />
              <Route path=":pageNumber" element={<WorkFeedPage />} />
            </Route>
          </Route>
          <Route path="403" element={<WorkFeedFailWhalePage />} />
          <Route path=":opportunityId" element={<SingleWorkFeedItemPage />} />
        </Route>
        <Route path="" element={<Navigate to="profile" />} />
        <Route
          path="work-feed-email"
          element={<Navigate to="../opportunities" />}
        />
        <Route
          path="preferences"
          element={
            <AuthRoute
              roles={[
                UserConst.Roles.LPUser,
                UserConst.Roles.EnvoyAdmin,
                UserConst.Roles.EnvoyUser,
              ]}
            >
              <Page title={() => LoadDocumentTitle('Preferences')}>
                <AppContainer>
                  <Outlet />
                </AppContainer>
              </Page>
            </AuthRoute>
          }
        >
          <Route path="" element={<Navigate to="communications" replace />} />
          <Route
            path="communications"
            element={<CommunicationsPreferencesPage />}
          />
          <Route path="profile" element={<ProfilePreferencesPage />} />
          <Route path="work-style" element={<WorkStylePreferencesPage />} />
          <Route
            path="profile-visibility"
            element={<ProfileVisibilityPreferencesPage />}
          />
          <Route path="programs" element={<ProgramsPreferencesPage />} />
        </Route>
      </Route>

      <Route
        path="digital-submissions/:submissionId"
        element={
          <Page title="AxiomLaw - Submissions">
            <AppContainer>
              <DigitalSubmissionsPage />
            </AppContainer>
          </Page>
        }
      />
      <Route
        path="confirms/availability-preferences/:token"
        element={
          <Page title="AxiomLaw - Confirm Availability">
            <AppContainer>
              <AvailabilityPreferencesConfirmPage />
            </AppContainer>
          </Page>
        }
      />
      <Route path="bio/:candidateId" element={<RedirectToEnvoyBios />} />
      <Route
        path="submission/:submissionId/talent/:candidateId"
        element={
          <AuthRoute
            roles={[
              UserConst.Roles.EnvoyAdmin,
              UserConst.Roles.EnvoyUser,
              UserConst.Roles.EnvoySales,
              UserConst.Roles.LPUser,
            ]}
          >
            <Page title="AxiomLaw - Talent Submission">
              <AppContainer>
                <TalentSubmissionPage />
              </AppContainer>
            </Page>
          </AuthRoute>
        }
      />
      <Route
        path="anonymous-profile/:candidateId"
        element={<RedirectToEnvoyBios isAnonymous />}
      />
      <Route
        path="help-center-changes"
        element={
          <Page title="AxiomLaw - Help Center Changes">
            <AppContainer>
              <HelpCenterChangesPage />
            </AppContainer>
          </Page>
        }
      />
      <Route
        path="401"
        element={
          <Page title="AxiomLaw - Unauthorized">
            <AppContainer>
              <UnauthorizedPage />
            </AppContainer>
          </Page>
        }
      />
      <Route
        path="404"
        element={
          <Page title="AxiomLaw - Not Found">
            <AppContainer>
              <NotFoundPage />
            </AppContainer>
          </Page>
        }
      />
      <Route path="" element={<Navigate to="talent/profile" />} />
      <Route path="*" element={<Navigate to="404" replace />} />
    </Routes>
  );
};
