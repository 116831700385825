import styled from 'styled-components';

export const DescriptionBlock = styled.div`
  && {
    // the RichText component
    div {
      word-break: break-word;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      line-height: inherit;

      ul,
      ol {
        padding: 0;
        margin-left: 1.25rem;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
`;
