import { CandidateProfileIssuesUtils } from '@axiom/utils';
import { CandidateProfileIssue, Experience } from '@axiom/validation';
import { ProfileCompletionConst } from '@axiom/const';

const { ProfileCompletionCriteria } = ProfileCompletionConst;

export type ActionNeededItem = {
  name: string;
  prependText: string;
  linkText?: string;
  linkTo?: string;
};

export const ProfileCompletionUtil = {
  getActionNeededItems: (
    issues: CandidateProfileIssue[] = [],
    experiences: Experience[] = []
  ): ActionNeededItem[] => {
    const actionNeededItems = [];

    if (CandidateProfileIssuesUtils.hasValidNameIssues(issues)) {
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsValidName,
        prependText: 'Update your display name',
      });
    }

    if (CandidateProfileIssuesUtils.hasSummaryOfAnyLengthIssues(issues)) {
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsAcceptableSummary,
        prependText: 'Add a',
        linkText: 'profile summary',
        linkTo: '?modal=profileSummary',
      });
    }

    if (
      CandidateProfileIssuesUtils.hasSummaryOfSufficientLengthIssues(issues)
    ) {
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsAcceptableSummary,
        prependText: 'Expand on your',
        linkText: 'profile summary',
        linkTo: '?modal=profileSummary',
      });
    }

    if (CandidateProfileIssuesUtils.hasExperienceSpecificIssues(issues)) {
      const experienceIssues = CandidateProfileIssuesUtils.getExperienceIssues(
        issues,
        experiences
      );
      actionNeededItems.push(
        ...experienceIssues.map(e => ({
          name: `${ProfileCompletionCriteria.needsExperienceUpdate}_${e.id}`,
          prependText: 'Expand your experience at',
          linkText: e.calculatedDisplayName,
          linkTo: `../experiences/${e.id}`,
        }))
      );
    }

    if (CandidateProfileIssuesUtils.hasNonAxiomExperienceIssues(issues)) {
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsNonAxiomExperience,
        prependText: 'Add a',
        linkText: 'non-Axiom experience',
        linkTo: '../experiences/new',
      });
    }

    if (CandidateProfileIssuesUtils.hasLegalTechSkillsIssues(issues)) {
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsLegalTechSkills,
        prependText: 'Add',
        linkText: 'legal tech skills',
        linkTo: '?modal=legalTechSkills',
      });
    }

    if (CandidateProfileIssuesUtils.hasDegreesIssues(issues)) {
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsDegrees,
        prependText: 'Complete your',
        linkText: 'education section',
        linkTo: '?modal=education',
      });
    }

    if (CandidateProfileIssuesUtils.hasNativeLanguageIssues(issues)) {
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsNativeLanguage,
        prependText: 'Add a',
        linkText: 'language',
        linkTo: '?modal=language',
      });
    }

    if (CandidateProfileIssuesUtils.hasPracticeStartYearIssues(issues)) {
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsPracticeStartYear,
        prependText: 'Add years of experience in your',
        linkText: 'preferences',
        linkTo: '../preferences/profile',
      });
    }

    if (CandidateProfileIssuesUtils.hasPreferencesIssues(issues)) {
      const [issue] = CandidateProfileIssuesUtils.getPreferencesIssues(issues);
      actionNeededItems.push({
        name: ProfileCompletionCriteria.needsUpdatedPreferences,
        prependText: 'Review and update your',
        linkText: 'preferences',
        linkTo: `../preferences/${issue.context}`,
      });
    }

    return actionNeededItems;
  },
};
