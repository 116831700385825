import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Gutter,
  Grid,
  GridColumn,
  Layout,
  useApi,
  useApiWithErrors,
  ApiError,
} from '@axiom/ui';
import { CandidateWorkFeedItem } from '@axiom/validation';

import { UserApi } from '../../api/protected/user/user';
import { CandidateOpportunitiesApi } from '../../api/protected/candidates/candidate-opportunities';
import { CandidateLegacyApi } from '../../api/protected/candidates/candidate-legacy';
import { CandidateUtil } from '../../utils/candidate-util';
import { WORK_FEED_VIEWS } from '../../utils/work-feed-util';
import { WorkFeedCard } from '../WorkFeedCard/WorkFeedCard';
import { OpportunityPhaseTracker } from '../OpportunityPhaseTracker/OpportunityPhaseTracker';
import { WorkFeedInterestedTracker } from '../WorkFeedInterestedTracker/WorkFeedInterestedTracker';

import { WorkFeedSPVBackButton } from './WorkFeedSPVBackButton';
import { WorkFeedSPVFooter } from './WorkFeedSPVFooter';
import { WorkFeedSPVEmpty } from './WorkFeedSPVEmpty';

export const WorkFeedSPV = () => {
  let shouldShowEmptyState = false;
  let workFeedItem: CandidateWorkFeedItem;

  const navigate = useNavigate();
  const [{ data: user }] = useApi(UserApi.readUser());
  const { opportunityId, candidateId: urlCandidateId } = useParams();
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [{ data: candidate }] = useApi(
    CandidateLegacyApi.readCandidate(candidateId)
  );
  const [workFeedItemResponse] = useApiWithErrors(
    CandidateOpportunitiesApi.readCandidateWorkFeedItem(
      candidateId,
      opportunityId
    )
  );

  useEffect(() => {
    if (workFeedItemResponse instanceof ApiError) {
      const error = workFeedItemResponse as ApiError;
      if (error.getStatusCode() === 403) {
        const failUrl = `../403`;
        navigate(failUrl, { replace: true });
      }
    }
  }, [workFeedItemResponse]);

  if (workFeedItemResponse instanceof ApiError) {
    const error = workFeedItemResponse as ApiError;
    if (error.getStatusCode() === 403) {
      return null;
    } else if (error.getStatusCode() === 423) {
      shouldShowEmptyState = true;
    } else {
      throw error;
    }
  } else {
    workFeedItem = workFeedItemResponse.data;
  }

  return (
    <Grid>
      <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
        <WorkFeedInterestedTracker candidateId={candidateId} />
        <Gutter bottom="16px" />
      </GridColumn>
      <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
        <Layout direction="vertical" verticalGutter="16px">
          <WorkFeedSPVBackButton />
          {shouldShowEmptyState && <WorkFeedSPVEmpty />}
          {!shouldShowEmptyState && workFeedItem && (
            <>
              <WorkFeedCard
                workFeedItem={workFeedItem}
                candidate={candidate}
                view={WORK_FEED_VIEWS.spv}
                index={0}
              />
              <OpportunityPhaseTracker workFeedItem={workFeedItem} />
              <WorkFeedSPVFooter candidateId={candidate.id} />
            </>
          )}
        </Layout>
      </GridColumn>
    </Grid>
  );
};
