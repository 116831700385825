import React from 'react';
import {
  Button,
  Card,
  CardFooter,
  CardSection,
  EmptyLayout,
  Gutter,
  Paragraph,
  SmallHeader,
} from '@axiom/ui';

import EmptyProjectsGraphic from '../../public/svg/empty-project.svg';

export const WorkFeedSPVEmpty = () => (
  <Card name="WORK_FEED_SPV_EMPTY">
    <CardSection divider>
      <EmptyLayout
        graphic={
          <span>
            <EmptyProjectsGraphic width="117px" height="117px" />
          </span>
        }
      >
        <SmallHeader>
          It looks like this opportunity is no longer available
        </SmallHeader>
        <Gutter bottom="16px" />
        <Paragraph>
          It may have closed or been filled sooner than expected. New
          opportunities are added daily so be sure to check the feed while
          you’re here.
        </Paragraph>
      </EmptyLayout>
    </CardSection>
    <CardFooter>
      <Button name="VIEW_NEW_OPPORTUNITIES" to="../all">
        View New Opportunities
      </Button>
    </CardFooter>
  </Card>
);
