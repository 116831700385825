import React from 'react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
} from '@axiom/ui';
import { Candidate, Certification } from '@axiom/validation';

import { CandidateLegacyApi } from '../../api/protected/candidates/candidate-legacy';
import { CandidateCertificationsApi } from '../../api/protected/candidates/candidate-certifications';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

export const AccomplishmentsDeleteModal = ({
  candidateId,
  certificationId,
  onClose,
}: {
  candidateId: Candidate['id'];
  certificationId: Certification['id'];
  onClose: () => void;
}) => {
  return (
    <Modal name="ACCOMPLISHMENTS_DELETE_MODAL">
      <ModalHeader name="ACCOMPLISHMENTS_DELETE_MODAL_HEADER" onClose={onClose}>
        Delete This Accomplishment
      </ModalHeader>
      <ModalSection>
        Deleting this accomplishment removes it from your Axiom for Talent
        Profile and the bio that Axiom shares with prospective clients.
      </ModalSection>
      <ModalFooter>
        <Button variation="outline" name="CANCEL" onClick={onClose}>
          Cancel
        </Button>
        <Button
          name="SUBMIT"
          onClick={async () => {
            await CandidateCertificationsApi.deleteCertification(
              certificationId
            );
            await CandidateLegacyApi.refreshCandidate(candidateId);
            await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
              candidateId
            );
            return onClose();
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
