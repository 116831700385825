import { Candidate } from '@axiom/const';

export const TALENT_FIELD_ACTIVITY_LOG = Candidate.ACTIVITY_LOG;
export const TALENT_FIELD_ADDRESS_1 = Candidate.ADDRESS_1;
export const TALENT_FIELD_ADDRESS_2 = Candidate.ADDRESS_2;
export const TALENT_FIELD_ADDRESS_CITY = Candidate.ADDRESS_CITY;
export const TALENT_FIELD_ADDRESS_COUNTRY = Candidate.ADDRESS_COUNTRY;
export const TALENT_FIELD_ADDRESS_COUNTRY_CODE = Candidate.ADDRESS_COUNTRY_CODE;
export const TALENT_FIELD_ADDRESS_DISPLAY_NAME = Candidate.ADDRESS_DISPLAY_NAME;
export const TALENT_FIELD_ADDRESS_STATE = Candidate.ADDRESS_STATE;
export const TALENT_FIELD_ADDRESS_ZIP = Candidate.ADDRESS_ZIP;
export const TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME =
  Candidate.ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME;
export const TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE =
  Candidate.ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE;
export const TALENT_FIELD_AVAILABILITY = Candidate.AVAILABILITY;
export const TALENT_FIELD_AVAILABILITY_NOTES = Candidate.AVAILABILITY_NOTES;
export const TALENT_FIELD_AXIOM_EMPLOYEE_TYPE = Candidate.AXIOM_EMPLOYEE_TYPE;
export const TALENT_FIELD_BARRED_LOCATIONS = Candidate.BARRED_LOCATIONS;
export const TALENT_FIELD_BULLHORN_ID = Candidate.BULLHORN_ID;
export const TALENT_FIELD_CANDIDACY_ENGAGEMENTS =
  Candidate.CANDIDACY_ENGAGEMENTS;
export const TALENT_FIELD_CANDIDACY_LIST = Candidate.CANDIDACY_LIST;
export const TALENT_FIELD_COLLABORATOR_ID = Candidate.COLLABORATOR_ID;
export const TALENT_FIELD_COLLABORATORS = Candidate.COLLABORATORS;
export const TALENT_FIELD_COMPENSATION = Candidate.COMPENSATION;
export const TALENT_FIELD_CREATED_AT = Candidate.CREATED_AT;
export const TALENT_FIELD_CURRENCY = Candidate.CURRENCY;
export const TALENT_FIELD_DAYS_SINCE_STATUS_CHANGE =
  Candidate.DAYS_SINCE_STATUS_CHANGE;
export const TALENT_FIELD_DESIRED_COMMUTE = Candidate.DESIRED_COMMUTE;
export const TALENT_FIELD_DESIRED_ENGAGEMENT_SPECIFIC_COMPENSATION =
  Candidate.DESIRED_ENGAGEMENT_SPECIFIC_COMPENSATION;
export const TALENT_FIELD_EMPLOYEE_TYPE = Candidate.EMPLOYEE_TYPE;
export const TALENT_FIELD_EXPERIENCE = Candidate.EXPERIENCE;
export const TALENT_FIELD_FIRST_NAME = Candidate.FIRST_NAME;
export const TALENT_FIELD_FURTHEST_CANDIDACY_STATUS =
  Candidate.FURTHEST_CANDIDACY_STATUS;
export const TALENT_FIELD_FURTHEST_CANDIDACY_STATUS_UPDATED_AT =
  Candidate.FURTHEST_CANDIDACY_STATUS_UPDATED_AT;
export const TALENT_FIELD_PRACTICE_START_YEAR = Candidate.PRACTICE_START_YEAR;
export const TALENT_FIELD_HOME_OFFICE = Candidate.HOME_OFFICE;
export const TALENT_FIELD_HOME_PHONE = Candidate.HOME_PHONE;
export const TALENT_FIELD_HOURLY_COMPENSATION = Candidate.HOURLY_COMPENSATION;
export const TALENT_FIELD_ID = Candidate.ID;
export const TALENT_FIELD_LANGUAGE_CEFR = Candidate.LANGUAGE_CEFR;
export const TALENT_FIELD_LANGUAGES = Candidate.LANGUAGES;
export const TALENT_FIELD_LAST_NAME = Candidate.LAST_NAME;
export const TALENT_FIELD_LAST_UPDATED_BY = Candidate.LAST_UPDATED_BY;
export const TALENT_FIELD_LATEST_ENGAGEMENT_ACCOUNT_NAME =
  Candidate.LATEST_ENGAGEMENT_ACCOUNT_NAME;
export const TALENT_FIELD_LATEST_ENGAGEMENT_END_DATE =
  Candidate.LATEST_ENGAGEMENT_ACCOUNT_NAME;
export const TALENT_FIELD_LAW_SCHOOL = Candidate.LAW_SCHOOL;
export const TALENT_FIELD_MATTER_ID = Candidate.MATTER_ID;
export const TALENT_FIELD_MIDDLE_NAME = Candidate.MIDDLE_NAME;
export const TALENT_FIELD_MOBILE_PHONE = Candidate.MOBILE_PHONE;
export const TALENT_FIELD_NOTICE_PERIOD = Candidate.NOTICE_PERIOD;
export const TALENT_FIELD_OCCUPATION_TYPE = Candidate.OCCUPATION_TYPE;
export const TALENT_FIELD_OWNER_FULL_NAME = Candidate.OWNER_FULL_NAME;
export const TALENT_FIELD_OWNER_LAST_NAME = Candidate.OWNER_LAST_NAME;
export const TALENT_FIELD_OWNER_USER_ID = Candidate.OWNER_USER_ID;
export const TALENT_FIELD_PERSONAL_EMAIL = Candidate.PERSONAL_EMAIL;
export const TALENT_FIELD_PROFILE_STATUS = Candidate.PROFILE_STATUS;
export const TALENT_FIELD_PROFILE_STATUS_UPDATED_AT =
  Candidate.PROFILE_STATUS_UPDATED_AT;
export const TALENT_FIELD_SOONEST_ENGAGEMENT_ACCOUNT_NAME =
  Candidate.SOONEST_ENGAGEMENT_ACCOUNT_NAME;
export const TALENT_FIELD_SOONEST_ENGAGEMENT_END_DATE =
  Candidate.SOONEST_ENGAGEMENT_END_DATE;
export const TALENT_FIELD_STATE_EXAM_SCORE = Candidate.STATE_EXAM_SCORE;
export const TALENT_FIELD_SKILLS = Candidate.SKILLS;
export const TALENT_FIELD_UPDATED_AT = Candidate.UPDATED_AT;
export const TALENT_FIELD_WEEKLY_AVAILABILITY = Candidate.WEEKLY_AVAILABILITY;
export const TALENT_FIELD_WORK_EMAIL = Candidate.WORK_EMAIL;
export const TALENT_FIELD_YEARS_OF_EXPERIENCE = Candidate.YEARS_OF_EXPERIENCE;
