import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Candidate,
  CandidateProfileIssue,
  Experience,
  Taxonomy,
} from '@axiom/validation';
import {
  Banner,
  Button,
  Card,
  CardHeader,
  CardSection,
  Layout,
  LayoutItem,
  SmallHeader,
} from '@axiom/ui';

import { CandidateExperiencesUtil } from '../../utils/candidate-experiences-util';

import { ExperienceItemDisplay } from './ExperienceItemDisplay';

export const ExperiencesCard = ({
  experiences,
  taxonomy,
  candidateId,
  issues,
}: {
  experiences: Experience[];
  taxonomy: Taxonomy;
  candidateId: Candidate['id'];
  issues: CandidateProfileIssue[];
}) => {
  const navigate = useNavigate();
  const shouldShowMergeTip: Record<Experience['id'], boolean> = useMemo(
    () => CandidateExperiencesUtil.createShouldShowMergeTipMapping(experiences),
    [experiences]
  );
  return (
    <Card name="EXPERIENCES">
      <CardHeader>
        <Layout position="middle">
          <LayoutItem fluid>
            <SmallHeader>Experience</SmallHeader>
          </LayoutItem>
          <Button
            name="ADD_EXPERIENCE"
            pattern="secondary"
            onClick={() => {
              navigate('../experiences/new');
            }}
            icon="plus"
            variation="minimal"
          >
            Add
          </Button>
        </Layout>
      </CardHeader>
      {experiences.map(experience => (
        <CardSection divider key={experience.id} name="EXPERIENCE_ITEM">
          <ExperienceItemDisplay
            candidateId={candidateId}
            experience={experience}
            taxonomy={taxonomy}
            showMergeTip={shouldShowMergeTip[experience.id] ?? false}
            issues={issues}
            onExperienceNav={() => navigate(`../experiences/${experience.id}`)}
          />
        </CardSection>
      ))}
      {experiences.length === 0 && (
        <CardSection name="NO_EXPERIENCES">
          <Banner impact="low" type="info">
            No experience added
          </Banner>
        </CardSection>
      )}
    </Card>
  );
};
