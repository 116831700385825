import GenericStore from '../classes/generic-store';
import { getPracticeAreas } from '../api/protected/practice-areas/practice-areas';
import { getTreeTrunk } from '../utils/dropdown-tree-utils';

class PreloadedPracticeAreasStoreClass extends GenericStore {
  load() {
    return this.setState(
      getPracticeAreas().then(response => {
        const filtered = response.filter(data => data.type);

        return {
          options: getTreeTrunk(filtered, true),
          raw: filtered.reduce((accum, current) => {
            accum[current.id] = current;
            return accum;
          }, {}),
        };
      })
    );
  }

  selectData(state) {
    return this.select(state)?.data || [];
  }
}

export const PreloadedPracticeAreasStore =
  new PreloadedPracticeAreasStoreClass();
