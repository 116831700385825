import React from 'react';
import { func } from 'prop-types';
import {
  Button,
  Modal,
  ModalSection,
  ModalHeader,
  ModalFooter,
} from '@axiom/ui';

export const ProfileImageEditPhotoConsentModal = ({ onConsent, onDecline }) => (
  <Modal>
    <ModalHeader onClose={onDecline}>Photo Consent</ModalHeader>
    <ModalSection>
      <span>
        You have the option to upload your photograph to the Axiom Platform. If
        you choose to upload your photograph, you are giving us your consent for
        your photograph to be featured alongside your profile when it is
        submitted to clients, when clients or prospective clients are searching
        for lawyers on our website, and/or when you are on clients’ benches. You
        may update your photograph or delete it entirely from your profile (in
        the event that you change your mind about sharing it). There is no
        obligation to upload a photograph at all and if you choose not to do so,
        this will not have any consequences on how Axiom supports you in finding
        suitable client engagements.
      </span>
    </ModalSection>
    <ModalFooter>
      <Button variation="outline" name="CANCEL" onClick={onDecline}>
        I do not consent
      </Button>

      <Button name="SUBMIT" onClick={onConsent}>
        I consent
      </Button>
    </ModalFooter>
  </Modal>
);

ProfileImageEditPhotoConsentModal.propTypes = {
  onConsent: func.isRequired,
  onDecline: func.isRequired,
};
