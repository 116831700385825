import React from 'react';
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Button,
} from '@axiom/ui';

interface DeleteModalProps {
  onClose: () => void;
  onDelete: () => void;
}

export const TwoFactorDeleteModal = ({
  onClose,
  onDelete,
}: DeleteModalProps) => {
  return (
    <Modal name="TWO_FACTOR_DELETE_MODAL" size="confirm">
      <ModalHeader name="TWO_FACTOR_DELETE_MODAL_HEADER" onClose={onClose}>
        Delete phone number
      </ModalHeader>
      <ModalSection>
        <Paragraph>
          This will permanently delete your two-factor authentication phone
          number. You can add a new phone number at any time.
        </Paragraph>
      </ModalSection>
      <ModalFooter>
        <Button name="CANCEL_BUTTON" variation="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button name="DELETE_BUTTON" onClick={onDelete}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
