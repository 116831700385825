import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardSection,
  CondensedSmall,
  DateUtil,
  Flashy,
  Form,
  Grid,
  GridColumn,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SectionHeader,
  SmallHeader,
  Toggle,
  useApi,
} from '@axiom/ui';
import { CandidateSchema } from '@axiom/validation';

import { CandidateLegacyApi } from '../../api/protected/candidates/candidate-legacy';
import { UserApi } from '../../api/protected/user/user';
import { PreferencesNavigation } from '../PreferencesNavigation/PreferencesNavigation';
import { PreloadedPermissionsStore } from '../../stores/preloaded-permissions-store';
import { CandidateUtil } from '../../utils/candidate-util';
import { PreferencesUtil } from '../../utils/preferences-util';
import { isLPUser } from '../../utils/roles';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

import { PreferencesBanner } from './PreferencesBanner';

const EditProgramsPreferencesSchema = CandidateSchema.partial().pick({
  openToSecondment: true,
  openToAdviceAndCounsel: true,
  openToVariableHourlyProjects: true,
  openToLargeProjects: true,
  openToPermanentPlacement: true,
});

export const ProgramsPreferences = ({
  permissions,
}: {
  permissions: {
    viewWorkStylePreferences: boolean;
    viewProfileVisibilityPreferences: boolean;
    viewProgramsPreferences: boolean;
  };
}) => {
  const navigate = useNavigate();

  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();

  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );

  const [{ data: candidate }] = useApi(
    CandidateLegacyApi.readCandidate(candidateId)
  );
  const { programsPreferencesReviewedAt } = candidate;

  useEffect(() => {
    if (!PreloadedPermissionsStore.canViewProgramsPreferences(permissions)) {
      navigate('../communications');
    }
  }, [permissions]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> = null;
    if (
      PreloadedPermissionsStore.canViewProgramsPreferences(permissions) &&
      isLPUser(user)
    ) {
      timeout = setTimeout(() => {
        CandidateLegacyApi.updateCandidate(candidateId, {
          programsPreferencesReviewedAt: DateUtil.formatAsTimestamp(new Date()),
        });
      }, 500);
    }
    return () => timeout && clearTimeout(timeout);
  }, []);

  return (
    <Grid name="PROGRAMS_PREFERENCES">
      <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
        <PreferencesNavigation />
        <Gutter only="smallScreen" bottom="16px" />
      </GridColumn>
      <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
        {PreferencesUtil.shouldPreferenceNeedReview(
          programsPreferencesReviewedAt
        ) && <PreferencesBanner />}
        <Form
          schema={EditProgramsPreferencesSchema}
          onSubmit={async formData => {
            await CandidateLegacyApi.updateCandidate(candidateId, {
              ...CandidateSchema.partial().parse(formData),
              programsPreferencesReviewedAt: DateUtil.formatAsTimestamp(
                new Date()
              ),
            });
            return Promise.all([
              CandidateLegacyApi.refreshCandidate(candidateId),
              CandidateProfileIssuesApi.refreshCandidateProfileIssues(
                candidateId
              ),
            ]);
          }}
          initialValues={{
            openToSecondment: !!candidate.openToSecondment,
            openToAdviceAndCounsel: !!candidate.openToAdviceAndCounsel,
            openToVariableHourlyProjects:
              !!candidate.openToVariableHourlyProjects,
            openToLargeProjects: !!candidate.openToLargeProjects,
            openToPermanentPlacement: !!candidate.openToPermanentPlacement,
          }}
          submitOnChange
        >
          {() => (
            <Card>
              <CardHeader>
                <SmallHeader>Programs</SmallHeader>
              </CardHeader>
              <CardSection divider>
                <SectionHeader>
                  Select which Axiom Programs you are open to participate in, if
                  they are available in your area.
                </SectionHeader>
              </CardSection>
              <CardSection divider>
                <Layout
                  stackableOn="mobile"
                  horizontalGutter="24px"
                  position="stretch space-between"
                >
                  <LayoutItem>
                    <ParagraphHeader>
                      <Flashy bold>
                        I’m open to being seconded to a client
                      </Flashy>
                    </ParagraphHeader>
                    <Gutter bottom="16px" />
                    <Paragraph>
                      <Flashy bold>Secondments:</Flashy> Clients engage Axiom
                      legal talent for a fixed number of hours or days each
                      week, either full-time or part-time for a period of time.
                    </Paragraph>
                    <Gutter bottom="16px" only="mobile" />
                  </LayoutItem>
                  <LayoutItem>
                    <Toggle name="openToSecondment" />
                  </LayoutItem>
                </Layout>
              </CardSection>
              <CardSection divider>
                <Layout
                  stackableOn="mobile"
                  horizontalGutter="24px"
                  position="stretch space-between"
                >
                  <LayoutItem>
                    <ParagraphHeader>
                      <Flashy bold>I’m open to working with AA&C</Flashy>
                    </ParagraphHeader>
                    <Gutter bottom="16px" />
                    <Paragraph>
                      <Flashy bold>Axiom Advice & Counsel (AA&C):</Flashy> AA&C
                      is Axiom’s US-based full-service law firm for clients who
                      don’t have a legal team or who want Partner oversight.
                      AA&C engages Axiom legal professionals to work on matters
                      for each client.
                    </Paragraph>
                    <Gutter bottom="16px" only="mobile" />
                  </LayoutItem>
                  <LayoutItem>
                    <Toggle name="openToAdviceAndCounsel" />
                  </LayoutItem>
                </Layout>
              </CardSection>
              <CardSection divider>
                <Layout
                  stackableOn="mobile"
                  horizontalGutter="24px"
                  position="stretch space-between"
                >
                  <LayoutItem>
                    <ParagraphHeader>
                      <Flashy bold>
                        I’m open to working on variable hourly projects
                      </Flashy>
                    </ParagraphHeader>
                    <Gutter bottom="16px" />
                    <Paragraph>
                      <Flashy bold>Variable hourly:</Flashy> Clients engage
                      Axiom legal talent on a variable hourly basis sometimes
                      requiring on-demand availability.
                    </Paragraph>
                    <Gutter bottom="16px" only="mobile" />
                  </LayoutItem>
                  <LayoutItem>
                    <Toggle name="openToVariableHourlyProjects" />
                  </LayoutItem>
                </Layout>
              </CardSection>
              <CardSection divider>
                <Layout
                  stackableOn="mobile"
                  horizontalGutter="24px"
                  position="stretch space-between"
                >
                  <LayoutItem>
                    <ParagraphHeader>
                      <Flashy bold>
                        I’m open to working with a team of Axiom legal talent
                      </Flashy>
                    </ParagraphHeader>
                    <Gutter bottom="16px" />
                    <Paragraph>
                      <Flashy bold>Large projects:</Flashy> Clients engage a
                      team of Axiom legal talent to work together on a large,
                      complex project. Large projects require an ability to work
                      well in structured teams to meet deadlines.
                    </Paragraph>
                    <Gutter bottom="16px" only="mobile" />
                  </LayoutItem>
                  <LayoutItem>
                    <Toggle name="openToLargeProjects" />
                  </LayoutItem>
                </Layout>
              </CardSection>
              <CardSection divider>
                <Layout
                  stackableOn="mobile"
                  horizontalGutter="24px"
                  position="stretch space-between"
                >
                  <LayoutItem>
                    <ParagraphHeader>
                      <Flashy bold>
                        I’m open to being placed permanently with a client
                      </Flashy>
                    </ParagraphHeader>
                    <Gutter bottom="16px" />
                    <Paragraph>
                      <Flashy bold>Permanent placement:</Flashy> Clients
                      contract with Axiom to fill full-time positions in their
                      legal departments or for a secondment with the intention
                      of hiring permanently after a period of time.
                    </Paragraph>
                    <Gutter bottom="16px" only="mobile" />
                  </LayoutItem>
                  <LayoutItem>
                    <Toggle name="openToPermanentPlacement" />
                  </LayoutItem>
                </Layout>
              </CardSection>
            </Card>
          )}
        </Form>
        {programsPreferencesReviewedAt && (
          <Gutter top="10px">
            <CondensedSmall>
              <Flashy
                italic
                color="textSubtle"
                name="PROGRAMS_PREFERENCES_REVIEWED_AT"
              >
                Last reviewed:{' '}
                {DateUtil.displayDateFullYearShortMonthDayName(
                  programsPreferencesReviewedAt
                )}
              </Flashy>
            </CondensedSmall>
          </Gutter>
        )}
      </GridColumn>
    </Grid>
  );
};

export const ProgramsPreferencesView = connect(state => ({
  permissions: PreloadedPermissionsStore.select(state),
}))(ProgramsPreferences);
