import React from 'react';
import { shape, string } from 'prop-types';
import { connect } from 'react-redux';

import { AppStore } from '../../stores/app-store';
import { ComponentShape } from '../../utils/prop-type-util';
import { ErrorBoundaryLayout } from '../../layouts/ErrorBoundaryLayout/ErrorBoundaryLayout';

const AppContainer = ({ appError, children }) => {
  if (appError && appError.errorMessage) {
    return <ErrorBoundaryLayout errorMessage={appError.errorMessage} />;
  }

  return children;
};

AppContainer.propTypes = {
  appError: shape({
    errorMessage: string,
  }),
  children: ComponentShape,
};

AppContainer.defaultProps = {
  appError: null,
  children: null,
};

export default connect(state => ({
  appError: AppStore.selectAppError(state),
}))(AppContainer);
