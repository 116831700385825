import styled from 'styled-components';

import { RawCheckbox } from '../../shared/Checkbox/Checkbox';
import DropdownList from '../DropdownList/DropdownList';

export const NOInputColumnWrapper = styled.div`
  display: inline-block;
  margin-bottom: 30px;

  & > span {
    display: flex;
  }
`;

export const NOFormGroupIndent = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
`;

export const NOSmallBlurbWrapper = styled.div`
  padding: 10px 0 30px 0;
`;
export const NOSectionWrapper = styled.div`
  padding: 10px 0 35px 0;

  label {
    margin-bottom: 1rem;
  }
`;

export const NOCheckboxWrapper = styled(RawCheckbox)`
  display: inline;
`;

export const NODropdownWrapper = styled(DropdownList)`
  width: 36%;
`;
