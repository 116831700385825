import { Api } from '@axiom/ui';
import { BarredLocation } from '@axiom/validation';

import ApiHelper from '../../../lib/api-helper';
import { BARRED_LOCATION_API_NAME } from '../../constants';
import { EnvUtil } from '../../../utils/env-util';

const api = new ApiHelper(BARRED_LOCATION_API_NAME);

export const getBarredLocations = async () => {
  const { data } = await api.GET('/barredLocations');
  return data;
};

class BarredLocationApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readBarredLocations() {
    return super.read<{ data: Array<BarredLocation> }>({
      endpoint: '/barredLocations',
      method: 'GET',
    });
  }
}

export const BarredLocationApi = new BarredLocationApiClass();
