import React, { useState, useEffect } from 'react';
import { z } from 'zod';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Gutter,
  Form,
  Grid,
  GridRow,
  GridColumn,
  Input,
  ToastUtil,
  CondensedLarge,
  ApiError,
  NotFoundError,
  BadRequestError,
} from '@axiom/ui';
import { User, Candidate, CandidateMfaPhoneNumber } from '@axiom/validation';

import { UsersApi } from '../../api/protected/users/users';
import { CandidateApi } from '../../api/protected/candidates/candidate';

const TwoFactorVerificationSchema = z.object({
  VERIFICATION_CODE: z
    .string()
    .trim()
    .regex(/^[a-zA-Z0-9]+$/, { message: 'Invalid Code' })
    .length(6, { message: 'Invalid Code' }),
});

export interface TwoFactorVerificationModalProps {
  onClose: () => void;
  user: User;
  candidateMfa: CandidateMfaPhoneNumber;
  candidate: Candidate;
}

export const TwoFactorVerificationModal = ({
  onClose,
  user,
  candidate,
  candidateMfa,
}: TwoFactorVerificationModalProps) => {
  const [isResendHidden, setIsResendHidden] = useState(true);
  const [timeoutCommand, setTimeoutCommand] = useState(Date.now());

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsResendHidden(false);
    }, 120000);

    // Cleanup function to clear the timeout on unmount or when dependencies change
    return () => clearTimeout(timeoutId);
  }, [timeoutCommand]);

  return (
    <Form
      schema={TwoFactorVerificationSchema}
      onSubmit={async (formData, actions) => {
        try {
          await UsersApi.createMfaVerification(user.id, {
            mfaCode: formData.VERIFICATION_CODE,
          });
          await CandidateApi.refreshCandidateMfaPhoneNumber(candidate.id);
          ToastUtil.add({
            name: 'VERIFICATION_CODE_SUCCESS',
            type: 'info',
            children: (
              <CondensedLarge>
                You have successfully added a 2-factor authentication phone
                number
              </CondensedLarge>
            ),
          });

          onClose();
        } catch (error) {
          if (error instanceof ApiError) {
            if (error instanceof BadRequestError) {
              actions.setFieldError(
                'VERIFICATION_CODE',
                'Invalid Code. Please Request another one below.'
              );
            } else if (error instanceof NotFoundError) {
              actions.setFieldError(
                'VERIFICATION_CODE',
                'Code Expired. Please Request another one below.'
              );
            }
            setTimeoutCommand(Date.now());
            setIsResendHidden(false);
          } else {
            throw error;
          }
        }
      }}
      initialValues={{
        VERIFICATION_CODE: null,
      }}
    >
      {({ fireSubmit, values, setValues }) => {
        return (
          <Modal name="TWO_FACTOR_VERIFICATION_MODAL">
            <ModalHeader
              onClose={onClose}
              name="TWO_FACTOR_VERIFICATION_MODAL_HEADER"
            >
              Two-factor authentication
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                We have sent a temporary code to{' '}
                {`+${candidateMfa.countryCode} ${candidateMfa.phoneNumber}`}.
                Enter the code below to verify that it’s you. This code will
                expire after two minutes.
              </Paragraph>
              <Gutter bottom="16px" />
              <Grid>
                <GridRow>
                  <GridColumn
                    largeScreenWidth={6}
                    tabletWidth={6}
                    mobileWidth={12}
                  >
                    <Input name="VERIFICATION_CODE" label="Enter the code" />
                    <Gutter bottom="16px" />
                    {!isResendHidden && (
                      <Button
                        name="RESEND_CODE"
                        variation="outline"
                        onClick={async () => {
                          await UsersApi.updateMfaPhoneNumber(user.id, {
                            phoneNumber: candidateMfa.phoneNumber,
                            countryCode: candidateMfa.countryCode,
                          });
                          setIsResendHidden(true);
                          ToastUtil.add({
                            name: 'VERIFICATION_CODE_RESEND',
                            type: 'info',
                            children: (
                              <CondensedLarge>
                                Code Sent to{' '}
                                {`+${candidateMfa.countryCode} ${candidateMfa.phoneNumber}`}
                              </CondensedLarge>
                            ),
                          });
                          setTimeoutCommand(Date.now());
                          setValues({ VERIFICATION_CODE: null });
                        }}
                      >
                        Resend Code
                      </Button>
                    )}
                  </GridColumn>
                  <GridColumn width={6} />
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <Button
                name="CANCEL_BUTTON"
                variation="outline"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button name="VERIFY_BUTTON" onClick={fireSubmit}>
                Verify
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
