import { get } from 'lodash';
import { shape, bool, string, arrayOf } from 'prop-types';

import { AjvUtil } from '../utils/ajv-util';

/**
 * USAGE
 * When you need to track form data, use this store. Takes an object. If you pass in an array, it will convert to an object
 *
 * REDUCER STATES
 * show, hide, set, overwrite, submitting, submit success, submit failed
 *
 * COMPATIBLE COMPONENTS
 *
 * FormStateHelperTreatment
 */

const FormStateHelperShapeStructure = {
  isVisible: bool,
  isSubmitting: bool,
  formName: string,
  data: shape({}),
  errors: arrayOf(shape({})),
};

export const FormStateHelperShape = shape(FormStateHelperShapeStructure);

export default class FormStateHelper {
  /**
   * @param {string} namespace - Store namespace
   * @param {string} formName - Form name
   * @param {Object} compiledAjvSchema - [Ajv Schema]
   */
  constructor(namespace, formName, compiledAjvSchema) {
    this.namespace = namespace;
    this.formName = formName;
    this.compiledAjvSchema = compiledAjvSchema;
  }

  generateReducers() {
    return {
      [this._getShowActionName()]: (state, formPayload) => ({
        ...state,
        isVisible: true,
        isSubmitting: false,
        formName: this.formName,
        formItemIdentifier: formPayload.formItemIdentifier,
        data: { ...formPayload.formData },
        errors: null,
      }),
      [this._getSetActionName()]: (state, formData) => ({
        ...state,
        data: { ...state.data, ...formData },
      }),
      [this._getSubmittingActionName()]: state => ({
        ...state,
        isSubmitting: true,
        errors: null,
      }),
      [this._getSubmitSuccessActionName()]: state => ({
        ...state,
        isSubmitting: false,
        errors: null,
      }),
      [this._getSubmitErrorActionName()]: (state, errors) => ({
        ...state,
        isSubmitting: false,
        errors,
      }),
      [this._getHideActionName()]: state => ({
        ...state,
        isVisible: false,
        isSubmitting: false,
        formName: null,
        data: null,
        errors: null,
      }),
    };
  }

  getStructure(formDataShape) {
    return {
      ...FormStateHelperShapeStructure,
      data: formDataShape,
    };
  }

  /**
   * Marks form as ready to show
   * @param formData - Data to set to the store and mark form as ready
   * @param formItemIdentifier - If you need to do a form per item, you set this and then can pass it into the
   * FormStateHelperTreatment component
   */
  showForm(formData, formItemIdentifier = null) {
    return dispatch => {
      dispatch({
        type: this._getShowActionName(),
        payload: { formData, formItemIdentifier },
      });
    };
  }

  /**
   * Closes the form
   */
  hideForm() {
    return dispatch => dispatch({ type: this._getHideActionName() });
  }

  /**
   * Use to set properties to the form store
   * @param formData - Form properties to set in the store
   */
  setFormData(formData) {
    return dispatch =>
      dispatch({ type: this._getSetActionName(), payload: formData });
  }

  /**
   * @param {Object[]} errors - Errors to go into the store
   */
  setFormErrors(errors) {
    if (errors && !Array.isArray(errors)) {
      errors = [errors];
    }
    return dispatch =>
      dispatch({ type: this._getSubmitErrorActionName(), errors });
  }

  /**
   * @param {Object} formData - Form object to validate against ajv schema
   * @returns {*}
   */
  validateForm(formData) {
    return AjvUtil.formatValidationForFinalForm(
      this.compiledAjvSchema,
      formData
    );
  }

  /**
   * Submits a form with give promise and marks as success or error
   * @param {Promise} apiCall
   */
  submitForm(apiCall) {
    return dispatch => {
      dispatch({ type: this._getSubmittingActionName() });

      apiCall
        .then(data => {
          dispatch({
            type: this._getSubmitSuccessActionName(),
            payload: data,
          });
          dispatch({ type: this._getHideActionName() });
        })
        .catch(e => {
          dispatch({ type: this._getSubmitErrorActionName(), errors: [e] });
          throw e;
        });
    };
  }

  /**
   * Pull root state based on form name
   * @param state - Redux State
   */
  select(state) {
    const formState = get(state, this.namespace) || {};
    return formState.formName === this.formName ? formState : {};
  }

  /**
   * Pulls form data from store
   * @param state - Redux State
   */
  selectData(state) {
    const formState = this.select(state);
    return formState.formName === this.formName && formState.data
      ? formState.data
      : {};
  }

  /**
   * Pulls form/submit errors
   * @param state - Redux State
   * @param path - form prop
   */
  selectErrors(state, path) {
    const formState = this.select(state);
    return (
      formState.formName === this.formName && formState.errors
        ? formState.errors
        : []
    ).filter(error => !path || error.path === path);
  }

  /**
   * @private
   */
  _getShowActionName() {
    return `${this.namespace}_${this.formName}_SHOW`;
  }

  /**
   * @private
   */
  _getSetActionName() {
    return `${this.namespace}_${this.formName}_SET`;
  }

  /**
   * @private
   */
  _getSubmittingActionName() {
    return `${this.namespace}_${this.formName}_SUBMIT`;
  }

  /**
   * @private
   */
  _getSubmitSuccessActionName() {
    return `${this.namespace}_${this.formName}_SUBMIT_SUCCESS`;
  }

  /**
   * @private
   */
  _getSubmitErrorActionName() {
    return `${this.namespace}_${this.formName}_SUBMIT_ERROR`;
  }

  /**
   * @private
   */
  _getHideActionName() {
    return `${this.namespace}_${this.formName}_HIDE`;
  }
}
