import React from 'react';
import {
  Badge,
  Banner,
  Brief,
  Button,
  CondensedHeader,
  CondensedLarge,
  ConfirmSubmitDialog,
  DateUtil,
  Flashy,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  Pill,
  Pills,
  ReadonlyTextarea,
} from '@axiom/ui';
import {
  Candidate,
  CandidateProfileIssue,
  Experience,
  Taxonomy,
} from '@axiom/validation';
import { CandidateProfileIssuesUtils } from '@axiom/utils';

import { ExperienceApi } from '../../api/protected/experiences/experience';
import { CandidateExperienceApi } from '../../api/protected/candidates/candidate-experience';
import { CandidateLegacyApi } from '../../api/protected/candidates/candidate-legacy';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';
import { CandidateExperiencesUtil } from '../../utils/candidate-experiences-util';

import { ExperienceMissingIndustryPrompt } from './ExperienceMissingIndustryPrompt';
import { ExperienceItemDisplayTaxonomy } from './ExperienceItemDisplayTaxonomy';

export const ExperienceItemDisplay = ({
  candidateId,
  experience,
  taxonomy,
  showMergeTip,
  issues,
  onExperienceNav,
}: {
  candidateId: Candidate['id'];
  experience: Experience;
  taxonomy: Taxonomy;
  showMergeTip: boolean;
  issues: CandidateProfileIssue[];
  onExperienceNav: () => void;
}) => {
  const { industries } = taxonomy;
  const showExperienceTaxonomy =
    CandidateExperiencesUtil.hasNonDsSuggestions(
      experience.experienceTaxonomy
    ) || experience.generalSkills?.length > 0;
  const deleteExperience = async () => {
    await ExperienceApi.deleteExperience(experience.id);

    await CandidateLegacyApi.refreshCandidate(candidateId);
    await CandidateProfileIssuesApi.refreshCandidateProfileIssues(candidateId);

    return CandidateExperienceApi.refreshExperiences(candidateId);
  };

  return (
    <div data-test="EXPERIENCE_ITEM_DISPLAY">
      <Brief briefMaxHeight="248px" pattern="secondary">
        <Layout position="middle">
          <LayoutItem fluid>
            {experience.isAxiom && (
              <Gutter bottom="8px">
                <Badge background="orange" name="AXIOM_EXPERIENCE_BADGE">
                  Axiom
                </Badge>
              </Gutter>
            )}
            <ParagraphHeader name="CLIENT">{experience.client}</ParagraphHeader>
          </LayoutItem>
          <LayoutItem>
            <Layout horizontalGutter="8px" position="middle">
              {!experience.isAxiom && (
                <ConfirmSubmitDialog
                  name="CONFIRM_DELETE_EXPERIENCE_DIALOG"
                  yesButtonText="Delete"
                  size="tiny"
                  modalTitle="Delete This Experience"
                  modalBody="Deleting this experience removes it from your Axiom for Talent Profile and the bio that Axiom shares with prospective clients."
                  Trigger={
                    <IconButton
                      icon="trash"
                      pattern="secondary"
                      variation="minimal"
                      name="DELETE_EXPERIENCE_ICON"
                    />
                  }
                  onYes={deleteExperience}
                />
              )}
              <IconButton
                name="EDIT_EXPERIENCE_BUTTON"
                icon="pencil"
                pattern="secondary"
                variation="minimal"
                onClick={() => onExperienceNav()}
              />
            </Layout>
          </LayoutItem>
        </Layout>
        <Gutter bottom="8px" />
        {!experience.isAxiom && (
          <Paragraph name="EXTERNAL_OPPORTUNITY_NAME">
            {experience.externalOpportunityName || '--'}
          </Paragraph>
        )}
        <Paragraph name="DATE_RANGE">
          {DateUtil.displayDateRange(experience.startDate, experience.endDate)}
        </Paragraph>
        <Paragraph name="LOCATION">
          {experience.isAxiom
            ? experience.opportunity.locationCityStateCountry
            : experience.locationName}
        </Paragraph>
        <Gutter bottom="16px" />
        {CandidateProfileIssuesUtils.hasExperienceWithMissingIndustryIssue(
          issues,
          experience.id
        ) && (
          <Gutter bottom="16px">
            <Banner name="ADD_INDUSTRY_HINT" type="info" impact="high">
              <Grid>
                <GridRow>
                  <GridColumn smallScreenWidth={12} largeScreenWidth={9}>
                    <ExperienceMissingIndustryPrompt />
                    <Gutter bottom="16px" only="smallScreen" />
                  </GridColumn>
                  <GridColumn smallScreenWidth={12} largeScreenWidth={3}>
                    <Layout position="right">
                      <LayoutItem>
                        <Button
                          pattern="primary"
                          name="EDIT_EXPERIENCE_BANNER_BUTTON"
                          onClick={() => onExperienceNav()}
                        >
                          Edit Now
                        </Button>
                      </LayoutItem>
                    </Layout>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Banner>
          </Gutter>
        )}
        {!experience.isAxiom &&
          /Axiom/i.test(
            `${experience.client} ${experience.externalOpportunityName}`
          ) && (
            <>
              <Banner name="CONVERT_EXPERIENCE_HINT">
                <ParagraphHeader as="span">
                  <Flashy color="textSecondary">
                    Should this be an Axiom experience?
                  </Flashy>
                </ParagraphHeader>{' '}
                It looks like this might be an engagement you had with Axiom in
                the past. Your Talent Success Partner can assist in marking it
                as an Axiom experience if needed.
              </Banner>
              <Gutter bottom="16px" />
            </>
          )}
        {showMergeTip && (
          <>
            <Banner name="MERGE_EXPERIENCE_HINT">
              <ParagraphHeader as="span">
                <Flashy color="textSecondary">
                  Did you do the same work at both of these engagements?
                </Flashy>
              </ParagraphHeader>{' '}
              If so, you should consider merging them. Reach out to your talent
              partner to let them know which experience should be kept.
            </Banner>
            <Gutter bottom="16px" />
          </>
        )}
        <ReadonlyTextarea
          value={experience.description || '--'}
          name="DESCRIPTION"
        />
        {experience?.tags?.length > 0 && (
          <Gutter top="16px">
            <CondensedHeader>Associated Skills</CondensedHeader>
            <Gutter bottom="8px" />
            <Pills name="TAGS">
              {experience.tags.map(tag => (
                <Pill key={tag.id} pattern="secondary">
                  {tag.name}
                </Pill>
              ))}
            </Pills>
          </Gutter>
        )}
        {experience.industryValue && (
          <Gutter top="16px">
            <CondensedHeader>Industries</CondensedHeader>
            <Gutter bottom="8px" />
            <CondensedLarge name="INDUSTRY">
              {industries[experience.industryValue].displayValue}
            </CondensedLarge>
          </Gutter>
        )}
      </Brief>
      {showExperienceTaxonomy && (
        <Gutter vertical="16px">
          <ExperienceItemDisplayTaxonomy
            experience={experience}
            taxonomy={taxonomy}
          />
        </Gutter>
      )}
    </div>
  );
};
