import React from 'react';
import { string } from 'prop-types';
import { Gutter, WarningIcon } from '@axiom/ui';

import { AlignCenter } from '../../themes/components';
import { PALETTE } from '../../themes/constants';

const { grays } = PALETTE;

export const ErrorBlurb = ({ errorMessage }) => (
  <Gutter vertical="0.5rem">
    <AlignCenter>
      <WarningIcon backgroundColor={grays.black} color={grays.white} />
      <span>{errorMessage}</span>{' '}
    </AlignCenter>
  </Gutter>
);

ErrorBlurb.propTypes = {
  errorMessage: string,
};

ErrorBlurb.defaultProps = {
  errorMessage: 'An error occurred. Please refresh.',
};
