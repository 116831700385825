import React, { useCallback } from 'react';
import { shape } from 'prop-types';
import { connect } from 'react-redux';
import { Paragraph } from '@axiom/ui';
import { CandidateProfileUtils } from '@axiom/utils';

import { CandidateOpportunitiesStore } from '../../stores/protected/candidate-opportunities-store';
import { formatDataTestId } from '../../utils/dataTest';

const AvailabilityPreferencesUserStatusComponent = ({
  candidate,
  currentOpportunities,
}) => {
  const getCurrentClientsEngagedCount = useCallback(() => {
    // de-duping array
    const clientSet = new Set(currentOpportunities.map(opp => opp.accountId));
    return clientSet.size;
  });

  const getCurrentEngagedHours = useCallback(() => {
    return CandidateProfileUtils.calculateTotalEngagedHours(
      currentOpportunities
    );
  }, [currentOpportunities]);

  const currentStatus = profileStatus => {
    const statusStarter = 'You are currently';
    let statusSentence;

    switch (profileStatus.toUpperCase()) {
      case 'BEACH':
      case 'WAITLIST': {
        statusSentence = `${statusStarter} on the ${profileStatus.toLowerCase()}.`;
        break;
      }
      case 'ALUM': {
        statusSentence = `${statusStarter} an Axiom alum.`;
        break;
      }
      case 'IDLE': {
        statusSentence = `${statusStarter} not looking for work with Axiom.`;
        break;
      }
      case 'PENDING ACTIVE':
      case 'ACTIVE':
      case 'PENDING BEACH': {
        statusSentence = (
          <span>
            {statusStarter} engaged for{' '}
            <strong data-test={formatDataTestId('current_hours_engaged')}>
              {getCurrentEngagedHours()}
            </strong>{' '}
            hours per week at{' '}
            <strong
              data-test={formatDataTestId('current_clients_engaged_count')}
            >
              {getCurrentClientsEngagedCount()}
            </strong>{' '}
            client
            {getCurrentClientsEngagedCount() !== 1 && 's'}.
          </span>
        );
        break;
      }
      default: {
        break;
      }
    }
    return (
      <div data-test={formatDataTestId('USER_PREFERENCES_USER_STATUS')}>
        {statusSentence && (
          <Paragraph
            name={`PENDO-AVAILABILITY_STATUS-${profileStatus.toUpperCase()}`}
            data-value={profileStatus}
          >
            {statusSentence}
          </Paragraph>
        )}
      </div>
    );
  };

  return currentStatus(candidate.profileStatus);
};

AvailabilityPreferencesUserStatusComponent.propTypes = {
  candidate: shape({}).isRequired,
  currentOpportunities: CandidateOpportunitiesStore.getDataShape().isRequired,
};

export const AvailabilityPreferencesUserStatus = connect(state => ({
  currentOpportunities:
    CandidateOpportunitiesStore.selectCurrentOpportunities(state),
}))(AvailabilityPreferencesUserStatusComponent);
