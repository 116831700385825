import has from 'lodash/has';

export const OldStateComponentUtil = {
  shouldShowInitialLoader(state) {
    if (state.error) {
      return false;
    }

    if ((state.isLoading || !has(state, 'isLoading')) && !state.isFetched) {
      return true;
    }

    return false;
  },
  shouldShowBlockingLoader(state) {
    if (state.error) {
      return false;
    }

    if (state.isLoading && state.isFetched) {
      return true;
    }

    return false;
  },
  shouldShowError(state) {
    return !!state.error;
  },
  shouldShowComponent(state) {
    if (state.error) {
      return false;
    }
    if (
      OldStateComponentUtil.shouldShowInitialLoader(state) &&
      !state.isFetched
    ) {
      return false;
    }

    return true;
  },
};
