import GenericStore from '../../classes/generic-store';

class PhotoConsentModalClass extends GenericStore {
  load(data) {
    return this.setState(data);
  }

  confirm(ref) {
    this.clearState(ref.click());
  }

  decline() {
    this.clearState();
  }
}

export const PhotoConsentModalStore = new PhotoConsentModalClass();
