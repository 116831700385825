import React from 'react';
import { shape, func, arrayOf } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { connect } from 'react-redux';
import {
  Banner,
  Brief,
  Button,
  CompanyLogo,
  CondensedHeader,
  CondensedLarge,
  FluidButtonLayout,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SmallHeader,
} from '@axiom/ui';

import RichTextEditor from '../RichTextEditor/RichTextEditor';
import FormStateHelperTreatment from '../FormStateHelperTreatment/FormStateHelperTreatment';
import { FormNewOpportunityYesStore } from '../../stores/protected/form-new-opportunity-yes-store';
import { FormNewOpportunityNoStore } from '../../stores/protected/form-new-opportunity-no-store';
import { FormNewOpportunityUnsureStore } from '../../stores/protected/form-new-opportunity-unsure-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { OpportunityUtil } from '../../utils/opportunity-util';
import { CandidateExperiencesStore } from '../../stores/protected/candidate-experiences-store';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { DateUtil } from '../../utils/date-util';
import { LocationUtil } from '../../utils/location-util';
import { EnvUtil } from '../../utils/env-util';

import NewOpportunityYesModal from './NewOpportunityYesModal';
import NewOpportunityNoModal from './NewOpportunityNoModal';
import NewOpportunityUnsureModal from './NewOpportunityUnsureModal';

export const NewOpportunityItemComponent = ({
  candidate,
  opportunity,
  experiences,
  yesModalFormState,
  noModalFormState,
  unsureModalFormState,
  openYesModal,
  openNoModal,
  openUnsureModal,
  practiceAreas,
}) => {
  const contactDetails = OpportunityUtil.getContact(opportunity);
  const displayOpportunity = OpportunityUtil.transformDisplayOpportunity(
    candidate,
    opportunity
  );
  return (
    <>
      <FormStateHelperTreatment
        formState={yesModalFormState}
        formItemIdentifier={opportunity.id}
        formComponent={<NewOpportunityYesModal opportunity={opportunity} />}
        isFormModal
      />
      <FormStateHelperTreatment
        formState={noModalFormState}
        formItemIdentifier={opportunity.id}
        formComponent={<NewOpportunityNoModal opportunity={opportunity} />}
        isFormModal
      />
      <FormStateHelperTreatment
        formState={unsureModalFormState}
        formItemIdentifier={opportunity.id}
        formComponent={<NewOpportunityUnsureModal opportunity={opportunity} />}
        isFormModal
      />

      <Banner type="critical">
        {contactDetails && (
          <Gutter bottom="24px">
            <Layout horizontalGutter="8px" position="middle" wrap>
              <LayoutItem>
                <ParagraphHeader>Axiom Opportunity Contact</ParagraphHeader>
              </LayoutItem>
              <LayoutItem>
                <Paragraph>
                  <a href={`mailto:${contactDetails.email}`}>
                    {contactDetails.fullName}
                  </a>
                </Paragraph>
              </LayoutItem>
            </Layout>
          </Gutter>
        )}
        {displayOpportunity.candidateStatusMessage}
        <Gutter bottom="24px" />
        <FluidButtonLayout stackableOn="mobile">
          <Button
            name="pendo-new-submission-unsure-button"
            variation="outline"
            onClick={() => openUnsureModal(opportunity)}
            disabled={
              opportunity.candidateStatus ===
              CandidateOpportunitiesConst.CandidateStatuses.WarmedUnsure
            }
          >
            Unsure
          </Button>
          <Button
            name="pendo-new-submission-no-button"
            variation="outline"
            onClick={() => openNoModal(opportunity)}
          >
            No
          </Button>
          <Button
            name="pendo-new-submission-yes-button"
            onClick={() => openYesModal(opportunity, experiences)}
          >
            Yes
          </Button>
        </FluidButtonLayout>
      </Banner>
      <Gutter bottom="32px" />
      <Layout position="middle" horizontalGutter="16px">
        <LayoutItem>
          <CompanyLogo
            src={`${EnvUtil.clearbitLogoUrl}/${displayOpportunity.account?.website}`}
            alt={displayOpportunity.account?.calculatedName}
          />
        </LayoutItem>
        <LayoutItem fluid>
          <SmallHeader>{displayOpportunity.jobName}</SmallHeader>
          <Gutter bottom="8px" />
          <CondensedLarge>{displayOpportunity.accountName}</CondensedLarge>
        </LayoutItem>
      </Layout>
      <Gutter bottom="24px" />
      <Grid columns="equal">
        <GridRow gutterBottom="8px">
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedHeader>Last Updated</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedLarge>
              {DateUtil.displayShortDate(displayOpportunity.updatedAt) || '--'}
            </CondensedLarge>
            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth="2" smallScreenWidth="12">
            <CondensedHeader>Practice Area</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="4" smallScreenWidth="12">
            <CondensedLarge>
              {practiceAreas?.raw[displayOpportunity.practiceAreaId]?.name ||
                '--'}
            </CondensedLarge>
            <Gutter bottom="8px" only="smallScreen" />
          </GridColumn>
        </GridRow>

        <GridRow gutterBottom="8px">
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedHeader>Engagement Length</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedLarge>
              {displayOpportunity.estimatedEngagementTotalMonths
                ? `~${displayOpportunity.estimatedEngagementTotalMonths} months`
                : '--'}
            </CondensedLarge>
            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth="2" smallScreenWidth="12">
            <CondensedHeader>Location</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="4" smallScreenWidth="12">
            <CondensedLarge>
              {LocationUtil.displayFormattedJobLocation(
                displayOpportunity,
                displayOpportunity?.position
              )}
            </CondensedLarge>
            <Gutter bottom="8px" only="smallScreen" />
          </GridColumn>
        </GridRow>

        <GridRow>
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedHeader>Weekly Commitment</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedLarge>
              {displayOpportunity.weeklyTimeCommitment || '--'}
            </CondensedLarge>
            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth="2" smallScreenWidth="12">
            <CondensedHeader>Role</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="4" smallScreenWidth="12">
            <CondensedLarge>{displayOpportunity.role}</CondensedLarge>
          </GridColumn>
        </GridRow>
      </Grid>

      <Gutter bottom="24px" />

      <CondensedHeader>Work Description</CondensedHeader>
      <Gutter bottom="8px" />
      <Brief briefMaxHeight="200px" pattern="secondary">
        <CondensedLarge>
          <RichTextEditor
            readOnly
            initialValue={displayOpportunity.description || '--'}
          />
        </CondensedLarge>
      </Brief>
    </>
  );
};

NewOpportunityItemComponent.propTypes = {
  candidate: shape({}).isRequired,
  opportunity: shape({}).isRequired,
  experiences: arrayOf(shape({})),
  yesModalFormState: FormNewOpportunityYesStore.getStateShape().isRequired,
  noModalFormState: FormNewOpportunityNoStore.getStateShape().isRequired,
  unsureModalFormState:
    FormNewOpportunityUnsureStore.getStateShape().isRequired,
  openYesModal: func.isRequired,
  openNoModal: func.isRequired,
  openUnsureModal: func.isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
};
NewOpportunityItemComponent.defaultProps = {
  experiences: [],
};

export const NewOpportunityItem = connect(
  state => ({
    candidate: CurrentCandidateStore.selectCandidate(state),
    yesModalFormState: FormNewOpportunityYesStore.selectFormState(state),
    noModalFormState: FormNewOpportunityNoStore.selectFormState(state),
    unsureModalFormState: FormNewOpportunityUnsureStore.selectFormState(state),
    experiences: CandidateExperiencesStore.selectExperiences(state),
    practiceAreas: PreloadedPracticeAreasStore.selectData(state),
  }),
  {
    openYesModal: FormNewOpportunityYesStore.actionShowForm,
    openNoModal: FormNewOpportunityNoStore.actionShowForm,
    openUnsureModal: FormNewOpportunityUnsureStore.actionShowForm,
  }
)(NewOpportunityItemComponent);
