import React from 'react';
import { Banner, Flashy, Gutter, ParagraphHeader } from '@axiom/ui';

export const PreferencesBanner = () => {
  return (
    <Gutter bottom="10px" name="PREFERENCES_BANNER">
      <Banner type="error" impact="high">
        <ParagraphHeader as="span">
          <Flashy bold>It’s time to review your preferences.</Flashy>
        </ParagraphHeader>{' '}
        Take a look at the settings and make any necessary changes.
      </Banner>
    </Gutter>
  );
};
