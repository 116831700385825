import { Api } from '@axiom/ui';
import {
  Candidate,
  CandidateWorkFeedFilter,
  CandidateWorkFeedInterestedCount,
  CandidateWorkFeedSearch,
  CandidateWorkFeedStatistics,
  Opportunity,
} from '@axiom/validation';

import { EnvUtil } from '../../../utils/env-util';

import { CandidateOpportunitiesApi } from './candidate-opportunities';

class WorkFeedApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  readWorkFeed(candidateId: Candidate['id'], body: CandidateWorkFeedFilter) {
    return super.read<CandidateWorkFeedSearch>({
      endpoint: `/candidates/${candidateId}/workFeed/search`,
      body,
      method: 'POST',
    });
  }

  readWorkFeedSaved(
    candidateId: Candidate['id'],
    body: CandidateWorkFeedFilter
  ) {
    return super.read<CandidateWorkFeedSearch>({
      endpoint: `/candidates/${candidateId}/workFeed/saved`,
      query: body,
      method: 'GET',
    });
  }

  readWorkFeedInterested(
    candidateId: Candidate['id'],
    body: CandidateWorkFeedFilter
  ) {
    return super.read<CandidateWorkFeedSearch>({
      endpoint: `/candidates/${candidateId}/workFeed/interested`,
      query: body,
      method: 'GET',
    });
  }

  readWorkFeedInterestedCount(candidateId: Candidate['id']) {
    return super.read<CandidateWorkFeedInterestedCount>({
      endpoint: `/candidates/${candidateId}/workFeed/interestedCount`,
      method: 'GET',
    });
  }

  readWorkFeedStatistics(candidateId: Candidate['id']) {
    return super.read<{ data: CandidateWorkFeedStatistics }>({
      endpoint: `/candidates/${candidateId}/workFeed/statistics`,
      method: 'GET',
    });
  }

  refreshAllOfWorkFeed(
    candidateId: Candidate['id'],
    opportunityId?: Opportunity['id'],
    invalidateInterestedCount?: boolean
  ) {
    const refresh: (Promise<void> | Promise<unknown>)[] = [
      super.invalidate(`/candidates/${candidateId}/workFeed/search`),
      super.invalidate(`/candidates/${candidateId}/workFeed/saved`),
      super.invalidate(`/candidates/${candidateId}/workFeed/interested`),
    ];

    if (invalidateInterestedCount) {
      refresh.push(
        super.invalidate(`/candidates/${candidateId}/workFeed/interestedCount`)
      );
    }

    if (opportunityId) {
      refresh.push(
        CandidateOpportunitiesApi.refreshCandidateWorkFeedItem(
          candidateId,
          opportunityId
        )
      );
    }

    return Promise.all(refresh);
  }

  clearWorkFeed(candidateId: Candidate['id']) {
    return super.clear(`/candidates/${candidateId}/workFeed/search`);
  }

  clearWorkFeedSaved(candidateId: Candidate['id']) {
    return super.clear(`/candidates/${candidateId}/workFeed/saved`);
  }

  clearWorkFeedInterested(candidateId: Candidate['id']) {
    return super.clear(`/candidates/${candidateId}/workFeed/interested`);
  }
}

export const WorkFeedApi = new WorkFeedApiClass();
