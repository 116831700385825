const BASE_FONT_SIZE = 1;

export const FONT = {
  sizes: {
    base: `${BASE_FONT_SIZE}rem`,
    lg: `${BASE_FONT_SIZE * 1.25}rem`,
    sm: `${BASE_FONT_SIZE * 0.875}rem`,
  },
  weights: {
    lighter: 'lighter',
    light: 300,
    normal: 400,
    medium: 500,
    bold: 600,
    bolder: 'bolder',
  },
  lineHeights: {
    base: '1.5rem',
  },
  headerSizes: {
    h1: `${BASE_FONT_SIZE * 2.5}rem`,
    h2: `${BASE_FONT_SIZE * 2}rem`,
    h3: `${BASE_FONT_SIZE * 1.75}rem`,
    h4: `${BASE_FONT_SIZE * 1.5}rem`,
    h5: `${BASE_FONT_SIZE * 1.25}rem`,
    h6: BASE_FONT_SIZE,
  },
};

export const GRAYS = {
  white: '#fff',
  black: '#000',
  gray1: '#ecf0f0',
  gray2: '#dbe0e2',
  gray3: '#bfc6cd',
  gray4: '#a3abb7',
  gray5: '#7d8694',
  gray6: '#5c6471',
  gray7: '#464e5b',
  gray8: '#313844',
  gray9: '#1c222e',
  gray10: '#7d8694', // TODO: fix this so it's not that same as gray5(?)
  gray11: '#1c2223',
  gray12: '#1a1a1a',
  lightest: '#fff',
  neutral10: '#ecf0f0',
  neutral20: '#dbe0e2',
  neutral30: '#bfc6cd',
  neutral40: '#a3abb7',
  neutral50: '#7d8694',
  neutral60: '#5b6471',
  neutral70: '#464e5b',
  neutral80: '#313844',
  neutral90: '#1c222e',
  darkest: '#000',
  malignant10: '#6c757d',
  malignant20: '#e9ecef',
  gray50: '#7F7F7F',
  malignant30: '#ccc',
  malignant40: 'rgba(101, 101, 101, 0.5)',
};

export const COLORS = {
  axiomOrange: '#ff5a2d',
  brick: '#852b32',
  canary: '#fdd355',
  crimson: '#c93333',
  denim: '#477e96',
  darkDenim: '#3A687C',
  forest: '#68855e',
  gold: '#ffbe0d',
  grape: '#7a5579',
  grass: '#b1c472',
  green: '#8CC63F',
  khaki: '#c2ba90',
  lavender: '#ab7eaa',
  olive: '#7c7562',
  rust: '#de5727',
  sienna: '#b84118',
  sky: '#a5d4d2',
  taupe: '#957d73',
  umber: '#5c4a4c',
  midwinterMist: '#a4d3de',
};

const THEME_COLORS = {
  primary: COLORS.axiomOrange,
  secondary: GRAYS.gray9,
  success: COLORS.green,
  info: COLORS.denim,
  warning: COLORS.canary,
  danger: COLORS.crimson,
  light: GRAYS.gray1,
  dark: GRAYS.gray9,
  textMuted: '#6c757d',
};

export const PALETTE = { theme: THEME_COLORS, grays: GRAYS, colors: COLORS };
