import styled from 'styled-components';

import { blockingAppCurtainZIndex } from '../../themes/stacking-context';

export const FrameBlocker = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f0f2f1;
  z-index: ${blockingAppCurtainZIndex};
`;

export const ContentWrapper = styled.div`
  flex: 0;
  height: 200px;
  width: 400px;

  svg {
    display: block;
    margin: 2em auto;
  }
`;
