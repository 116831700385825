import { get } from 'lodash';
import { shape, bool, number, string } from 'prop-types';

/**
 * USAGE
 * When performing a GET call to the server and expecting an object as the payload
 *
 * REDUCER STATES
 * start fetch, fetch successful, fetch errored
 *
 * COMPATIBLE COMPONENTS
 *
 * RecordStateHelperTreatment
 */
const RecordStateHelperShapeStructure = {
  isLoading: bool,
  isFetched: bool,
  data: shape({}),
  error: shape({
    statusCode: number,
    errorMsg: string,
  }),
};
export const RecordStateHelperShape = shape(RecordStateHelperShapeStructure);

export default class RecordStateHelper {
  constructor(namespace, eventName) {
    this.namespace = namespace;
    this.eventName = eventName;
  }

  generateReducers() {
    return {
      [this._getLoadingActionName()]: state => ({
        ...state,
        isLoading: true,
        data: state.data || {}, // For subsequent fetches, we don't want to wipe whats in state
        error: null,
      }),
      [this._getSuccessActionName()]: (state, payload) => ({
        ...state,
        isLoading: false,
        isFetched: true,
        data: payload,
        error: null,
      }),
      [this._getErrorActionName()]: (state, error) => ({
        ...state,
        isLoading: false,
        data: {},
        error,
      }),
    };
  }

  getStructure(dataShape) {
    return { ...RecordStateHelperShapeStructure, data: dataShape };
  }

  /**
   * Have the success response put data object into store for you
   * @param {Promise} apiCall - Api call to fetch object
   */
  fetchRecord(apiCall) {
    return dispatch => {
      dispatch({ type: this._getLoadingActionName() });

      apiCall
        .then(data => {
          dispatch({ type: this._getSuccessActionName(), payload: data });
        })
        .catch(e => {
          dispatch({ type: this._getErrorActionName(), error: e });
        });
    };
  }

  /**
   * Manually put data object into store
   * @param {Object} record - Set the store data record
   */
  setRecord(record) {
    record = record || {};
    return this._getSuccessAction(record);
  }

  /**
   * @param {Object} state - Redux State
   */
  select(state) {
    return { ...get(state, this.namespace) };
  }

  /**
   * Pull the data object out of the store
   * @param {Object} state - Redux State
   */
  selectData(state) {
    return { ...get(state, `${this.namespace}.data`) };
  }

  /**
   * @private
   */
  _getLoadingActionName() {
    return `${this.namespace}_${this.eventName}_START`;
  }

  /**
   * @private
   */
  _getSuccessActionName() {
    return `${this.namespace}_${this.eventName}_SUCCESS`;
  }

  /**
   * @private
   */
  _getErrorActionName() {
    return `${this.namespace}_${this.eventName}_ERROR`;
  }

  /**
   * @private
   */
  _getSuccessAction(payload) {
    return { type: this._getSuccessActionName(), payload };
  }
}
