import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Page = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string | (() => string);
}) => {
  const { pathname } = useLocation();
  const resolvedTitle = typeof title === 'function' ? title() : title;

  useEffect(() => {
    if (resolvedTitle) {
      document.title = resolvedTitle;
    }
  }, [resolvedTitle]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};
