import React from 'react';
import { bool, func, node, oneOf, oneOfType, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { DataTestUtil } from '@axiom/ui';

import { Label } from '../Label/Label';

import {
  CheckboxContainer,
  CheckboxError,
  CheckboxIcon,
  CheckboxIconWrapper,
  CheckboxLabel,
  CheckboxSubtext,
} from './CheckboxStyles';

export const RawCheckbox = ({
  bold,
  checked,
  className,
  disabled,
  label,
  meta,
  name,
  onBlur,
  onChange,
  onFocus,
  subtext,
  verticalAlign,
}) => {
  const inputId = `${name}_checkbox`;
  const subtextId = subtext && `${name}_subtext`;
  const invalid = (meta.touched && (meta.error || meta.submitError)) || null;
  const errorId = invalid && `${name}_error`;
  const describedby = [subtextId, errorId].filter(Boolean).join(' ');

  return (
    <CheckboxContainer
      className={className}
      verticalAlign={verticalAlign}
      data-test={DataTestUtil.format(name || 'checkbox_container')}
      onClick={() => !disabled && onChange(!checked)}
    >
      <CheckboxIconWrapper data-test={DataTestUtil.format('CLICKABLE_AREA')}>
        <input
          aria-describedby={describedby}
          aria-invalid={invalid}
          checked={checked}
          className="sr-only"
          data-test={DataTestUtil.format('checkbox')}
          disabled={disabled}
          id={inputId}
          type="checkbox"
          onChange={e => onChange(e.target.checked)}
          onBlur={e => !disabled && onBlur(e)}
          onFocus={e => !disabled && onFocus(e)}
        />
        <CheckboxIcon
          checked={checked}
          disabled={disabled}
          width={25}
          height={25}
        />
      </CheckboxIconWrapper>
      <span>
        <CheckboxLabel
          htmlFor={inputId}
          $bold={bold}
          $checked={checked}
          $disabled={disabled}
        >
          {typeof label === 'string' ? <Label>{label}</Label> : label}
        </CheckboxLabel>
        {subtext && <CheckboxSubtext id={subtextId}>{subtext}</CheckboxSubtext>}
        <CheckboxError finalFormElementMeta={meta} id={errorId} />
      </span>
    </CheckboxContainer>
  );
};

RawCheckbox.propTypes = {
  bold: bool,
  checked: bool,
  className: string,
  disabled: bool,
  label: oneOfType([string, shape(Label.propTypes)]),
  meta: shape({}),
  name: string,
  onBlur: func,
  onChange: func,
  onFocus: func,
  subtext: oneOfType([string, node]),
  verticalAlign: oneOf(['bottom', 'middle', 'top']),
};

RawCheckbox.defaultProps = {
  bold: false,
  checked: false,
  className: '',
  disabled: false,
  label: null,
  meta: {},
  name: null,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  subtext: null,
  verticalAlign: 'top',
};

export const Checkbox = ({
  bold,
  className,
  disabled,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  subtext,
  verticalAlign,
}) => {
  return (
    <Field name={name} type="checkbox">
      {fieldProps => (
        <RawCheckbox
          bold={bold}
          checked={fieldProps.input.checked}
          className={className}
          disabled={disabled}
          label={label}
          meta={fieldProps.meta}
          name={name}
          subtext={subtext}
          verticalAlign={verticalAlign}
          onBlur={e => {
            fieldProps.input.onBlur(e);
            onBlur(e);
          }}
          onChange={checked => {
            fieldProps.input.onChange(checked);
            onChange(checked);
          }}
          onFocus={e => {
            fieldProps.input.onFocus(e);
            onFocus(e);
          }}
        />
      )}
    </Field>
  );
};

Checkbox.propTypes = {
  bold: bool,
  className: string,
  disabled: bool,
  label: oneOfType([string, shape(Label.propTypes)]),
  name: string,
  onBlur: func,
  onChange: func,
  onFocus: func,
  subtext: oneOfType([string, node]),
  verticalAlign: oneOf(['bottom', 'middle', 'top']),
};

Checkbox.defaultProps = {
  bold: false,
  className: null,
  disabled: false,
  label: null,
  name: null,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  subtext: null,
  verticalAlign: 'top',
};
