import { shape, string } from 'prop-types';

import CollectionStateHelper from '../../lib/collection-state-helper';
import { getSkills } from '../../api/protected/tags/tags';

export const SKILLS_NAMESPACE = 'SKILLS';
const stateSkills = new CollectionStateHelper(SKILLS_NAMESPACE, 'FETCH');
export const SkillsStore = {
  getReducers() {
    return {
      ...stateSkills.generateReducers(),
    };
  },
  getStateShape() {
    return {
      ...stateSkills.getStructure(this.getDataShape()),
    };
  },
  getDataShape() {
    return shape({
      createdAt: string,
      id: string,
      name: string,
      updatedAt: string,
    });
  },
  actionSetSkills(skills) {
    return stateSkills.setCollection(skills);
  },
  actionFetchSkills() {
    return stateSkills.fetchCollection(getSkills());
  },
  selectSkills(state) {
    return stateSkills.selectData(state);
  },
};
