import React from 'react';
import { Experience, Taxonomy } from '@axiom/validation';
import {
  Brief,
  CondensedHeader,
  CondensedLarge,
  Gutter,
  Well,
} from '@axiom/ui';

import { CandidateExperiencesUtil } from '../../utils/candidate-experiences-util';

export type ExperienceItemDisplayTaxonomyProps = {
  experience: Experience;
  taxonomy: Taxonomy;
};

export const ExperienceItemDisplayTaxonomy = ({
  experience,
  taxonomy,
}: ExperienceItemDisplayTaxonomyProps) => {
  const {
    experiencesFocusAreaOrderPreferences,
    generalSkills: experienceGeneralSkills,
    experienceTaxonomy,
  } = experience;
  const { focusAreas, legalSkills, skillGroups, generalSkills } = taxonomy;

  const experienceTaxonomyByFocusArea =
    CandidateExperiencesUtil.groupExperienceTaxonomyByFocusArea(
      experienceTaxonomy,
      experiencesFocusAreaOrderPreferences
    );

  return (
    <Well name="EXPERIENCE_ITEM_DISPLAY_TAXONOMY">
      <Brief briefMaxChildren={3} pattern="secondary">
        {experienceTaxonomyByFocusArea.map(fa => (
          <Gutter bottom="16px" key={fa.focusArea} name="FOCUS_AREA_GROUP">
            <CondensedHeader name="FOCUS_AREA">
              {focusAreas[fa.focusArea].fullName}
            </CondensedHeader>
            {fa.skillGroups.map(sg => (
              <Gutter top="8px" key={sg.skillGroup}>
                <CondensedLarge>
                  <span data-test="SKILL_GROUP">
                    {skillGroups[sg.skillGroup].fullName}:{' '}
                  </span>
                  <span data-test="LEGAL_SKILLS">
                    {sg.legalSkills
                      .map(ls => legalSkills[ls].fullName)
                      .join('; ')}
                  </span>
                </CondensedLarge>
              </Gutter>
            ))}
          </Gutter>
        ))}
        {experienceGeneralSkills?.length > 0 && (
          <Gutter top="8px">
            <CondensedHeader>General Skills</CondensedHeader>
            <Gutter top="8px">
              <CondensedLarge name="GENERAL_SKILLS">
                {experienceGeneralSkills
                  .sort()
                  .map(gs => generalSkills[gs].displayName)
                  .join('; ')}
              </CondensedLarge>
            </Gutter>
          </Gutter>
        )}
      </Brief>
    </Well>
  );
};
