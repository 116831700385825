import styled from 'styled-components';

export const NotificationIndicator = styled.div`
  display: inline-block;
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.dataVizOrange};

  &.read {
    background-color: transparent;
  }
`;
