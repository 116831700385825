import React from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import {
  Gutter,
  Layout,
  ContextMenuItem,
  CondensedLabel,
  CondensedMedium,
} from '@axiom/ui';
import { useNavigate } from 'react-router-dom';

import { NotificationModelShape } from '../../models/notification-model';
import { NotificationsStore } from '../../stores/protected/notifications-store';
import { DateUtil } from '../../utils/date-util';

import { NotificationIndicator } from './NotificationItemStyles';

const NotificationItemComponent = ({
  notification,
  markAllNotificationsRead,
}) => {
  const navigate = useNavigate();

  let notificationBody = notification.body;
  let notificationLink = null;

  try {
    const jsonNotification = JSON.parse(notification.body);
    notificationBody = jsonNotification?.content;
    notificationLink = jsonNotification?.link;

    if (notificationLink?.startsWith('http')) {
      notificationLink = notificationLink.slice(notificationLink.indexOf('//'));
    }
  } catch {
    /* empty */
  }

  const handleClick = () => {
    if (!notification.isRead) {
      markAllNotificationsRead(notification.id);
    }
    if (notificationLink) {
      navigate(notificationLink);
    }
  };

  return (
    <ContextMenuItem onClick={handleClick} name="notificationItem">
      <Layout position="middle">
        <NotificationIndicator
          className={notification.isRead && 'read'}
          data-test="NOTIFICATIONITEMISREADMARKER"
        />

        <CondensedLabel name="notificationItemTitle">
          {notification.subject}
        </CondensedLabel>
      </Layout>
      <Gutter left="16px" top="8px">
        <CondensedMedium name="notificationItemContent">
          {notificationBody}
        </CondensedMedium>
      </Gutter>
      <Gutter left="16px" top="8px">
        <CondensedMedium name="notificationItemDate">
          {DateUtil.displayShortDate(notification.createdAt)}
        </CondensedMedium>
      </Gutter>
    </ContextMenuItem>
  );
};

NotificationItemComponent.propTypes = {
  notification: NotificationModelShape.isRequired,
  markAllNotificationsRead: func.isRequired,
};

export default connect(null, {
  markAllNotificationsRead: NotificationsStore.actionMarkNotificationRead,
})(NotificationItemComponent);
