import React from 'react';
import { Badge, useApi } from '@axiom/ui';
import { CandidateProfileIssuesUtils } from '@axiom/utils';
import { Candidate } from '@axiom/validation';

import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';
import { CandidateLegacyApi } from '../../api/protected/candidates/candidate-legacy';
import { CandidateOpportunitiesLegacyApi } from '../../api/protected/candidates/candidate-opportunities-legacy';
import { CandidateExperienceApi } from '../../api/protected/candidates/candidate-experience';
import { ProfileCompletionUtil } from '../../utils/profile-completion-util';

export const ProfileIssuesCounter: React.FC<{
  candidateId: Candidate['id'];
}> = ({ candidateId }) => {
  const [
    { data: candidate },
    { data: candidateOpportunities },
    { data: profileIssues },
    { data: experiences },
  ] = useApi(
    CandidateLegacyApi.readCandidate(candidateId),
    CandidateOpportunitiesLegacyApi.readOpportunitiesByCandidateId(candidateId),
    CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId),
    CandidateExperienceApi.readExperiences(candidateId)
  );
  const actionNeededItems = ProfileCompletionUtil.getActionNeededItems(
    profileIssues.issues,
    experiences
  );

  return CandidateProfileIssuesUtils.shouldShowProfileCompletionSection(
    candidate,
    candidateOpportunities,
    profileIssues.issues
  ) ? (
    <Badge background="orange" name="profile-issues-count">
      {actionNeededItems.length}
    </Badge>
  ) : null;
};
