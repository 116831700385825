import { patchConfirmations } from '../api/confirmations/confirmations-api';
import GenericStore from '../classes/generic-store';

import { AppStore } from './app-store';

class ConfirmationsStoreClass extends GenericStore {
  load() {
    return this.setState();
  }

  submit(token, candidateId) {
    return this.watchState(
      patchConfirmations(token, {
        action: 'ConfirmAvailability',
        availabilityPreferencesUpdatedAt: new Date(),
        candidateId,
      })
        .then(() => {
          this.load();
        })
        .catch(e => {
          console.error(e); // eslint-disable-line no-console
          this.dispatch(
            AppStore.actionShowStoreError(
              'We can’t confirm your availability preferences at this time'
            )
          );
        })
    );
  }
}

export const ConfirmationsStore = new ConfirmationsStoreClass();
