export const selectifyObject = options =>
  Object.keys(options).map(key => ({
    label: options[key],
    labelString: options[key],
    value: key,
  }));

const selectify = ({ groupLabel, group, options }) =>
  Object.keys(options).map(key => ({
    group,
    groupLabel,
    label: options[key],
    originalLabel: options[key],
    value: key,
  }));

export default selectify;
