import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { WorkFeed } from '../components/WorkFeed/WorkFeed';
import { PageLayout } from '../layouts/PageLayout';

export const WorkFeedPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="WORK-FEED-PAGE">
      <WorkFeed />
    </PageLayout>
  </TalentDataProvider>
);

WorkFeedPage.propTypes = {};
