import { DateUtils } from '@axiom/utils';

const { getDaysBetween } = DateUtils;

// starting at 14 days, users can confirm their availability
// but will not be penalized in profile completion until 30
const DaysUntilAvailabilityPreferencesCanBeConfirmed = 13;

export const CandidatePreferencesUtil = {
  canConfirmPreferences: lastUpdate =>
    !lastUpdate ||
    getDaysBetween(new Date(lastUpdate), new Date()) >
      DaysUntilAvailabilityPreferencesCanBeConfirmed,
};
