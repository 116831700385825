import moment from 'moment';
import { Experience } from '@axiom/validation';

export const CandidateExperiencesUtil = {
  createShouldShowMergeTipMapping: (experiences: Experience[]) => {
    return Object.fromEntries(
      experiences
        // for each Axiom experience, mark true if
        .filter(({ isAxiom }) => isAxiom)
        .map((experience, index, array) => [
          experience.id,
          array
            // another experience later in the sorted array (aka "earlier" in time)
            .slice(index + 1)
            // at the same client
            .filter(
              earlierExperience =>
                earlierExperience.client === experience.client
            )
            // exists where one of the following conditions is met:
            .some(
              earlierExperience =>
                // it's (another) ongoing engagement
                earlierExperience.endDate === null ||
                // it's an overlapping engagement
                moment(experience.startDate).isSameOrBefore(
                  moment(earlierExperience.endDate),
                  'day'
                ) ||
                // it's an earlier engagement that ended within 180 days of this one starting
                moment(experience.startDate).isSameOrBefore(
                  moment(earlierExperience.endDate).add(180, 'days'),
                  'day'
                )
            ),
        ])
    );
  },

  /**
   * Groups experience taxonomy by focus area and removes ds suggestions
   * @param experienceTaxonomy
   * @param experiencesFocusAreaOrderPreferences
   */
  groupExperienceTaxonomyByFocusArea: (
    experienceTaxonomy: Experience['experienceTaxonomy'],
    experiencesFocusAreaOrderPreferences: Experience['experiencesFocusAreaOrderPreferences']
  ) => {
    type ByFocusArea = {
      focusArea: string;
      skillGroups: {
        skillGroup: string;
        legalSkills: string[];
      }[];
    };

    // Sorting used for skill groups, legal skills, and unordered focus areas
    const lowerCaseSort = (a: string, b: string) => {
      const lowerA = a.toLowerCase();
      const lowerB = b.toLowerCase();
      if (lowerA < lowerB) return -1;
      if (lowerA > lowerB) return 1;
      return 0;
    };

    const expTax: ByFocusArea[] = [];

    const experienceTaxonomyMap = experienceTaxonomy.reduce(
      (acc, curr) => {
        const { focusArea, skillGroup, legalSkills } = curr;
        if (curr.dsSuggestion) {
          return acc;
        } else if (acc[focusArea]) {
          if (skillGroup) {
            acc[focusArea].skillGroups.push({
              skillGroup,
              legalSkills: legalSkills.sort(lowerCaseSort),
            });
            acc[focusArea].skillGroups.sort((a, b) =>
              lowerCaseSort(a.skillGroup, b.skillGroup)
            );
          }
        } else {
          acc[focusArea] = {
            focusArea,
            skillGroups: skillGroup
              ? [
                  {
                    skillGroup,
                    legalSkills: legalSkills.sort(lowerCaseSort),
                  },
                ]
              : [],
          };
        }
        return acc;
      },
      {} as Record<string, ByFocusArea>
    );

    // order focus areas if there is a preference
    // There can only be 0 or 1 order preferences
    if (experiencesFocusAreaOrderPreferences[0]?.focusAreas) {
      const { focusAreas } = experiencesFocusAreaOrderPreferences[0];
      focusAreas.forEach(fa => {
        if (experienceTaxonomyMap[fa]) {
          expTax.push({ ...experienceTaxonomyMap[fa] });
          delete experienceTaxonomyMap[fa];
        }
      });
    }

    // unordered values show at the end
    return [
      ...expTax,
      ...Object.keys(experienceTaxonomyMap)
        .sort(lowerCaseSort)
        .map(k => experienceTaxonomyMap[k]),
    ];
  },

  /**
   * Checks if any experience taxonomy was added manually by the user
   * @param experienceTaxonomy
   */
  hasNonDsSuggestions: (
    experienceTaxonomy: Experience['experienceTaxonomy']
  ) => {
    return experienceTaxonomy.some(et => et.dsSuggestion === false);
  },
};
