import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout';
import { ProfilePreferencesView } from '../components/PreferencesViews/ProfilePreferencesView';

export const ProfilePreferencesPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="PROFILE-PREFERENCES-PAGE">
      <ProfilePreferencesView />
    </PageLayout>
  </TalentDataProvider>
);
