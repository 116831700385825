// Put all z-index usages in here!

export const descriptionList = 1;
export const footerIcon = 1;
export const activePanel = 2;
export const popover = 3;
export const contextualMenu = 4;
export const modal = 100;
export const blockingCurtainZIndex = 1000;
export const appErrors = 3000;
export const blockingAppCurtainZIndex = 10000;
