import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { AxiomLogo } from '@axiom/ui';

import { DEFAULT_MAX_LOAD_TIME } from './loading-treatment-consts';
import { FrameBlocker, ContentWrapper } from './LoadingTreatmentStyles';

const INITIAL_STATE = {
  status: 0,
};

class LoadingTreatment extends Component {
  constructor(config) {
    super(config);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const { maxLoadMilliseconds } = this.props;
    const { status } = this.state;
    // TODO: Scroll blocking starts here
    const intervalRate = maxLoadMilliseconds / 300;

    this.interval = setInterval(() => {
      const was = status;
      const willBe = was < 100 ? was + 1 : 0;
      this.setState({ status: willBe });
    }, intervalRate);
  }

  componentWillUnmount() {
    // TODO: Stop scroll blocking stops here
    clearInterval(this.interval);
  }

  render() {
    const { status } = this.state;
    return (
      <FrameBlocker>
        <ContentWrapper>
          <AxiomLogo />
          <Progress value={status} />
        </ContentWrapper>
      </FrameBlocker>
    );
  }
}

LoadingTreatment.defaultProps = {
  maxLoadMilliseconds: DEFAULT_MAX_LOAD_TIME,
};

LoadingTreatment.propTypes = {
  maxLoadMilliseconds: PropTypes.number,
};

export default LoadingTreatment;
