import React from 'react';
import { shape } from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Brief,
  Button,
  CardSection,
  CompanyLogo,
  CondensedHeader,
  CondensedLarge,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SectionHeader,
  useApi,
} from '@axiom/ui';

import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { EnvUtil } from '../../utils/env-util';
import { OpportunityUtil } from '../../utils/opportunity-util';
import { DateUtil } from '../../utils/date-util';
import { LocationUtil } from '../../utils/location-util';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { PermissionsApi } from '../../api/permissions-api';

export const CurrentEngagementItem = ({
  engagement,
  candidate,
  practiceAreas,
}) => {
  const displayEngagement = OpportunityUtil.transformDisplayOpportunity(
    candidate,
    engagement
  );

  const { candidateId } = useParams();

  const [{ data: apiPermissions }] = useApi(
    PermissionsApi.readPermissions(candidate.record?.id || candidateId)
  );

  return (
    <>
      <CardSection divider>
        <Layout position="middle" horizontalGutter="16px">
          <LayoutItem>
            <CompanyLogo
              src={`${EnvUtil.clearbitLogoUrl}/${engagement.account?.website}`}
              alt={engagement.account?.calculatedName}
            />
          </LayoutItem>
          <LayoutItem fluid>
            <ParagraphHeader>{displayEngagement.jobName}</ParagraphHeader>
            <Gutter bottom="8px" />
            <Paragraph>{displayEngagement.accountName}</Paragraph>
          </LayoutItem>
        </Layout>

        <Gutter bottom="16px" />

        {apiPermissions.workday && (
          <>
            <Button
              name="pendo-submit-timesheet"
              toTab={`${EnvUtil.apiPortalUrl}/saml/workday?RelayState=timesheet`}
              fluid
            >
              Submit Timesheet
            </Button>
            <Gutter bottom="16px" />
          </>
        )}

        <CondensedHeader>Client Contact</CondensedHeader>

        <CondensedLarge>
          {displayEngagement.salesforceContact ? (
            <a href={`mailto: ${displayEngagement.salesforceContact.email}`}>
              {displayEngagement.clientName}
            </a>
          ) : (
            displayEngagement.clientName
          )}
        </CondensedLarge>

        <Gutter bottom="16px" />

        <CondensedHeader>Location</CondensedHeader>
        <CondensedLarge>
          {LocationUtil.displayFormattedJobLocation(
            displayEngagement,
            displayEngagement.position
          )}
        </CondensedLarge>
        <Gutter bottom="16px" />

        <CondensedHeader>Practice Area</CondensedHeader>
        <CondensedLarge>
          {practiceAreas?.raw[displayEngagement.practiceAreaId]?.name || '--'}
        </CondensedLarge>
        <Gutter bottom="16px" />

        <CondensedHeader>Weekly Commitment</CondensedHeader>
        <CondensedLarge>
          {displayEngagement.weeklyTimeCommitment || '--'}
        </CondensedLarge>
        <Gutter bottom="16px" />

        <CondensedHeader>Start Date</CondensedHeader>
        <CondensedLarge>
          {DateUtil.displayShortDate(displayEngagement.startDate)}
        </CondensedLarge>
        <Gutter bottom="16px" />

        <CondensedHeader name="PENDO_EXPECTED_ENG_END_DATE">
          Expected End Date
        </CondensedHeader>
        <CondensedLarge>
          {DateUtil.displayDate(displayEngagement.position.endDate)}
          {' - '}
          {displayEngagement.position.endDateStatus === 'Confirmed'
            ? 'Confirmed'
            : 'Extension possible'}
        </CondensedLarge>
        <Gutter bottom="16px" />

        <CondensedHeader>Work Description</CondensedHeader>
        <CondensedLarge>
          <Brief briefMaxHeight="200px" pattern="secondary">
            <RichTextEditor
              readOnly
              initialValue={displayEngagement.description || '--'}
            />
          </Brief>
        </CondensedLarge>
      </CardSection>
      {(engagement.account?.ownerUser?.fullName ||
        candidate.record.ownerUser?.fullName) && (
        <CardSection divider>
          <SectionHeader name="pendo-current-engagement-team">
            Axiom Team
          </SectionHeader>

          {engagement.account?.ownerUser?.fullName && (
            <Gutter top="16px">
              <CondensedHeader>Engagement Contact</CondensedHeader>
              <CondensedLarge>
                <a href={`mailto: ${engagement.account.ownerUser.email}`}>
                  {engagement.account.ownerUser.fullName}
                </a>
              </CondensedLarge>
            </Gutter>
          )}

          {candidate.record.ownerUser?.fullName && (
            <Gutter top="16px">
              <CondensedHeader>Talent Partner</CondensedHeader>
              <CondensedLarge>
                <a href={`mailto: ${candidate.record.ownerUser.email}`}>
                  {candidate.record.ownerUser.fullName}
                </a>
              </CondensedLarge>
            </Gutter>
          )}
        </CardSection>
      )}
    </>
  );
};

CurrentEngagementItem.defaultProps = {
  practiceAreas: {
    raw: {},
  },
};

CurrentEngagementItem.propTypes = {
  practiceAreas: PreloadedPracticeAreasStore.getDataShape(),
  engagement: shape({}).isRequired,
  candidate: shape({}).isRequired,
};
