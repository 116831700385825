import { Api } from '@axiom/ui';
import { PracticeArea } from '@axiom/validation';

import ApiHelper from '../../../lib/api-helper';
import { PRACTICE_AREAS_API_NAME } from '../../constants';
import { EnvUtil } from '../../../utils/env-util';

const api = new ApiHelper(PRACTICE_AREAS_API_NAME);

export const getPracticeAreas = async () => {
  const { data } = await api.GET('/practiceAreas');
  return data;
};

class PracticeAreaApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readPracticeAreas() {
    return super.read<{ data: PracticeArea[] }>({
      endpoint: '/practiceAreas',
      method: 'GET',
    });
  }
}

export const PracticeAreaApi = new PracticeAreaApiClass();

export default {
  getPracticeAreas,
};
