import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout';
import { WorkStylePreferencesView } from '../components/PreferencesViews/WorkStylePreferencesView';

export const WorkStylePreferencesPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="WORK-STYLE-PREFERENCES-PAGE">
      <WorkStylePreferencesView />
    </PageLayout>
  </TalentDataProvider>
);
