import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Layout,
  SidedrawerFooter,
  SidedrawerHeader,
  SidedrawerSection,
  SidedrawerUtil,
  SmallHeader,
  useApi,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { WorkFeedFilterUtil } from '../../utils/work-feed-filter-util';
import { PracticeAreaApi } from '../../api/protected/practice-areas/practice-areas';
import { WorkFeedApi } from '../../api/protected/candidates/candidate-workfeed';
import { WorkFeedFiltersForm } from '../WorkFeedFiltersForm/WorkFeedFiltersForm';

export const WorkFeedFiltersSidedrawer = ({
  candidate,
}: {
  candidate: Candidate;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [{ data: practiceAreas }] = useApi(PracticeAreaApi.readPracticeAreas());

  const getFilters = () =>
    WorkFeedFilterUtil.getFilters(searchParams, candidate, practiceAreas);

  const getSearchFilters = () => {
    return {
      page: 1,
      pageSize: 10,
      filters: getFilters(),
    };
  };

  const [search] = useApi(
    WorkFeedApi.readWorkFeed(candidate.id, getSearchFilters())
  );

  return (
    <>
      <SidedrawerHeader
        onClose={() => SidedrawerUtil.remove('work-feed-filters-side-drawer')}
        name="WORK_FEED_FILTERS_SIDEDRAWER_HEADER"
      >
        <Layout position="middle left">
          <SmallHeader>Filters</SmallHeader>
        </Layout>
      </SidedrawerHeader>
      <SidedrawerSection>
        <WorkFeedFiltersForm candidate={candidate} filters={getFilters()} />
      </SidedrawerSection>
      <SidedrawerFooter>
        <Button
          name="CLEAR_ALL"
          variation="outline"
          onClick={() =>
            WorkFeedFilterUtil.applyFilters(
              setSearchParams,
              candidate,
              practiceAreas,
              null
            )
          }
        >
          Clear All
        </Button>
        <Button
          onClick={() => SidedrawerUtil.remove('work-feed-filters-side-drawer')}
          name="SHOW"
        >
          Show {search.meta.resultCount}
        </Button>
      </SidedrawerFooter>
    </>
  );
};
