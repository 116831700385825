import React, { createRef } from 'react';
import { Ref } from 'semantic-ui-react';

import { StickyContext } from './StickyContext';

export const StickyLayout = ({
  className,
  children,
  name,
}: {
  className?: string;
  children: React.ReactNode;
  name?: string;
}) => {
  const ref = createRef<HTMLElement>();

  return (
    <div data-test={name} className={className}>
      <Ref innerRef={ref}>
        <StickyContext.Provider value={ref}>{children}</StickyContext.Provider>
      </Ref>
    </div>
  );
};
