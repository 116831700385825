import styled from 'styled-components';
import { loadLightPalette } from '@axiom/ui';

import { PALETTE } from './constants';

export const KendoErrorWrapper = styled.div`
  ${({ invalid }) => {
    if (invalid) {
      return `
        .form-control.is-invalid {
          border: solid 1px ${PALETTE.theme.danger} !important;
          padding-bottom: 2rem !important;      
        }
      `;
    }
    return null;
  }};
`;

export const DateRangePickerWrapper = styled.div`
  label.k-label {
    display: none;
  }

  .k-textbox-container {
    padding-top: 0;
    width: 50%;
    float: left;
  }

  input {
    padding: 0.2rem !important;
  }

  span:nth-child(2) {
    display: none;
  }
`;

const loadInteractions = () => {
  const baseTheme = loadLightPalette();

  baseTheme.interactions.denim = {
    default: {
      text: PALETTE.grays.white,
      background: PALETTE.colors.denim,
      border: PALETTE.colors.denim,
    },
    hover: {
      text: PALETTE.grays.white,
      background: PALETTE.colors.darkDenim,
      border: PALETTE.colors.darkDenim,
    },
    active: {
      text: PALETTE.grays.white,
      background: PALETTE.colors.darkDenim,
      border: PALETTE.colors.darkDenim,
    },
    disabled: {
      text: PALETTE.grays.white,
      background: PALETTE.colors.darkDenim,
      border: PALETTE.colors.darkDenim,
    },
  };

  baseTheme.interactions.inverseDenim = {
    default: {
      text: PALETTE.colors.denim,
      background: PALETTE.grays.white,
      border: PALETTE.colors.denim,
    },
    hover: {
      text: PALETTE.colors.darkDenim,
      background: PALETTE.grays.white,
      border: PALETTE.colors.darkDenim,
    },
    active: {
      text: PALETTE.colors.darkDenim,
      background: PALETTE.grays.white,
      border: PALETTE.colors.darkDenim,
    },
    disabled: {
      text: PALETTE.grays.white,
      background: PALETTE.grays.gray7,
      border: PALETTE.grays.gray7,
      opacity: '0.25',
    },
  };

  return baseTheme;
};

export default {
  main: {
    primaryText: '#000000',
    colorPrimary: '#2B2B2B',
    colorSecondary: '#707070',
    appBackground: '#F6F6F6',
    disabledColor: '#adadad',
    contentBackground: '#FFFFFF',
    positiveColor: '#C7D66D',
    negativeColor: '#800000',
    ready: '#92B5A5',
    unqualified: '#93A3B1',
    qualified: '#4D7C8A',
    interviewing: '#B1B89C',
    contracting: '#C7D66D',
    axiomOrange: '#ec653e',
    ...loadInteractions(),
  },
  dark: {
    primaryText: '#ffffff',
  },
};

export const getThemeProp =
  property =>
  ({ theme }) =>
    theme[property];
