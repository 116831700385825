import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SmallHeader,
  useBreakpoint,
} from '@axiom/ui';

import ReferralDollars from '../../public/svg/referral-dollars.svg';
import ReferralHandshake from '../../public/svg/referral-handshake.svg';
import { PreloadedPermissionsStore } from '../../stores/preloaded-permissions-store';
import { EnvUtil } from '../../utils/env-util';

const { canViewReferClient, canViewReferLawyer } = PreloadedPermissionsStore;

const UsefulLinksComponent = ({
  permissions,
}: {
  permissions: {
    viewReferLawyer: boolean;
    viewReferClient: boolean;
  };
}) => {
  const { isSmallScreen } = useBreakpoint();
  if (!canViewReferClient(permissions) || !canViewReferLawyer(permissions)) {
    return null;
  }

  return (
    <Card name="USEFUL-LINKS">
      <CardHeader>
        <SmallHeader>Referral Bonus</SmallHeader>
      </CardHeader>
      <CardSection>
        <Layout direction="vertical" verticalGutter="32px">
          {canViewReferClient(permissions) && (
            <Layout
              horizontalGutter="24px"
              wrap={isSmallScreen}
              name="REFER-CLIENT"
            >
              <LayoutItem>
                <ReferralHandshake width="50px" height="50px" />
                <Gutter bottom="16px" />
              </LayoutItem>
              <LayoutItem fluid>
                <ParagraphHeader>Refer a Client</ParagraphHeader>
                <Gutter bottom="8px" />
                <Paragraph>
                  Refer a new business opportunity and earn up to $5,000 as a
                  thank you.
                </Paragraph>
                <Gutter bottom="8px" />
                <a
                  href={EnvUtil.referClientUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-test="REFER_A_CLIENT_BUTTON"
                >
                  Referral Link
                </a>
              </LayoutItem>
            </Layout>
          )}
          {canViewReferLawyer(permissions) && (
            <Layout
              horizontalGutter="24px"
              wrap={isSmallScreen}
              name="REFER-LAWYER"
            >
              <LayoutItem fluid>
                <ReferralDollars width="50px" height="50px" />
                <Gutter bottom="16px" />
              </LayoutItem>
              <LayoutItem>
                <ParagraphHeader>Refer a Lawyer</ParagraphHeader>
                <Gutter bottom="8px" />
                <Paragraph>
                  Do you know a top-caliber lawyer who might be interested in
                  working at Axiom? Send them our way!
                </Paragraph>
                <Gutter bottom="8px" />
                <a
                  href={EnvUtil.referLawyerUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-test="REFER_A_LAWYER_BUTTON"
                >
                  Referral Link
                </a>
              </LayoutItem>
            </Layout>
          )}
        </Layout>
      </CardSection>
    </Card>
  );
};

export const UsefulLinks = connect(state => ({
  permissions: PreloadedPermissionsStore.select(state),
}))(UsefulLinksComponent);
