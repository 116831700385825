const hasRole = (user, role) => user.roles.includes(role);

export const isHQUser = user =>
  hasRole(user, 'EnvoyUser') || hasRole(user, 'EnvoyAdmin');

export const isSalesUser = user => hasRole(user, 'EnvoySales');

export const isLPUser = user => hasRole(user, 'LPUser');

export const isEnvoyUser = user => hasRole(user, 'EnvoyUser');
