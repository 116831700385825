import React from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  SmallHeader,
  Suspense,
} from '@axiom/ui';

import { PreferredWeeklyHours } from '../PreferredWeeklyHours/PreferredWeeklyHours';
import { InterviewAvailability } from '../InterviewAvailability/InterviewAvailability';

export const AvailabilityPreferences = () => {
  return (
    <Card>
      <CardHeader>
        <SmallHeader name="PENDO-MY-WORK-INFO">Availability</SmallHeader>
      </CardHeader>
      <CardSection divider>
        <PreferredWeeklyHours />
      </CardSection>
      <CardSection>
        <Suspense>
          <InterviewAvailability />
        </Suspense>
      </CardSection>
    </Card>
  );
};
