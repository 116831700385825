import React from 'react';
import { FormFieldProps as SemanticFieldProps } from 'semantic-ui-react';

import { FormField, FormFieldProps } from '../FormField/FormField';

import { RawToggle, RawToggleProps, RawToggleValueType } from './RawToggle';

export type ToggleProps<FormValue> = {
  description?: FormFieldProps<FormValue>['description'];
  disabled?: RawToggleProps['disabled'];
  id?: RawToggleProps['id'];
  label?: FormFieldProps<FormValue>['label'];
  name: RawToggleProps['name'];
  onChange?: RawToggleProps['onChange'];
};

export const Toggle = <FormValue,>({
  description,
  disabled = false,
  id,
  label,
  name,
  onChange = value => value,
}: ToggleProps<FormValue>) => {
  const formattedId = id || name;
  return (
    <FormField
      {...{
        dataTest: name,
        description,
        disabled,
        id: formattedId,
        label,
        name,
      }}
      renderField={({
        value,
        setFieldValue,
        setFieldTouched,
      }: { value: RawToggleValueType } & SemanticFieldProps) => (
        <RawToggle
          disabled={disabled}
          id={formattedId}
          name={name}
          onChange={v => {
            setFieldTouched(name);
            onChange(v);
            setFieldValue(name, v);
          }}
          value={value}
        />
      )}
    />
  );
};
