import styled from 'styled-components';

import { PALETTE } from '../../themes/constants';

export const DivButtonsWrapper = styled.div`
  border-right: 0.0625rem solid lightgrey;
  border-bottom: 0.0625rem solid lightgrey;
  border-left: 0.0625rem solid lightgrey;
  padding: 0.25rem;
`;

const getBorderColor = (invalid, readOnly) => {
  if (invalid) {
    return `0.0625rem solid ${PALETTE.theme.danger}`;
  }

  if (readOnly) {
    return 'none';
  }

  return '0.0625rem solid lightgrey';
};

export const DivEditorWrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 50px;
  transition: height 0.35s ease-in-out;
  ${({ readOnly, readOnlyHeight }) =>
    readOnly &&
    typeof readOnlyHeight === 'number' &&
    `height: ${readOnlyHeight}px`};
  @media print {
    transition: none;
    height: auto;
  }

  border: ${({ invalid, readOnly }) => getBorderColor(invalid, readOnly)};
  padding: ${({ readOnly }) => (readOnly ? '0' : '0.5rem')};

  .editors,
  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    ${({ readOnly }) => !readOnly && 'height: 100%;'};
    ${({ readOnly }) => !readOnly && 'min-height: 10rem;'};
  }
`;

export const DivRichTextEditorWrapper = styled.div``;

export const ButtonUlToggleWrapper = styled.button`
  cursor: pointer;
  border: none;
  outline: none !important;
`;
