import React from 'react';
import { ProfileCompletionConst } from '@axiom/const';
import {
  Banner,
  Button,
  CondensedSmall,
  Flashy,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SectionHeader,
} from '@axiom/ui';

export const LegalTechSkillsHintBanner: React.FC<{
  onAddNowClicked: () => void;
}> = ({ onAddNowClicked }) => {
  return (
    <Banner name="ADD_LEGAL_TECH_SKILLS_HINT" type="info" impact="high">
      <Grid>
        <GridRow>
          <GridColumn smallScreenWidth={12} largeScreenWidth={9}>
            <Layout horizontalGutter="8px" position="middle" wrap>
              <LayoutItem>
                <SectionHeader>
                  {
                    ProfileCompletionConst
                      .ProfileCompletionLegalTechSkillsBanner.missingTitle
                  }
                </SectionHeader>
              </LayoutItem>
              <LayoutItem>
                <CondensedSmall>
                  <Flashy color="textSubtle">|</Flashy>
                </CondensedSmall>
              </LayoutItem>
              <LayoutItem>
                <CondensedSmall>
                  <Flashy color="textSubtle">5 min</Flashy>
                </CondensedSmall>
              </LayoutItem>
            </Layout>
            <Gutter bottom="8px" />
            <Paragraph>
              {
                ProfileCompletionConst.ProfileCompletionLegalTechSkillsBanner
                  .missingContent
              }
            </Paragraph>
            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn smallScreenWidth={12} largeScreenWidth={3}>
            <Layout position="right">
              <LayoutItem>
                <Button
                  name="PENDO_ADD_LEGAL_TECH_SKILLS_BANNER_BUTTON"
                  onClick={() => onAddNowClicked()}
                >
                  Add Now
                </Button>
              </LayoutItem>
            </Layout>
          </GridColumn>
        </GridRow>
      </Grid>
    </Banner>
  );
};
