import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout';
import { ProgramsPreferencesView } from '../components/PreferencesViews/ProgramsPreferencesView';

export const ProgramsPreferencesPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="PROGRAMS-PREFERENCES-PAGE">
      <ProgramsPreferencesView />
    </PageLayout>
  </TalentDataProvider>
);
