import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@axiom/ui';

export const WorkFeedSPVBackButton = () => {
  const location = useLocation();
  const previousPath = location.state?.from?.pathname;
  let text = 'All Opportunities';

  if (previousPath) {
    if (previousPath.includes('saved')) {
      text = 'Saved Opportunities';
    } else if (previousPath.includes('interested')) {
      text = 'Interested Opportunities';
    }
  }

  return (
    <div>
      {previousPath ? (
        <Button
          variation="minimal"
          name="BACK_BUTTON"
          icon="arrow-left"
          iconPosition="left"
          to={previousPath}
        >
          {text}
        </Button>
      ) : (
        <Link to="../all" data-test="BACK_LINK">
          <Button
            variation="minimal"
            name="BACK_BUTTON"
            icon="arrow-left"
            iconPosition="left"
          >
            {text}
          </Button>
        </Link>
      )}
    </div>
  );
};
