import React from 'react';
import { func } from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
} from '@axiom/ui';

export const ProfileImageEditDeleteConfirmModal = ({ onDecline, onDelete }) => (
  <Modal size="confirm">
    <ModalHeader onClose={onDecline}>Delete Photo</ModalHeader>
    <ModalSection>Are you sure you want to delete this photo?</ModalSection>
    <ModalFooter>
      <Button variation="outline" name="CANCEL" onClick={onDecline}>
        Cancel
      </Button>

      <Button name="DELETE" onClick={onDelete}>
        Delete
      </Button>
    </ModalFooter>
  </Modal>
);

ProfileImageEditDeleteConfirmModal.propTypes = {
  onDecline: func.isRequired,
  onDelete: func.isRequired,
};
