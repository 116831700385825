import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { Form as FFForm, Field as FFField } from 'react-final-form';
import { CandidateOpportunitiesConst } from '@axiom/const';
import {
  Button,
  DataTestUtil,
  Modal,
  Text,
  ModalSection,
  ModalHeader,
  ModalFooter,
  Grid,
  GridRow,
  GridColumn,
} from '@axiom/ui';

import { FormNewOpportunityNoStore } from '../../stores/protected/form-new-opportunity-no-store';
import { RadioFF } from '../RadioFF/RadioFF';

import {
  NOFormGroupIndent,
  NOInputColumnWrapper,
  NOSmallBlurbWrapper,
} from './NewOpportunitiesStyles';

const optOutReasons = [
  {
    label: "I don't have the time right now.",
    value: CandidateOpportunitiesConst.ReasonForNo.DontHaveTime,
    triggerText:
      'If there has been a change in your availability that will have an impact beyond this one engagement, please make the appropriate changes in "Preferences" to reflect that.',
  },
  {
    label: 'This location is not convenient for me.',
    value: CandidateOpportunitiesConst.ReasonForNo.InconvenientLocation,
    triggerText:
      'If there has been a change in your current location that will have an impact beyond this one engagement, please make the appropriate changes in "Preferences" to reflect that.',
  },
  {
    label: 'This is not in line with my expertise.',
    value: CandidateOpportunitiesConst.ReasonForNo.WrongAreaOfExpertise,
    triggerText:
      "This opportunity was sent to your Portal because of it's alignment with your past experiences. If you don't believe that to be the case, please ensure that your prior experience in your Profile is up to date.",
  },
  {
    label: "I'd like to explain over the phone.",
    value: CandidateOpportunitiesConst.ReasonForNo.Other,
    triggerText:
      "We'll reach out so you can tell us more about the reason for your decision.",
  },
];

export class NewOpportunityNoModalComponent extends React.PureComponent {
  render() {
    const { opportunityNoForm, submitForm, cancelForm } = this.props;

    return (
      <FFForm
        onSubmit={submitForm}
        initialValues={opportunityNoForm}
        render={props => (
          <Modal>
            <ModalHeader onClose={cancelForm}>
              Opt Out of Opportunity
            </ModalHeader>
            <ModalSection>
              <h4>Reason for Opt-Out</h4>
              <p>
                Select the reason for your decision. This helps us better match
                you with future opportunities.
              </p>
              <NOFormGroupIndent>
                <Grid>
                  <GridRow>
                    <GridColumn width={6}>
                      {optOutReasons.slice(0, 2).map(item => (
                        <NOInputColumnWrapper key={item.value}>
                          <FFField
                            name="candidateReasonForNo"
                            label={item.label}
                            value={item.value}
                            component={RadioFF}
                            type="radio"
                          />
                        </NOInputColumnWrapper>
                      ))}
                    </GridColumn>
                    <GridColumn width={6}>
                      {optOutReasons.slice(2).map(item => (
                        <NOInputColumnWrapper key={item.value}>
                          <FFField
                            name="candidateReasonForNo"
                            label={item.label}
                            value={item.value}
                            component={RadioFF}
                            type="radio"
                          />
                        </NOInputColumnWrapper>
                      ))}
                    </GridColumn>
                  </GridRow>
                </Grid>
              </NOFormGroupIndent>
              <NOSmallBlurbWrapper>
                {props.values.candidateReasonForNo && (
                  <Text size="small" name="trigger-text">
                    {
                      optOutReasons.find(
                        item => item.value === props.values.candidateReasonForNo
                      ).triggerText
                    }
                  </Text>
                )}
              </NOSmallBlurbWrapper>
            </ModalSection>
            <ModalFooter>
              <Button
                variation="outline"
                onClick={cancelForm}
                name={DataTestUtil.format('CANCEL')}
              >
                Cancel
              </Button>
              <Button
                disabled={!props.values.candidateReasonForNo}
                onClick={props.handleSubmit}
                name={DataTestUtil.format('SUBMIT')}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        )}
      />
    );
  }
}

NewOpportunityNoModalComponent.defaultProps = {};
NewOpportunityNoModalComponent.propTypes = {
  opportunityNoForm: FormNewOpportunityNoStore.getDataShape().isRequired,
  submitForm: func.isRequired,
  cancelForm: func.isRequired,
};
export default connect(
  state => ({
    opportunityNoForm: FormNewOpportunityNoStore.selectFormData(state),
  }),
  {
    cancelForm: FormNewOpportunityNoStore.actionHideForm,
    submitForm: FormNewOpportunityNoStore.actionSubmitForm,
  }
)(NewOpportunityNoModalComponent);
