import styled from 'styled-components';
import { DropDownList as KendoDropDownList } from '@progress/kendo-react-dropdowns';

import { PALETTE } from '../../themes/constants';

export const DropDownListWrapper = styled.div`
  ${props =>
    props.disabled === true &&
    `
    background-color: ${PALETTE.grays.gray3} !important;
  `};
`;
export const KendoDropDownListWrapper = styled(KendoDropDownList)`
  width: 100%;
  ${props =>
    props.valid === false &&
    `
    border-bottom: 0.0625rem solid #c62a00 !important;
  `};
  & > span.k-dropdown-wrap {
    border-bottom-width: 0.625rem;
  }
  & > span.k-state-focused {
    box-shadow: none;
    outline: none;
  }
`;
