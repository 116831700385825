import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { WorkFeedFailWhale } from '../components/WorkFeedFailWhale/WorkFeedFailWhale';
import { PageLayout } from '../layouts/PageLayout';

export const WorkFeedFailWhalePage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks>
      <WorkFeedFailWhale />
    </PageLayout>
  </TalentDataProvider>
);
