import styled from 'styled-components';
import { Label as RSLabel, Input as RSInput } from 'reactstrap';

export const StyledSvgContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  padding-right: 1.25rem;
`;

export const RadioFFLabel = styled(RSLabel)`
  margin-bottom: initial !important;
  vertical-align: middle;
  cursor: pointer;
  font-size: 1rem;
  display: inline-block;
`;

export const RSInputHiddenWrapper = styled(RSInput)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;
