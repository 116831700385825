import styled from 'styled-components';

export const FooterBlock = styled.div`
  a {
    color: ${({ theme }) => theme.colors.textReversed};
  }
  padding: 24px 0;
`;

export const FooterSocialMedia = styled.span`
  color: ${({ theme }) => theme.colors.textReversed};
  background-color: #56688d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
  padding-left: 8px;
  padding-top: 0;
  text-align: left;
  width: 30px;
  height: 30px;
  svg {
    width: 15px;
    height: 15px;
  }
  svg,
  svg * {
    pointer-events: all;
  }
`;
