import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { arrayOf, node, string } from 'prop-types';
import { CookieUtil, RolesUtil } from '@axiom/ui';

import StoreStateTreatment from '../components/StoreStateTreatment/StoreStateTreatment';
import { UserStore } from '../stores/protected/user-store';
import { WindowUtil } from '../utils/window-util';
import { LoadingCurtain } from '../components/LoadingCurtain/LoadingCurtain';
import { UrlVariableParamValidation } from '../utils/url-variable-param-validation';

const AuthRouteComponent = ({ userState, children, roles }) => {
  const params = useParams();

  useEffect(() => {
    if (CookieUtil.isUserExpired()) {
      WindowUtil.toLogin();
    } else {
      UserStore.load();
    }
  }, []);

  if (UrlVariableParamValidation(params).invalid) {
    return <Navigate to="/404" replace />;
  }

  return (
    <StoreStateTreatment
      name="AUTHROUTE"
      initialLoader={<LoadingCurtain />}
      storeState={userState}
      renderLoadedView={({ data: user }) => {
        return RolesUtil.isAllowed(user, roles) ? (
          children
        ) : (
          <Navigate to="/401" replace />
        );
      }}
    />
  );
};

AuthRouteComponent.propTypes = {
  children: node.isRequired,
  userState: UserStore.getStateShape().isRequired,
  roles: arrayOf(string),
};

AuthRouteComponent.defaultProps = {
  roles: null,
};

export const AuthRoute = connect(state => ({
  userState: UserStore.select(state),
}))(AuthRouteComponent);
