import React from 'react';
import { Button, Gutter, Pill, Pills } from '@axiom/ui';
import { CandidateAlertWorkFeed } from '@axiom/validation';

import { FilterPill } from '../../types/work-feed-types';

import { useWorkFeedAlertsPills } from './useWorkFeedAlertsPills';

export const WorkFeedAlertsPills = ({
  workFeedAlert,
  onClear,
  onClearAll,
}: {
  workFeedAlert: CandidateAlertWorkFeed;
  onClear?: (pill: FilterPill) => void;
  onClearAll?: () => void;
}) => {
  const pills = useWorkFeedAlertsPills(workFeedAlert);

  return (
    <div data-test="WORK_FEED_ALERTS_PILLS_WRAPPER">
      {pills.length > 0 && (
        <>
          <Pills name="WORK_FEED_ALERTS_PILLS">
            {pills.map(pill => (
              <Pill
                name={pill.value}
                data-value={pill.value}
                key={pill.value}
                pattern="secondary"
                onDismiss={onClear ? () => onClear(pill) : undefined}
              >
                {pill.label}
              </Pill>
            ))}
          </Pills>
          {onClearAll && (
            <Button
              name="CLEAR_ALL"
              onClick={onClearAll}
              variation="minimal"
              pattern="secondary"
            >
              Clear All
            </Button>
          )}
          <Gutter bottom="8px" />
        </>
      )}
    </div>
  );
};
