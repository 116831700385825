import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Gutter,
  Card,
  CardSection,
  CardFooter,
  SmallHeader,
  Paragraph,
  EmptyLayout,
  useApi,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { EnvUtil } from '../../utils/env-util';
import { WorkFeedFilterUtil } from '../../utils/work-feed-filter-util';
import EmptyProjectsGraphic from '../../public/svg/empty-project.svg';
import { PracticeAreaApi } from '../../api/protected/practice-areas/practice-areas';

export const WorkFeedEndOfFeed = ({ candidate }: { candidate: Candidate }) => {
  const [, setSearchParams] = useSearchParams();
  const [{ data: practiceAreas }] = useApi(PracticeAreaApi.readPracticeAreas());
  const { calculatedFirstName, calculatedLastName } = candidate;
  const to = EnvUtil.endOfOpportunityEmail;
  const subject = `${calculatedFirstName} ${calculatedLastName} - General Interest`;
  const body = encodeURI(
    `Hello Axiom,\nPlease consider me for future opportunities relevant to my experience.\nThank you,\n${calculatedFirstName}\nOppID - 'TBD'\n`
  );

  return (
    <Card name="OPPORTUNITY_END_OF_FEED_CARD">
      <CardSection divider>
        <EmptyLayout
          graphic={<EmptyProjectsGraphic width="114px" height="114px" />}
        >
          <SmallHeader>
            Not finding "the one"? Here are a few things to try:
          </SmallHeader>
          <Gutter bottom="24px" />
          <Paragraph>
            <ul>
              <li>Clear or adjust your search filters.</li>
              <li>Check back later as we add new opportunities every day.</li>
              <li>
                Finally, your talent partner will continue to send personalized
                opportunity suggestions based on your Axiom profile and
                preferences.
              </li>
            </ul>
          </Paragraph>
        </EmptyLayout>
      </CardSection>
      <CardFooter stackableOn="mobile">
        <Button
          variation="outline"
          name="END_OF_FEED_CARD_CLEAR_FILTERS"
          onClick={() => {
            WorkFeedFilterUtil.applyFilters(
              setSearchParams,
              candidate,
              practiceAreas,
              null
            );
          }}
        >
          Clear Filters
        </Button>
        <Button
          name="END_OF_FEED_CARD_REACH_OUT"
          to={`mailto:${to}?subject=${subject}&body=${body}`}
        >
          Reach Out
        </Button>
      </CardFooter>
    </Card>
  );
};
