import React from 'react';
import {
  PageHeader,
  Paragraph,
  ParagraphHeader,
  useApi,
  Card,
  CardSection,
  Layout,
  LayoutItem,
  Flashy,
  useBreakpoint,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { WorkFeedApi } from '../../api/protected/candidates/candidate-workfeed';

export const WorkFeedSPVFooter = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [{ data: statistics }] = useApi(
    WorkFeedApi.readWorkFeedStatistics(candidateId)
  );

  const { isMobile } = useBreakpoint();

  return (
    <Card name="WORK_FEED_SPV_FOOTER">
      <CardSection divider>
        <Layout position="center" direction="vertical" verticalGutter="36px">
          <LayoutItem position="center">
            <PageHeader>A Trusted Partner</PageHeader>
            <Paragraph>20 years of proven experience</Paragraph>
          </LayoutItem>
          <LayoutItem>
            <Layout
              stackableOn="mobile"
              position="center"
              verticalGutter="42px"
            >
              <LayoutItem
                position="center"
                borderRight={!isMobile && '1px solid black'}
                fluid
              >
                <PageHeader>
                  <Flashy name="TOTAL_ACTIVE_OPPORTUNITIES" color="textBrand">
                    {statistics.roundedTotal}+
                  </Flashy>
                </PageHeader>
                <ParagraphHeader>Active Opportunities</ParagraphHeader>
              </LayoutItem>

              <LayoutItem
                position="center"
                borderRight={!isMobile && '1px solid black'}
                fluid
              >
                <PageHeader>
                  <Flashy color="textBrand">20+</Flashy>
                </PageHeader>

                <ParagraphHeader>Added every week</ParagraphHeader>
              </LayoutItem>
              <LayoutItem position="center" fluid>
                <PageHeader>
                  <Flashy color="textBrand">50%</Flashy>
                </PageHeader>

                <ParagraphHeader>
                  Of F100 companies <br />
                  are clients
                </ParagraphHeader>
              </LayoutItem>
            </Layout>
          </LayoutItem>
        </Layout>
      </CardSection>
    </Card>
  );
};
