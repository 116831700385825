import React from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  SmallHeader,
  useBreakpoint,
} from '@axiom/ui';
import { Candidate, CandidateWorkFeedFormFilter } from '@axiom/validation';

import { WorkFeedFiltersForm } from '../WorkFeedFiltersForm/WorkFeedFiltersForm';

export const WorkFeedFilters = ({
  candidate,
  filters,
}: {
  candidate: Candidate;
  filters: CandidateWorkFeedFormFilter;
}) => {
  const { isSmallScreen } = useBreakpoint();
  if (isSmallScreen) {
    return null;
  }
  return (
    <Card name="WORK_FEED_FILTERS">
      <CardHeader>
        <SmallHeader>Filters</SmallHeader>
      </CardHeader>
      <CardSection>
        <WorkFeedFiltersForm candidate={candidate} filters={filters} minimal />
      </CardSection>
    </Card>
  );
};
