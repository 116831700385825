import isUuid from 'is-uuid';
import {
  CandidateImageConst,
  CandidatesConst,
  ImageSizeKeysTypes,
} from '@axiom/const';
import { Candidate, User } from '@axiom/validation';

import { EnvUtil } from './env-util';

const { ProfileStatuses } = CandidatesConst;

const { IMAGE_RESIZE_DIRECTORY, ImageSizesByWidth, PROFILE_IMAGE_DIRECTORY } =
  CandidateImageConst;

type CandidateRecordLegacy = {
  record: Candidate;
};

type CandidateType = CandidateRecordLegacy | Candidate;

export const profileStatusesToShowAxiomAnswers = [
  ProfileStatuses.Alum,
  ProfileStatuses.AlumDNR,
  ProfileStatuses.Certifying,
  ProfileStatuses.NewLead,
  ProfileStatuses.Rejected,
  ProfileStatuses.Reservoir,
];

export const CandidateUtil = {
  getCandidateData(data: CandidateType) {
    return 'record' in data ? data.record : data;
  },

  isParaLegal(inputCandidate: CandidateType) {
    const candidate = this.getCandidateData(inputCandidate);

    return candidate?.occupationType === 'Legal Support';
  },

  reconcileUserCandidateId(user: User, candidateIdFromUrl: Candidate['id']) {
    return user.roles.some(r => r.startsWith('Envoy')) &&
      isUuid.anyNonNil(candidateIdFromUrl)
      ? candidateIdFromUrl
      : user.candidateId;
  },

  getProfileImageUri(candidate: CandidateType, size: ImageSizeKeysTypes) {
    const { profileImageKey } = this.getCandidateData(candidate);

    if (
      !profileImageKey ||
      !ImageSizesByWidth[size] ||
      !EnvUtil.publicS3Bucket
    ) {
      return null;
    }

    const [idSlug] = profileImageKey
      .replace(`${PROFILE_IMAGE_DIRECTORY}/`, '')
      .split('/');
    const { width, height } = ImageSizesByWidth[size];

    return `${EnvUtil.publicBaseUrlS3}/${EnvUtil.publicS3Bucket}/${IMAGE_RESIZE_DIRECTORY}/${idSlug}/${width}x${height}`;
  },

  getTalentPartnerUser(candidate: Candidate): User {
    const { ownerUser, profileStatus } = candidate;

    const axiomAnswers = {
      email: 'answers@axiomlaw.com',
      firstName: 'Axiom',
      lastName: 'Answers',
    };

    return profileStatusesToShowAxiomAnswers.includes(profileStatus)
      ? axiomAnswers
      : (ownerUser ?? axiomAnswers);
  },
};
