import styled from 'styled-components';
import { CheckboxIcon as UICheckboxIcon } from '@axiom/ui';

import { FormElementError } from '../FormElementError/FormElementError';
import { PALETTE } from '../../themes/constants';

export const CheckboxContainer = styled.div`
  align-items: ${({ verticalAlign }) => {
    switch (verticalAlign) {
      case 'bottom': {
        return 'flex-bottom';
      }
      case 'middle': {
        return 'center';
      }
      default: {
        return 'flex-top';
      }
    }
  }};
  ${props => !props.$disabled && 'cursor: pointer;'}
  display: flex;
  font-size: 1rem;
  margin-bottom: initial !important;
  vertical-align: middle;

  // TEMP BOOTSTRAP YANK CARRYOVER
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

  .form-check-input {
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  input[type='checkbox'],
  input[type='radio'] {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }
`;

export const CheckboxLabel = styled.label`
  margin-top: 0.1rem;

  label {
    color: ${props => (props.$disabled ? PALETTE.grays.gray3 : 'inherit')};
    font-size: 0.875rem;
    font-weight: ${props => (props.$bold ? 'bold' : 'normal')};
  }

  // override bootstrap
  font-size: initial;
  margin-bottom: 0;
`;

export const CheckboxSubtext = styled.div`
  font-size: 0.875rem;
`;

export const CheckboxError = styled(FormElementError)`
  display: block;
  margin-top: 0;
`;

export const CheckboxIcon = styled(UICheckboxIcon)`
  cursor: pointer;
  display: inline-block;
  margin-right: 0.625rem;
`;

export const CheckboxIconWrapper = styled.div`
  &:focus-within {
    ${CheckboxIcon} {
      outline: 3px solid ${PALETTE.colors.gold};
    }
  }
`;
