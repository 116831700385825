import { WeeklyTimeUnits, PositionsConst } from '@axiom/const';

const monthGroups = {
  ZERO: '0-3 months',
  FOUR: '4-6 months',
  SEVEN: '7-9 months',
  TEN: '10-12 months',
  OVER_TWELVE: '12+ months',
};

const hoursPerWeekGroups = {
  ONE: '1-5 hours',
  SIX: '6-10 hours',
  ELEVEN: '11-15 hours',
  SIXTEEN: '16-20 hours',
  TWENTY_ONE: '21-25 hours',
  TWENTY_SIX: '26-30 hours',
  THIRTY_ONE: '31-35 hours',
  THIRTY_SIX: '36-40 hours',
  OVER_FORTY: '40+ hours',
};

export const PositionUtil = {
  getRole: position => {
    return position?.role || '';
  },

  getWeeklyTimeCommitment: (candidate, position) => {
    candidate = candidate.record || candidate;
    const maxHours = candidate.maxHours || 40;
    const { billingUnitsPerWeek, weeklyTimeUnit } = position || {};

    if (!weeklyTimeUnit) {
      return null;
    }

    if (
      weeklyTimeUnit === WeeklyTimeUnits.Hourly &&
      billingUnitsPerWeek >= maxHours
    ) {
      return 'Full-time';
    }

    if (weeklyTimeUnit === WeeklyTimeUnits.Daily && billingUnitsPerWeek >= 5) {
      return 'Full-time';
    }

    return 'Part-time';
  },

  getHoursPerWeek: (position = {}) => {
    if (!position?.billingHoursPerWeek) {
      return null;
    }

    const { billingHoursPerWeek } = position;

    if (billingHoursPerWeek <= 5) {
      return hoursPerWeekGroups.ONE;
    }
    if (billingHoursPerWeek <= 10) {
      return hoursPerWeekGroups.SIX;
    }
    if (billingHoursPerWeek <= 15) {
      return hoursPerWeekGroups.ELEVEN;
    }
    if (billingHoursPerWeek <= 20) {
      return hoursPerWeekGroups.SIXTEEN;
    }
    if (billingHoursPerWeek <= 25) {
      return hoursPerWeekGroups.TWENTY_ONE;
    }
    if (billingHoursPerWeek <= 30) {
      return hoursPerWeekGroups.TWENTY_SIX;
    }
    if (billingHoursPerWeek <= 35) {
      return hoursPerWeekGroups.THIRTY_ONE;
    }
    if (billingHoursPerWeek <= 40) {
      return hoursPerWeekGroups.THIRTY_SIX;
    }

    return hoursPerWeekGroups.OVER_FORTY;
  },

  getEngagementLength: (position = {}) => {
    if (
      !position?.estimatedEngagementTotalMonths &&
      position?.estimatedEngagementTotalMonths !== 0
    ) {
      return null;
    }

    const { estimatedEngagementTotalMonths } = position;

    if (estimatedEngagementTotalMonths <= 3) {
      return monthGroups.ZERO;
    } else if (estimatedEngagementTotalMonths <= 6) {
      return monthGroups.FOUR;
    } else if (estimatedEngagementTotalMonths <= 9) {
      return monthGroups.SEVEN;
    } else if (estimatedEngagementTotalMonths <= 12) {
      return monthGroups.TEN;
    }

    return monthGroups.OVER_TWELVE;
  },

  getWorksite: position => {
    if (position?.worksite) {
      if (position.worksite === PositionsConst.Worksites.OnsiteRemote) {
        return 'Hybrid';
      }
      return position.worksite;
    }
    return null;
  },
};
