import moment from 'moment';
import { DateUtil } from '@axiom/ui';

export const PreferencesUtil = {
  shouldPreferenceNeedReview: (reviewedAt: string) => {
    if (!reviewedAt) {
      return true;
    }

    return DateUtil.isDateBeforeDate(
      reviewedAt,
      moment().subtract(6, 'months').toDate()
    );
  },
};
