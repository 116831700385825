import { shape, string } from 'prop-types';

export const CandidateLanguageShape = shape({
  id: string,
  languageCEFR: string,
  languageProficiency: string,
  languageSkill: string,
  name: string,
});

export const LanguageShape = shape({
  id: string,
  name: string,
});
