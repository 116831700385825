import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { EnvUtil } from '../../utils/env-util';

export const RedirectToEnvoyBios = ({
  isAnonymous = false,
}: {
  isAnonymous?: boolean;
}): null => {
  const { candidateId } = useParams();
  useEffect(() => {
    if (isAnonymous) {
      window.location.assign(
        `${EnvUtil.envoyUrl}/talent/${candidateId}/bio/anonymous`
      );
    } else {
      window.location.assign(`${EnvUtil.envoyUrl}/talent/${candidateId}/bio`);
    }
  }, []);
  return null;
};
