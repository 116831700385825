import { shape, string } from 'prop-types';

import FormStateHelper from '../../lib/form-state-helper';
import {
  putProfileImage,
  deleteProfileImage,
} from '../../api/protected/candidates/candidate-profile-image';
import { AjvUtil } from '../../utils/ajv-util';
import { AppStore } from '../app-store';

import { CurrentCandidateStore } from './current-candidate-store';

export const FORM_PROFILE_NAMESPACE = 'FORM_PROFILE';

export const FORM_PROFILE_NAMES = {
  GENERAL: 'GENERAL',
};

const FormProfileValidation = AjvUtil.compileSchema({
  properties: {
    profileImageUri: {
      type: 'string',
    },
  },
});

const formProfileGeneralState = new FormStateHelper(
  FORM_PROFILE_NAMESPACE,
  FORM_PROFILE_NAMES.GENERAL,
  FormProfileValidation
);
export const AlertInvalidProfileImage = () =>
  AppStore.actionShowErrorDialog(
    'Invalid Upload',
    'The file you can select must be in jpeg, png, or gif format and less than 5mb in size'
  );

export const AlertInvalidProfileForm = () =>
  AppStore.actionShowErrorDialog(
    'Invalid Fields',
    'There was an issue with your submission. Please try again.'
  );

export const FormProfileStore = {
  getReducers() {
    return {
      ...formProfileGeneralState.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...formProfileGeneralState.getStructure(FormProfileStore.getDataShape()),
    });
  },
  getDataShape() {
    return shape({
      profileImageUri: string,
    });
  },
  validate(formData) {
    return formProfileGeneralState.validateForm(formData);
  },
  actionShowGeneralForm({ profileImageUri }) {
    return formProfileGeneralState.showForm({ profileImageUri });
  },
  actionHideGeneralForm() {
    return formProfileGeneralState.hideForm();
  },
  actionSetGeneralFormData(props) {
    return formProfileGeneralState.setFormData({ ...props });
  },
  actionSubmitGeneralForm() {
    return (dispatch, getState) => {
      const state = getState();
      const { candidateId } = CurrentCandidateStore.selectCandidate(state);
      const { profileImageUri } = FormProfileStore.selectGeneralFormData(state);

      dispatch(
        formProfileGeneralState.submitForm(
          putProfileImage(candidateId, profileImageUri)
            .then(() => {
              dispatch(CurrentCandidateStore.actionFetchCandidate(candidateId));
            })
            .catch(errors => {
              dispatch(AlertInvalidProfileForm());
              throw errors;
            })
        )
      );
    };
  },
  actionDeleteGeneralFormData() {
    return (dispatch, getState) => {
      const state = getState();
      const { candidateId } = CurrentCandidateStore.selectCandidate(state);

      dispatch(
        formProfileGeneralState.submitForm(
          deleteProfileImage(candidateId)
            .then(() => {
              dispatch(CurrentCandidateStore.actionFetchCandidate(candidateId));
            })
            .catch(errors => {
              dispatch(AlertInvalidProfileForm());
              throw errors;
            })
        )
      );
    };
  },
  selectGeneralFormState(state) {
    return formProfileGeneralState.select(state);
  },
  selectGeneralFormData(state) {
    return formProfileGeneralState.selectData(state);
  },
};
