import React, { useState } from 'react';
import {
  Button,
  EmptyLayout,
  CardSection,
  Card,
  Visible,
  SmallHeader,
  Paragraph,
  CardFooter,
  Gutter,
  CondensedMedium,
} from '@axiom/ui';

import EmptyProjectsGraphic from '../../public/svg/empty-project.svg';

export const WorkFeedFailWhale = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <Card>
      <CardSection divider>
        <EmptyLayout
          name="OPPORTUNITY_FEED_FAIL_WHALE"
          graphic={
            <Visible only="largeScreen">
              <EmptyProjectsGraphic width="114px" height="114px" />
            </Visible>
          }
          graphicPosition="left"
        >
          <SmallHeader>Opportunities are on the way</SmallHeader>
          <Gutter bottom="16px" />
          <Paragraph>
            Opportunity Feed is where eligible Axiom talent can access, review,
            and express interest in any of Axiom's hundreds of open
            opportunities.
            <br />
            <br />
            Who's eligible? You'll see opportunities here if you are looking for
            your next Axiom engagement.
            <br />
            <br />
            If you're waiting for your very first Axiom engagement you won't see
            opportunities here just yet. Please continue working directly with
            your Axiom talent partner.
          </Paragraph>
          <br />
          <CondensedMedium>
            * Opportunity Feed is not yet available for Axiom talent who are
            located in Australia.
          </CondensedMedium>
        </EmptyLayout>
      </CardSection>
      <CardFooter>
        <Button
          disabled={isDisabled}
          onClick={() => setIsDisabled(true)}
          name="WORK_FEED_KEEP_ME_POSTED"
        >
          Keep me posted
        </Button>
      </CardFooter>
    </Card>
  );
};
