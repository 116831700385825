import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { WorkFeedSPV } from '../components/WorkFeedSPV/WorkFeedSPV';
import { PageLayout } from '../layouts/PageLayout';

export const SingleWorkFeedItemPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks>
      <WorkFeedSPV />
    </PageLayout>
  </TalentDataProvider>
);

SingleWorkFeedItemPage.propTypes = {};
