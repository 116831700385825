import { Api } from '@axiom/ui';

import { EnvUtil } from '../utils/env-util';

class SaplingApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  createToken() {
    return super.write<{ token: string }>({
      endpoint: '/sapling/token',
      method: 'POST',
    });
  }
}

export const SaplingApi = new SaplingApiClass();
