import React from 'react';
import {
  CandidateOpportunitiesConst,
  CandidateStatusType,
  EventsConst,
} from '@axiom/const';
import {
  Banner,
  Brief,
  CompanyLogo,
  CondensedHeader,
  CondensedLarge,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  ProgressStep,
  ProgressSteps,
  ReadonlyTextarea,
  SmallHeader,
} from '@axiom/ui';
import {
  Candidate,
  OpportunityCandidate,
  PracticeArea,
} from '@axiom/validation';

import { OpportunityUtil } from '../../utils/opportunity-util';
import { DateUtil } from '../../utils/date-util';
import { LocationUtil } from '../../utils/location-util';
import { EnvUtil } from '../../utils/env-util';

const { CandidateStatuses } = CandidateOpportunitiesConst;

const {
  WarmedYes,
  Submitted,
  Interviewing,
  Selected,
  Engaged,
  Rejected,
  TalentOptOut,
} = CandidateStatuses;

const getProgressActiveStatus = (opportunity: OpportunityCandidate) =>
  [Rejected].includes(opportunity.candidateStatus)
    ? opportunity.previousCandidateStatus
    : opportunity.candidateStatus;

const getInterviewedOn = (opportunity: OpportunityCandidate) => {
  if (!opportunity.events) return null;

  const event = opportunity.events
    .filter(
      oppEvent =>
        oppEvent.type === EventsConst.Types.Interview &&
        oppEvent.purpose === EventsConst.Purposes.OpportunityInterview
    )
    .sort((a, b) =>
      new Date(a.scheduledDate) < new Date(b.scheduledDate) ? -1 : 1
    )
    .shift();

  return event ? event.scheduledDate : null;
};

const displayUpdateDate = (opportunity: OpportunityCandidate) => {
  if (opportunity.candidateStatus === Submitted) {
    return {
      label: 'Submitted On',
      value: DateUtil.displayShortDate(opportunity.updatedAt),
    };
  }

  if (opportunity.candidateStatus === Interviewing) {
    return {
      label: 'Interview Date',
      value: DateUtil.displayShortDate(getInterviewedOn(opportunity)),
    };
  }
  return {
    label: 'Last Updated',
    value: DateUtil.displayShortDate(opportunity.candidateStatusUpdatedAt),
  };
};

export const ActiveSubmissionItem = ({
  candidate,
  opportunity,
  practiceAreas,
}: {
  candidate: Candidate;
  opportunity: OpportunityCandidate;
  practiceAreas: { [k: PracticeArea['id']]: PracticeArea };
}) => {
  const contactDetails = OpportunityUtil.getContact(opportunity);

  const checkStatusForShareProfile = ({
    previousPositiveCandidateStatus,
    candidateStatus,
  }: OpportunityCandidate) => {
    type StatusMapType = Partial<Record<CandidateStatusType, boolean>>;

    const validPreviousStatuses: StatusMapType = {
      [Submitted]: true,
      [Interviewing]: true,
      [Selected]: true,
    };
    const isValid: boolean =
      validPreviousStatuses[
        previousPositiveCandidateStatus as CandidateStatusType
      ];

    const validityMap: StatusMapType = {
      [Submitted]: true,
      [Interviewing]: true,
      [Selected]: true,
      [Rejected]: isValid,
      [TalentOptOut]: isValid,
    };

    return validityMap[candidateStatus as CandidateStatusType];
  };

  return (
    <div data-test="ACTIVE_SUBMISSION_ITEM">
      {opportunity.candidateStatusMessage && (
        <Gutter bottom="32px">
          <Banner type="info">
            <Paragraph name="CANDIDATE_STATUS_MESSAGE">
              {opportunity.candidateStatusMessage}
            </Paragraph>
          </Banner>
        </Gutter>
      )}
      {checkStatusForShareProfile(opportunity) && (
        <Gutter bottom="32px" name="SHARE_PROFILE_BANNER">
          <Banner type="critical">
            {contactDetails && (
              <Gutter bottom="24px">
                <Layout horizontalGutter="8px" position="middle" wrap>
                  <LayoutItem>
                    <ParagraphHeader>Engagement Contact</ParagraphHeader>
                  </LayoutItem>
                  <LayoutItem>
                    <Paragraph>
                      <a
                        href={`mailto:${contactDetails.email}`}
                        data-test="CONTACT_DETAIL_LINK"
                      >
                        {contactDetails.fullName}
                      </a>
                    </Paragraph>
                  </LayoutItem>
                </Layout>
              </Gutter>
            )}
            <Paragraph>
              Your Profile has been shared for the client’s consideration. For
              updates, check back here or watch your email.
            </Paragraph>
          </Banner>
        </Gutter>
      )}

      <Layout position="middle" horizontalGutter="16px">
        <LayoutItem>
          <CompanyLogo
            src={`${EnvUtil.clearbitLogoUrl}/${opportunity.account?.website}`}
            alt={opportunity.account?.calculatedName}
            name="COMPANY_LOGO"
          />
        </LayoutItem>
        <LayoutItem fluid>
          <SmallHeader name="JOB_NAME">{opportunity.jobName}</SmallHeader>
          <Gutter bottom="8px" />
          <CondensedLarge name="ACCOUNT_NAME">
            {opportunity.accountName}
          </CondensedLarge>
        </LayoutItem>
      </Layout>
      <Gutter bottom="24px" />
      <ProgressSteps
        completedStep={getProgressActiveStatus(opportunity)}
        icon="checkmark"
        name="PROGRESS_STEPS"
      >
        <ProgressStep stepName={WarmedYes}>Share My Profile</ProgressStep>
        <ProgressStep stepName={Submitted}>Profile Shared</ProgressStep>
        <ProgressStep stepName={Interviewing}>Interviewing</ProgressStep>
        <ProgressStep stepName={Selected}>Selected</ProgressStep>
        <ProgressStep stepName={Engaged}>Engaged</ProgressStep>
      </ProgressSteps>
      <Gutter bottom="24px" />
      <Grid columns="equal">
        <GridRow gutterBottom="8px">
          <GridColumn largeScreenWidth={3} smallScreenWidth={12}>
            <CondensedHeader name="UPDATE_LABEL">
              {displayUpdateDate(opportunity).label}
            </CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={3} smallScreenWidth={12}>
            <CondensedLarge name="UPDATE_DATE">
              {displayUpdateDate(opportunity).value || '--'}
            </CondensedLarge>
            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth={2} smallScreenWidth={12}>
            <CondensedHeader>Practice Area</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
            <CondensedLarge name="PRACTICE_AREA">
              {practiceAreas[opportunity.practiceAreaId]?.name || '--'}
            </CondensedLarge>
            <Gutter bottom="8px" only="smallScreen" />
          </GridColumn>
        </GridRow>

        <GridRow gutterBottom="8px">
          <GridColumn largeScreenWidth={3} smallScreenWidth={12}>
            <CondensedHeader>Engagement Length</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={3} smallScreenWidth={12}>
            <CondensedLarge name="ESTIMATED_ENGAGEMENT_TOTAL_MONTHS">
              {opportunity.position?.estimatedEngagementTotalMonths
                ? `~${opportunity.position?.estimatedEngagementTotalMonths} months`
                : '--'}
            </CondensedLarge>

            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth={2} smallScreenWidth={12}>
            <CondensedHeader>Location</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
            <CondensedLarge name="LOCATION">
              {LocationUtil.displayFormattedJobLocation(
                opportunity,
                opportunity.position
              )}
            </CondensedLarge>
            <Gutter bottom="8px" only="smallScreen" />
          </GridColumn>
        </GridRow>

        <GridRow>
          <GridColumn largeScreenWidth={3} smallScreenWidth={12}>
            <CondensedHeader>Weekly Commitment</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={3} smallScreenWidth={12}>
            <CondensedLarge name="WEEKLY_TIME_COMMITMENT">
              {OpportunityUtil.getWeeklyTimeCommitment(
                candidate,
                opportunity
              ) || '--'}
            </CondensedLarge>

            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth={2} smallScreenWidth={12}>
            <CondensedHeader>Role</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
            <CondensedLarge name="ROLE">
              {OpportunityUtil.getRole(opportunity)}
            </CondensedLarge>
          </GridColumn>
        </GridRow>
      </Grid>

      <Gutter bottom="24px" />

      <CondensedHeader>Work Description</CondensedHeader>
      <Gutter bottom="8px" />
      <Brief briefMaxHeight="200px" pattern="secondary">
        <ReadonlyTextarea
          name="WORK_DESCRIPTION"
          value={opportunity.position?.description || '--'}
        />
      </Brief>
    </div>
  );
};
