import styled from 'styled-components';

import { PALETTE } from '../../themes/constants';
import { blockingAppCurtainZIndex } from '../../themes/stacking-context';

export const ToastWrapper = styled.div`
  position: fixed;
  top: 5px;
  left: 50%;
  width: 600px;
  margin-left: -300px;
  text-align: center;
  z-index: ${blockingAppCurtainZIndex};
`;

export const ToastBody = styled.div`
  min-width: 100px;
  max-height: 300px;
  overflow: hidden;
  display: inline-block;
  background-color: ${PALETTE.theme.danger};
  color: whitesmoke;
  font-weight: bold;
  padding: 8px;
  border-radius: 10px;
`;
