import React from 'react';
import styled from 'styled-components';
import { oneOfType, node, number, string } from 'prop-types';

export const StyledLabel = styled.label`
  font-size: 0.875rem;
  font-weight: normal;

  display: inline-block;
  margin-bottom: 0;
`;

export const Label = ({ append, htmlFor, id, prepend, children }) => (
  <StyledLabel htmlFor={htmlFor} id={id}>
    {prepend}
    {prepend && ' '}
    {children}
    {append && ' '}
    {append}
  </StyledLabel>
);

Label.propTypes = {
  append: oneOfType([string, number, node]),
  children: oneOfType([string, number, node]),
  htmlFor: string,
  id: string,
  prepend: oneOfType([string, number, node]),
};

Label.defaultProps = {
  append: null,
  children: null,
  htmlFor: null,
  id: null,
  prepend: null,
};
