import styled from 'styled-components';

export const PrintStylesWrapper = styled.section`
  @media print {
    .hide-print {
      display: none;
    }
    div {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;

      .panel {
        border-left: 1px solid grey !important;
        border-right: 1px solid grey !important;
        border-bottom: 1px solid grey !important;

        .panel {
          margin-bottom: 40px !important;
        }
      }

      .col-md-3 {
        display: none;
      }

      .col-md-9 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .collapse {
        display: block;
      }
    }

    button {
      display: none;
    }
  }
`;

export const PositionRelative = styled.div`
  position: relative;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const FlexLeftWrapper = styled.div`
  flex: 1 0 auto;
  display: inline-flex;
  flex-direction: column;
`;

export const AlignCenter = styled.div`
  text-align: center;
`;
