import { arrayOf, shape } from 'prop-types';
import { orderBy } from 'lodash';
import { Opportunity, CandidateOpportunitiesConst } from '@axiom/const';

import GenericStore from '../../classes/generic-store';
import {
  getOpportunitiesByCandidateId,
  updateOpportunityByCandidate,
} from '../../api/protected/candidates/candidate-opportunities-legacy';
import { CandidateOpportunitiesApi } from '../../api/protected/candidates/candidate-opportunities';

const {
  CandidateStatuses: {
    Completed,
    Cooled,
    Engaged,
    Interviewing,
    Rejected,
    Removed,
    Selected,
    Submitted,
    TalentOptOut,
    Warmed,
    WarmedUnsure,
    WarmedYes,
  },
} = CandidateOpportunitiesConst;

const generateCoolingMessage = ({
  stage,
  candidateStatus,
  candidateStatusMessage,
  isFalseStart,
}) => {
  /**
   * Candidates on false start go from Engaged => Cooled, in which case we want to still
   * show a message for them to be able to move opps from Active to Historical
   */
  if (candidateStatus !== Cooled && isFalseStart) {
    return null;
  }

  if (candidateStatusMessage) {
    return candidateStatusMessage;
  }

  switch (candidateStatus) {
    case Warmed: {
      return "We think you'd be a great fit for an opportunity we're working on. Please review the information below and indicate if you'd like us to share your profile with the client.";
    }
    case WarmedUnsure: {
      return "We will be in touch shortly to discuss this engagement. Following our discussion, please be sure to indicate whether or not you'd like to proceed by selecting the appropriate response below.";
    }
    case TalentOptOut: {
      return "You've let us know you aren't interested in this opportunity.";
    }
    case Cooled: {
      if (stage === Opportunity.CLOSED_LOST) {
        return "Thanks for your interest in this opportunity. Unfortunately, the client has expressed they no longer have a need for Axiom's support. We will reach out to you should anything change.";
      }
      if (stage !== Opportunity.CLOSED_LOST) {
        return 'Thank you for your interest in this opportunity. While your skills and accomplishments are impressive, the client has decided to go in a different direction for this role. We appreciate the time you spent helping us prepare your submission and will be sure to keep you in mind for future opportunities.';
      }
      return null;
    }
    default: {
      return null;
    }
  }
};

const mutateOpportunity = opportunity => ({
  ...opportunity,
  candidateStatusMessage: generateCoolingMessage(opportunity),
});

class CandidateOpportunitiesStoreClass extends GenericStore {
  load(candidateId) {
    CandidateOpportunitiesApi.refreshCandidateEngagementActionsCount(
      candidateId
    );
    return this.setState(
      getOpportunitiesByCandidateId(candidateId).then(opps =>
        opps.map(opp => mutateOpportunity(opp))
      )
    );
  }

  getDataShape() {
    return arrayOf(shape({}));
  }

  getActiveStatuses() {
    return [WarmedYes, Submitted, Interviewing, Selected, Rejected];
  }

  selectOpportunities(state) {
    return orderBy(this.select(state)?.data || [], ['updatedAt'], 'desc');
  }

  selectNewOpportunities(state) {
    const newStatuses = new Set([Warmed, WarmedUnsure]);
    const newOpps = (this.select(state)?.data || [])
      .filter(op => newStatuses.has(op.candidateStatus))
      .filter(op => op.hasBeenWarmed);

    return orderBy(newOpps, ['updatedAt'], 'desc');
  }

  selectActiveOpportunities(state) {
    const statusesDependingOnWhenChanged = [Cooled];
    const activeStatuses = new Set([
      ...this.getActiveStatuses(),
      ...statusesDependingOnWhenChanged,
    ]);
    return (this.select(state)?.data || [])
      .filter(op => activeStatuses.has(op.candidateStatus))
      .filter(op => op.hasBeenWarmed)
      .filter(op => !op.candidateStatusMessageDismissedAt)
      .sort((a, b) => (new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1));
  }

  selectCurrentOpportunities(state) {
    const currentStatuses = new Set([Engaged]);
    const currentOpps = (this.select(state)?.data || []).filter(op =>
      currentStatuses.has(op.candidateStatus)
    );
    return orderBy(currentOpps, ['updatedAt'], 'desc');
  }

  selectHistoricOpportunities(state) {
    const historyStatuses = new Set([TalentOptOut, Completed, Cooled, Removed]);
    return (this.select(state)?.data || [])
      .filter(op => historyStatuses.has(op.candidateStatus))
      .filter(op => op.hasBeenWarmed)
      .filter(
        op =>
          !(
            op.candidateStatus === Cooled &&
            !op.candidateStatusMessageDismissedAt
          )
      )
      .sort((a, b) => (new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1));
  }

  patchHistorySubmissionItem(candidateId, opportunityId) {
    return this.watchState(
      updateOpportunityByCandidate(candidateId, opportunityId, {
        candidateStatusMessageDismissedAt: new Date(),
      }).then(() => {
        this.load(candidateId);
      })
    );
  }
}

export const CandidateOpportunitiesStore =
  new CandidateOpportunitiesStoreClass();
