import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Form,
} from '@axiom/ui';
import {
  Candidate,
  CandidateWorkFeedHideSchema,
  Opportunity,
} from '@axiom/validation';

import { WorkFeedApi } from '../../api/protected/candidates/candidate-workfeed';
import { CandidateOpportunitiesApi } from '../../api/protected/candidates/candidate-opportunities';

export const WorkFeedHideOpportunityModal = ({
  candidateId,
  onClose,
  opportunityId,
}: {
  candidateId: Candidate['id'];
  onClose: () => void;
  opportunityId: Opportunity['id'];
}) => (
  <Form
    schema={CandidateWorkFeedHideSchema}
    initialValues={{ opportunityId }}
    onSubmit={async () => {
      await CandidateOpportunitiesApi.createWorkFeedHidden(candidateId, {
        opportunityId,
      });
      await WorkFeedApi.refreshAllOfWorkFeed(candidateId);
      return onClose();
    }}
  >
    {({ fireSubmit }) => (
      <Modal name="HIDE_OPPORTUNITY_MODAL" size="confirm">
        <ModalHeader onClose={onClose} name="HIDE_OPPORTUNITY_MODAL_HEADER">
          Hide opportunity
        </ModalHeader>
        <ModalSection>
          This will remove this opportunity from your feed permanently, and will
          enable us to provide you with better recommendations in the future.
        </ModalSection>
        <ModalFooter>
          <Button
            variation="outline"
            name="HIDE_OPPORTUNITY_MODAL_CANCEL"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button name="HIDE_OPPORTUNITY_MODAL_SUBMIT" onClick={fireSubmit}>
            Hide
          </Button>
        </ModalFooter>
      </Modal>
    )}
  </Form>
);
