import { Api } from '@axiom/ui';
import { Candidate, Certification } from '@axiom/validation';

import { EnvUtil } from '../../../utils/env-util';

class CandidateCertificationsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  createCertification(
    candidateId: Candidate['id'],
    certification: Certification
  ) {
    return super.write<{ data: Certification }>({
      endpoint: `/candidates/${candidateId}/certifications`,
      body: certification,
      method: 'POST',
    });
  }

  updateCertification(
    id: Certification['id'],
    certification: Partial<Certification>
  ) {
    return super.write<{ data: Certification }>({
      endpoint: `/certifications/${id}`,
      body: certification,
      method: 'PATCH',
    });
  }

  deleteCertification(id: Certification['id']) {
    return super.write<{ data: Certification }>({
      endpoint: `/certifications/${id}`,
      method: 'DELETE',
    });
  }
}

export const CandidateCertificationsApi = new CandidateCertificationsApiClass();
