import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export interface PillsProps {
  className?: string;
  children: React.ReactNode;
  name?: string;
}

export const Pills = ({ className, children, name }: PillsProps) => {
  return (
    <div
      className={AttrsHelper.formatClassname('pills', className)}
      data-test={name}
    >
      {children}
    </div>
  );
};
