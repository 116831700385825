import { Api } from '@axiom/ui';
import { CandidateMfaPhoneNumber } from '@axiom/validation';

import { EnvUtil } from '../../../utils/env-util';

class UsersApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  updateMfaPhoneNumber(userId: string, body: CandidateMfaPhoneNumber) {
    return super.write<{ data: CandidateMfaPhoneNumber }>({
      endpoint: `/users/${userId}/mfaPhoneNumber`,
      method: 'PUT',
      body,
    });
  }

  createMfaVerification(userId: string, body: { mfaCode: string }) {
    return super.write<{ data: string }>({
      endpoint: `/users/${userId}/mfaVerification`,
      method: 'POST',
      body,
    });
  }
}

export const UsersApi = new UsersApiClass();
