import styled from 'styled-components';

export const APLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const APCard = styled.div`
  width: 33.125rem;
  height: 18.9375rem;
  background-color: #ffffff;
  box-shadow: 0 0 4 0 #000000;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
