import React, { useEffect, useRef, useState } from 'react';
import { number, shape, string, func, exact } from 'prop-types';

import {
  grabbingClass,
  SliderBackgroundBar,
  SliderContainer,
  SliderDot,
  SliderDotContainer,
  SliderForegroundBar,
  SliderLabel,
  SliderLabelsContainer,
} from './FFSliderStyles';

const dotRadius = 12;
const oneHundredthOfDotDiameter = (2 * dotRadius) / 100;
const adjustmentByPercent = Array.from({ length: 101 }).map(
  (_, index) => oneHundredthOfDotDiameter * index
);
const oneTransparentPixel = new Image();
oneTransparentPixel.src =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const FFSlider = ({ minRange, maxRange, input, onValueChange }) => {
  const { value, onChange } = input;
  const { value: minValue, label: minLabel = `${minValue}` } = minRange;
  const { value: maxValue, label: maxLabel = `${maxValue}` } = maxRange;
  const roundAndConstrain = (proposedValue, round) => {
    if (proposedValue < minValue) return minValue;
    if (proposedValue > maxValue) return maxValue;
    return round ? Math.round(proposedValue) : proposedValue;
  };
  const getDotLeft = proposedValue => {
    const percent = Math.round((proposedValue / maxValue) * 100);
    // since we don't want the dot to go past the ends of the slider
    // use precalculated px adjustments
    return `calc(${percent}% - ${adjustmentByPercent[percent]}px)`;
  };

  const dotRef = useRef(null);
  const labelsRef = useRef(null);
  const sliderRef = useRef(null);
  const isMouseDown = useRef(false);
  const [sliderValue, setSliderValue] = useState(value || maxValue);
  const [dotLeftValue, setDotLeftValue] = useState(getDotLeft(sliderValue));
  const [sliderBoundingBox, setSliderBoundingBox] = useState(null);

  const updateSliderBoundingBox = () => {
    setSliderBoundingBox(sliderRef.current?.getBoundingClientRect());
  };
  const onLabelsContainerClick = e => {
    // we need to stopPropagation on the native event here
    // because the synthetic event is too late to stop it
    if (e.target === labelsRef.current) {
      e.stopImmediatePropagation();
    }
  };
  const updateSliderValue = newValue => {
    if (newValue === sliderValue) return;
    setSliderValue(newValue);
    setDotLeftValue(getDotLeft(newValue));
    onValueChange(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    updateSliderValue(roundAndConstrain(value));
  }, [value]);

  // trap the clicks in between the labels to not update the slider
  useEffect(() => {
    if (labelsRef.current) {
      labelsRef.current.addEventListener('click', onLabelsContainerClick);
    }
    return () => {
      if (labelsRef.current) {
        labelsRef.current.removeEventListener('click', onLabelsContainerClick);
      }
    };
  }, [labelsRef.current]);

  // store the slider area's bounding box and update only on resize
  useEffect(() => {
    if (sliderRef.current) {
      updateSliderBoundingBox();
      window.addEventListener('resize', updateSliderBoundingBox);
    }
    return () => {
      if (sliderRef.current) {
        window.removeEventListener('resize', updateSliderBoundingBox);
      }
    };
  }, [sliderRef.current]);

  const calculations = (e, round = true) => {
    const { left, width } = sliderBoundingBox;
    const clickX =
      (e.clientX || e.pageX || e.changedTouches?.[0].clientX || 0) - left;
    /**
     * 'clickX' can be a negative number right after dragDrop
     * do to the event having the above properties with values of either zero or undefined
     * this ends up being 0 - left = -y
     * The below if prevents the negative value from breaking the UI
     * */
    if (clickX < 0) return sliderValue;

    const percent = (clickX / width) * 100;
    const newPercent = (maxValue / 100) * percent;
    return roundAndConstrain(newPercent, round);
  };

  const calculateAndSetSliderValueFromMouseEvent = e => {
    const newValue = calculations(e, true);
    updateSliderValue(newValue);
    sliderRef.current.classList.add(grabbingClass);
  };

  const cleanUpGrabbing = () => {
    sliderRef.current.classList.remove(grabbingClass);
    isMouseDown.current = false;
  };

  const handleMoveStart = e => {
    if (e.currentTarget === dotRef.current) {
      isMouseDown.current = true;
    }
    e.dataTransfer?.setDragImage(oneTransparentPixel, 0, 0);
  };

  const handleDrag = e => {
    if (isMouseDown.current === true) {
      calculateAndSetSliderValueFromMouseEvent(e);
    }
  };

  const handleDragEnd = e => {
    if (e.clientX || e.changedTouches?.[0].clientX) {
      if (e.type === 'touchend') {
        calculateAndSetSliderValueFromMouseEvent(e);
      }
      cleanUpGrabbing();
    }
  };

  const handleSliderClick = e => {
    calculateAndSetSliderValueFromMouseEvent(e);
    cleanUpGrabbing();
  };

  const handleKeyDown = e => {
    const keys = {
      increase: 'ArrowRight',
      decrease: 'ArrowLeft',
    };
    if (![keys.increase, keys.decrease].includes(e.key)) return;
    const newValue = sliderValue + (keys.increase === e.key ? 1 : -1);
    const newSafeValue = roundAndConstrain(newValue, true);
    updateSliderValue(newSafeValue);
  };

  const handleLabelClick = (e, newValue) => {
    e.preventDefault();
    e.stopPropagation();
    updateSliderValue(newValue);
  };

  return (
    <div
      data-test="MIN_MAX_SLIDER_CONTAINER"
      ref={element => {
        // Testing workaround. Shims the state update method onto the dom node.
        // Drag events in puppeteer have a clientX value that's roughly double
        // what it should be. It seems like the final mouse position
        if (element) {
          element.backdoor = element?.backdoor || {
            setValue(newValue) {
              updateSliderValue(newValue);
            },
          };
        }
      }}
    >
      <SliderContainer
        ref={sliderRef}
        dotRadius={dotRadius}
        onDragEnd={handleDragEnd}
        onTouchEnd={handleDragEnd}
        onDragOver={handleDrag}
        onDrag={handleDrag}
        onTouchMove={handleDrag}
        onClick={handleSliderClick}
        data-test="THE_DROP_ZONE"
      >
        <SliderBackgroundBar />
        <SliderForegroundBar width={`${(sliderValue / maxValue) * 100}%`} />
        <SliderLabelsContainer ref={labelsRef}>
          <SliderLabel onClick={e => handleLabelClick(e, minValue)}>
            {minLabel}
          </SliderLabel>
          <SliderLabel onClick={e => handleLabelClick(e, maxValue)}>
            {maxLabel}
          </SliderLabel>
        </SliderLabelsContainer>
        <SliderDotContainer
          ref={dotRef}
          draggable
          tabIndex="0"
          role="slider"
          aria-valuenow={sliderValue}
          aria-valuemin={minValue}
          aria-valuemax={maxValue}
          onKeyDown={handleKeyDown}
          onDragStart={handleMoveStart}
          onTouchStart={handleMoveStart}
          data-test="MAX_SLIDER_DOT"
          left={dotLeftValue}
        >
          <SliderDot />
        </SliderDotContainer>
      </SliderContainer>
    </div>
  );
};

FFSlider.defaultProps = {
  onValueChange: () => {},
};
FFSlider.propTypes = {
  input: shape({ value: number }).isRequired,
  minRange: exact({
    label: string,
    value: number,
  }).isRequired,
  maxRange: exact({
    label: string,
    value: number,
  }).isRequired,
  onValueChange: func,
};
