import React from 'react';
import Resizer from 'react-image-file-resizer';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { Button, ImageCircle, Layout, LayoutItem } from '@axiom/ui';
import { CandidateImageConst } from '@axiom/const';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import {
  FormProfileStore,
  AlertInvalidProfileImage,
} from '../../stores/protected/form-profile-store';
import { CandidateStore } from '../../stores/protected/candidate-store';
import { LoadingCurtain } from '../LoadingCurtain/LoadingCurtain';
import { DeleteProfileImageModalStore } from '../../stores/protected/delete-profile-image-modal-store';
import { PhotoConsentModalStore } from '../../stores/protected/photo-consent-modal-store';
import { CandidateUtil } from '../../utils/candidate-util';
import FormStateHelperTreatment from '../FormStateHelperTreatment/FormStateHelperTreatment';

import { ProfileImageEditDeleteConfirmModal } from './ProfileImageEditDeleteConfirmModal';
import { ProfileImageEditPhotoConsentModal } from './ProfileImageEditPhotoConsentModal';

const ALLOWED_FORMATS = new Set(['image/gif', 'image/png', 'image/jpeg']);
const MAX_FILE_SIZE = 1024 * 1000 * 1; // kb to MB to count of them
const { PossibleImageSizes } = CandidateImageConst;

class ProfileImageEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.uploadRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    const { generalFormState, profile, candidate } = this.props;
    if (generalFormState !== nextProps.generalFormState) {
      return true;
    }

    if (profile !== nextProps.profile) return true;

    if (candidate.profileImageKey !== nextProps.candidate.profileImageKey) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const { generalFormState, profile, submitGeneralForm } = this.props;
    const { isSubmitting } = generalFormState;
    const { profileImageUri } = profile;
    const { profileImageUri: _profileImageUri } = prevProps.profile;
    if (
      !isSubmitting &&
      profileImageUri &&
      profileImageUri !== _profileImageUri
    ) {
      submitGeneralForm();
    }
  }

  handleImageSelect = e => {
    e.preventDefault();

    const { files } = this.uploadRef;
    const { alertInvalidUpload, showGeneralForm } = this.props;

    if (files.length === 0) {
      // user cancelled the file picker, do nothing
      return false;
    }

    const file = files[0];

    if (!ALLOWED_FORMATS.has(file.type)) {
      alertInvalidUpload();
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      // resizing image since nginx can't take larger than 1mb
      // these settings are the same as before so in theory work just fine
      Resizer.imageFileResizer(
        file, // is the file of the new image that can now be uploaded...
        1000, // is the maxWidth of the  new image
        1000, // is the maxHeight of the  new image
        'JPEG', // is the compressFormat of the  new image
        100, // is the quality of the  new image
        0, // is the rotation of the  new image
        profileImageUri => {
          showGeneralForm({ profileImageUri });
        },
        'base64',
        60,
        60
      );
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        showGeneralForm({ profileImageUri: reader.result });
      };
      reader.readAsDataURL(file);
    }

    return false;
  };

  launchImageSelect = () => PhotoConsentModalStore.load({ showModal: true });

  launchDeleteProfileImage = () => {
    return DeleteProfileImageModalStore.load({ showModal: true });
  };

  clearImage = () => {
    const { deleteGeneralForm } = this.props;
    this.uploadRef.value = '';
    deleteGeneralForm();
    DeleteProfileImageModalStore.cancel();
  };

  render() {
    const {
      generalFormState,
      profile,
      candidate: candidateObj,
      deleteProfileImageModalState,
      photoConsentModalState,
    } = this.props;
    const candidate = CandidateUtil.getCandidateData(candidateObj);

    return (
      <>
        <Layout position="middle" horizontalGutter="16px">
          <FormStateHelperTreatment
            formState={generalFormState}
            loadingTreatment={<LoadingCurtain showSpinner />}
            formComponent={
              <ImageCircle
                src={CandidateUtil.getProfileImageUri(
                  profile,
                  PossibleImageSizes.W_160
                )}
                imageName={candidate.calculatedDisplayName}
              />
            }
            displayComponent={
              <ImageCircle
                src={CandidateUtil.getProfileImageUri(
                  candidate,
                  PossibleImageSizes.W_160
                )}
                imageName={candidate.calculatedDisplayName}
              />
            }
          />
          <Layout
            wrap
            position="middle"
            horizontalGutter="16px"
            verticalGutter="16px"
          >
            <LayoutItem>
              <Button pattern="secondary" onClick={this.launchImageSelect}>
                Add Photo
              </Button>

              <input
                type="file"
                id="file"
                ref={ref => {
                  this.uploadRef = ref;
                }}
                accept="image/*"
                onChange={this.handleImageSelect}
                style={{ display: 'none' }}
              />
            </LayoutItem>
            <LayoutItem>
              {candidate.profileImageUrl && (
                <Button
                  pattern="secondary"
                  onClick={this.launchDeleteProfileImage}
                >
                  Delete Photo
                </Button>
              )}
            </LayoutItem>
          </Layout>
        </Layout>
        <StoreStateTreatment
          name="DELETE_PHOTO_MODAL"
          isModalState
          storeState={deleteProfileImageModalState}
          renderLoadedView={() => (
            <ProfileImageEditDeleteConfirmModal
              onDecline={() => DeleteProfileImageModalStore.cancel()}
              onDelete={this.clearImage}
            />
          )}
        />
        <StoreStateTreatment
          name="Photo_Consent_modal"
          isModalState
          storeState={photoConsentModalState}
          renderLoadedView={() => (
            <ProfileImageEditPhotoConsentModal
              onConsent={() => PhotoConsentModalStore.confirm(this.uploadRef)}
              onDecline={() => PhotoConsentModalStore.decline()}
            />
          )}
        />
      </>
    );
  }
}

ProfileImageEditComponent.defaultProps = {};

ProfileImageEditComponent.propTypes = {
  candidate: CandidateStore.getDataShape().isRequired,
  profile: FormProfileStore.getDataShape().isRequired,
  generalFormState: FormProfileStore.getStateShape().isRequired,
  alertInvalidUpload: func.isRequired,
  submitGeneralForm: func.isRequired,
  showGeneralForm: func.isRequired,
  deleteGeneralForm: func.isRequired,
  deleteProfileImageModalState:
    DeleteProfileImageModalStore.getStateShape().isRequired,
  photoConsentModalState: PhotoConsentModalStore.getStateShape().isRequired,
};

export const ProfileImageEdit = connect(
  state => ({
    candidate: CandidateStore.selectData(state),
    profile: FormProfileStore.selectGeneralFormData(state),
    generalFormState: FormProfileStore.selectGeneralFormState(state),
    deleteProfileImageModalState: DeleteProfileImageModalStore.select(state),
    photoConsentModalState: PhotoConsentModalStore.select(state),
  }),
  {
    showGeneralForm: FormProfileStore.actionShowGeneralForm,
    submitGeneralForm: FormProfileStore.actionSubmitGeneralForm,
    deleteGeneralForm: FormProfileStore.actionDeleteGeneralFormData,
    alertInvalidUpload: AlertInvalidProfileImage,
  }
)(ProfileImageEditComponent);
