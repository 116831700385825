export const makeFullName = (...names) => {
  let name = '';

  for (const partialName of names) {
    if (typeof partialName === 'string' && partialName.length > 0) {
      name += `${partialName} `;
    }
  }
  return name.trim();
};

export const truncate = (str, len, mask = '') =>
  typeof str === 'string' && str.length > len
    ? `${str.slice(0, Math.max(0, len - 1))}${mask}`
    : str;

export const capitalizeString = str =>
  typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;
