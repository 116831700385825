import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { IconButton } from '../../element/Button/IconButton';

export const SidedrawerHeader = ({
  className,
  children,
  name,
  onClose,
}: {
  children?: React.ReactNode;
  className?: string;
  name?: string;
  onClose: () => void;
}) => (
  <div
    className={AttrsHelper.formatClassname('sidedrawer-header', className)}
    data-test={name}
  >
    {children}
    <IconButton
      name={`${name}-close-button`}
      pattern="secondary"
      variation="minimal"
      icon="x-mark"
      aria-label={
        typeof children === 'string'
          ? `Close ${children} sidedrawer`
          : 'Close sidedrawer'
      }
      className={`${name}-close-button gtm-${name}-close-modal close-button`}
      onClick={onClose}
      type="button"
    />
  </div>
);
