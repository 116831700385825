import React, { useState, useEffect } from 'react';
import { IconButton, Pagination } from '@axiom/ui';
import { CandidateWorkFeedSearch } from '@axiom/validation';

export const WorkFeedPagination = ({
  meta,
}: {
  meta: CandidateWorkFeedSearch['meta'];
}) => {
  const [pagination, setPagination] = useState({
    currentPage: meta?.currentPage,
    pageCount: meta?.pageCount,
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      pageCount: meta?.pageCount,
      currentPage: meta?.currentPage,
    });
  }, [meta?.currentPage, meta?.pageCount]);

  const getPaginationLink = (incremental: number) => {
    const queryParams = window.location.search || '';
    const incrementPage = pagination.currentPage + incremental;
    const pageNumberClamped = Math.min(
      Math.max(incrementPage, 1),
      pagination.pageCount
    );
    const pageIndicator =
      pageNumberClamped > 1 ? `./page/${pageNumberClamped}` : './';
    return `${pageIndicator}${queryParams}`;
  };

  return (
    <Pagination
      name="WORK_FEED_PAGINATION"
      currentPage={pagination.currentPage}
      totalPages={pagination.pageCount}
      nextButton={
        <IconButton
          icon="arrow-right"
          name="pagination-next-button"
          variation="minimal"
          pattern="secondary"
          to={getPaginationLink(1)}
        />
      }
      prevButton={
        <IconButton
          icon="arrow-left"
          name="pagination-prev-button"
          pattern="secondary"
          variation="minimal"
          to={getPaginationLink(-1)}
        />
      }
    />
  );
};
