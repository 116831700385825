import { EnvUtil } from '../utils/env-util';

export default {
  getAutoCompletions: async query => {
    if (query.trim().length === 0) return [];

    const encodedQuery = window.encodeURIComponent(query.trim());
    const response = await window.fetch(
      new window.Request(`${EnvUtil.clearbitAutocompleteApiUrl}${encodedQuery}`)
    );
    return response.json();
  },

  getLogo: async domain => {
    const response = await window.fetch(
      new window.Request(`https://logo.clearbit.com/${domain}`)
    );
    return response.json();
  },
};
