import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, GridColumn, GridRow, Gutter, useApi } from '@axiom/ui';
import { CandidateProfileIssuesUtils } from '@axiom/utils';

import { ProfileHeader } from '../ProfileHeader/ProfileHeader';
import { ProfileCompletionChecklist } from '../ProfileCompletionChecklist/ProfileCompletionChecklist';
import { ProfileSummary } from '../ProfileSummary/ProfileSummary';
import { Experiences } from '../Experiences/Experiences';
import { Education } from '../Education/Education';
import { Accomplishments } from '../Accomplishments/Accomplishments';
import { Languages } from '../Languages/Languages';
import { LegalTechSkills } from '../LegalTechSkills/LegalTechSkills';
import { PreloadedPermissionsStore } from '../../stores/preloaded-permissions-store';
import { AvailabilityPreferences } from '../AvailabilityPreferences/AvailabilityPreferences';
import { ProfileTalentPartner } from '../ProfileTalentPartner/ProfileTalentPartner';
import { UsefulLinks } from '../UsefulLinks/UsefulLinks';
import { UserApi } from '../../api/protected/user/user';
import { CandidateLegacyApi } from '../../api/protected/candidates/candidate-legacy';
import { CandidateUtil } from '../../utils/candidate-util';
import { CandidateOpportunitiesLegacyApi } from '../../api/protected/candidates/candidate-opportunities-legacy';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

const { canViewAvailabilityPanel, canViewEmployeeItems } =
  PreloadedPermissionsStore;

export const ProfileView = () => {
  const { candidateId: urlCandidateId } = useParams();
  const permissions = useSelector(state =>
    PreloadedPermissionsStore.select(state)
  );
  const [{ data: user }] = useApi(UserApi.readUser());
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [
    { data: candidate },
    { data: candidateOpportunities },
    { data: profileIssues },
  ] = useApi(
    CandidateLegacyApi.readCandidate(candidateId),
    CandidateOpportunitiesLegacyApi.readOpportunitiesByCandidateId(candidateId),
    CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId)
  );

  return (
    <Grid name="profile-view">
      <GridRow>
        {canViewEmployeeItems(permissions) ? (
          <GridColumn
            largeScreenWidth={4}
            tabletWidth={4}
            mobileWidth={12}
            name="profile-view-employee-items"
          >
            {canViewAvailabilityPanel(permissions) && (
              <Gutter
                bottom="16px"
                name="profile-view-availability-preferences"
              >
                <AvailabilityPreferences />
              </Gutter>
            )}
            <ProfileTalentPartner candidate={candidate} />
            <Gutter bottom="16px" />
            <UsefulLinks />
            <Gutter bottom="16px" />
          </GridColumn>
        ) : (
          <GridColumn
            largeScreenWidth={2}
            tabletWidth={2}
            name="profile-view-empty-left-column"
          />
        )}
        <GridColumn largeScreenWidth={8} tabletWidth={8} mobileWidth={12}>
          <ProfileHeader candidate={candidate} />
          <Gutter bottom="16px" />
          {CandidateProfileIssuesUtils.shouldShowProfileCompletionSection(
            candidate,
            candidateOpportunities,
            profileIssues.issues
          ) && (
            <Gutter
              bottom="16px"
              name="profile-view-profile-completion-checklist"
            >
              <ProfileCompletionChecklist candidateId={candidate.id} />
            </Gutter>
          )}
          <ProfileSummary candidateId={candidate.id} />
          <Gutter bottom="16px" />
          <Experiences candidateId={candidate.id} />
          <Gutter bottom="16px" />
          <LegalTechSkills candidateId={candidate.id} />
          <Gutter bottom="16px" />
          <Education candidate={candidate} />
          <Gutter bottom="16px" />
          <Accomplishments candidate={candidate} />
          <Gutter bottom="16px" />
          <Languages candidateId={candidate.id} />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
