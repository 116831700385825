import { Api } from '@axiom/ui';
import { CandidateMfaPhoneNumber } from '@axiom/validation';

import { EnvUtil } from '../../../utils/env-util';

class CandidateApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readCandidateMfaPhoneNumber(candidateId: string) {
    return super.read<{ data: CandidateMfaPhoneNumber }>({
      endpoint: `/candidates/${candidateId}/mfaPhoneNumber`,
      method: 'GET',
    });
  }

  deleteCandidateMfaPhoneNumber(candidateId: string) {
    return super.write<{ data: CandidateMfaPhoneNumber }>({
      endpoint: `/candidates/${candidateId}/mfaPhoneNumber`,
      method: 'DELETE',
    });
  }

  refreshCandidateMfaPhoneNumber(candidateId: string) {
    return super.invalidate(`/candidates/${candidateId}/mfaPhoneNumber`);
  }
}

export const CandidateApi = new CandidateApiClass();
