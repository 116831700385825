import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout';
import { ProfileVisibilityPreferencesView } from '../components/PreferencesViews/ProfileVisibilityPreferencesView';

export const ProfileVisibilityPreferencesPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="PROFILE-VISIBILITY-PREFERENCES-PAGE">
      <ProfileVisibilityPreferencesView />
    </PageLayout>
  </TalentDataProvider>
);
