import React from 'react';
import { Flashy, Gutter } from '@axiom/ui';

export const ExperienceDescriptionGuidelines = () => {
  return (
    <Gutter top="24px" right="24px">
      <ul>
        <li>
          <Flashy bold>For experiences within last 10 years:</Flashy>
          <ul>
            <li>
              If the experience was longer than 9 months, write at least 3
              bullet points and 300 characters.
            </li>
            <li>
              If the experience was shorter than 9 months, write at least 1
              bullet point and 100 characters.
            </li>
          </ul>
          <Gutter bottom="16px" />
        </li>
        <li>
          <Flashy bold>For experiences older than 10 years:</Flashy>
          <ul>
            <li>Write at least 1 bullet point and 100 characters.</li>
          </ul>
          <Gutter bottom="16px" />
        </li>
        <li>
          <Flashy bold>
            Experiences that are not relevant to getting Axiom Engagements do
            not need to be included.
          </Flashy>
          <Gutter bottom="16px" />
        </li>
        <li>
          <Flashy bold>
            Bullet points should be comprehensive and concise, describing the
            type of work you did.
          </Flashy>{' '}
          For example, specifically mention the types of contracts you were
          involved in reviewing, drafting, negotiating, or working on. Be sure
          to highlight significant projects you successfully completed.
          <Gutter bottom="16px" />
        </li>
        <li>
          <Flashy bold>
            Prioritize precision over vagueness when crafting descriptions.
          </Flashy>{' '}
          Ensure that the language used is both specific and accurate. Avoid
          descriptions like "Vendor/Tech agreements" or "L&E support."
          <Gutter bottom="16px" />
        </li>
        <li>
          <Flashy bold>Maintain a consistent and uniform appearance</Flashy> in
          terms of headings, spacing, and bullet points throughout.
          <Gutter bottom="16px" />
        </li>
        <li>
          <Flashy bold>
            Select powerful, vivid, and highly descriptive verbs
          </Flashy>{' '}
          that effectively convey the core responsibilities and workstreams of
          the engagement.
          <Gutter bottom="16px" />
        </li>
        <li>
          <Flashy bold>
            Ensure that the tense used for all past work experiences is
            consistent and uniform across the entire bio.
          </Flashy>{' '}
          Avoid having one former engagement as "Drafting contracts" whereas
          another engagement reads "Assisted on procurement contracts."
        </li>
      </ul>
    </Gutter>
  );
};
