import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import {
  Button,
  Card,
  CardHeader,
  CardSection,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SmallHeader,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { EducationUtil } from '../../utils/education-util';

import { EducationBannerPrompt } from './EducationBannerPrompt';
import { EducationDeleteModal } from './EducationDeleteModal';
import { EducationEditModal } from './EducationEditModal';

export const Education = ({ candidate }: { candidate: Candidate }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [educationDeleteId, setEducationDeleteId] = useState(null);
  const [educationEdit, setEducationEdit] = useState(null);
  const education = orderBy(
    candidate.degrees,
    ['isPrimary', 'yearAwarded', 'institution'],
    ['desc', 'desc', 'asc']
  );

  useEffect(() => {
    if (searchParams.get('modal') === 'education') {
      setEducationEdit({});
      setSearchParams({}, { replace: true });
    }
  }, [searchParams]);

  return (
    <Card name="EDUCATION">
      <CardHeader>
        <Layout position="middle">
          <LayoutItem fluid>
            <SmallHeader>Education</SmallHeader>
          </LayoutItem>
          <LayoutItem>
            <Button
              name="EDUCATION_ADD_BUTTON"
              pattern="secondary"
              variation="minimal"
              icon="plus"
              onClick={() => {
                setEducationEdit({});
              }}
            >
              Add
            </Button>
          </LayoutItem>
        </Layout>
      </CardHeader>
      {education.length === 0 && (
        <CardSection>
          <EducationBannerPrompt
            onOpenEdit={() => {
              setEducationEdit({});
            }}
          />
          <Gutter bottom="16px" />
        </CardSection>
      )}
      {education?.map(edu => {
        return (
          <CardSection name="EDUCATION_ITEM" key={edu.id} divider>
            <Layout position="middle">
              <LayoutItem fluid>
                <ParagraphHeader name="INSTITUTION">
                  {edu.institution}
                </ParagraphHeader>
              </LayoutItem>
              <LayoutItem>
                <Layout horizontalGutter="8px" position="middle">
                  {!edu.isPrimary && (
                    <IconButton
                      icon="trash"
                      pattern="secondary"
                      variation="minimal"
                      name="DELETE_EDUCATION_BUTTON"
                      onClick={() => {
                        setEducationDeleteId(edu.id);
                      }}
                    />
                  )}
                  <IconButton
                    icon="pencil"
                    pattern="secondary"
                    variation="minimal"
                    name="EDIT_EDUCATION_BUTTON"
                    onClick={() => {
                      setEducationEdit(edu);
                    }}
                  />
                </Layout>
              </LayoutItem>
            </Layout>
            <Paragraph name="EDUCATION_DETAIL">
              {EducationUtil.formatEducationDetails(edu)}
            </Paragraph>
          </CardSection>
        );
      })}
      {educationDeleteId && (
        <EducationDeleteModal
          candidateId={candidate.id}
          educationId={educationDeleteId}
          onClose={() => setEducationDeleteId(null)}
        />
      )}
      {educationEdit && (
        <EducationEditModal
          educations={education}
          modifyEducation={educationEdit}
          candidateId={candidate.id}
          onClose={() => setEducationEdit(null)}
        />
      )}
    </Card>
  );
};
