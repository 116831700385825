import { CandidateAlertWorkFeed } from '@axiom/validation';
import { useApi, TaxonomyUtil } from '@axiom/ui';

import { WorkFeedFilterUtil } from '../../utils/work-feed-filter-util';
import { usePracticeAreaOptions } from '../../hooks/usePracticeAreaOptions';
import { FilterPill } from '../../types/work-feed-types';
import { TaxonomyApi } from '../../api/protected/taxonomy/taxonomy';

const {
  countryOptions,
  engagementLengthOptions,
  legalRoleOptions,
  weeklyCommitmentOptions,
  worksiteOptions,
} = WorkFeedFilterUtil;

export const useWorkFeedAlertsPills = (
  workFeedAlert: CandidateAlertWorkFeed
) => {
  const pills: FilterPill[] = [];
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());

  const practiceAreaOptions = usePracticeAreaOptions();
  const industryOptions = TaxonomyUtil.getIndustryOptions(taxonomy.industries);

  const config = [
    { name: 'practiceAreas', options: practiceAreaOptions },
    { name: 'industries', options: industryOptions },
    { name: 'weeklyCommitments', options: weeklyCommitmentOptions },
    { name: 'countries', options: countryOptions },
    { name: 'worksites', options: worksiteOptions },
    { name: 'engagementLengths', options: engagementLengthOptions },
    { name: 'legalRoles', options: legalRoleOptions },
  ] as const;

  config.forEach(({ name, options }) => {
    const filters = workFeedAlert[name];
    if (filters) {
      filters.forEach(v =>
        pills.push({ ...options.find(opt => opt.value === v), name })
      );
    }
  });

  return pills;
};
