import React from 'react';
import { shape, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { Banner, Card, CardHeader, CardSection, SmallHeader } from '@axiom/ui';

import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { CandidateOpportunitiesStore } from '../../stores/protected/candidate-opportunities-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';

import { CurrentEngagementItem } from './CurrentEngagementItem';

const CurrentEngagementComponent = ({
  opportunities,
  candidate,
  opportunitiesState,
  practiceAreas,
}) => {
  return (
    <Card name="CURRENT-ENGAGEMENT">
      <CardHeader>
        <SmallHeader>Active Engagements</SmallHeader>
      </CardHeader>

      <StoreStateTreatment
        name="CURRENT_ENGAGEMENT_TREATMENT"
        storeState={opportunitiesState}
        renderLoadedView={() => (
          <>
            {opportunities.map(engagement => (
              <CurrentEngagementItem
                key={engagement.id}
                engagement={engagement}
                candidate={candidate}
                practiceAreas={practiceAreas}
              />
            ))}
            {opportunities.length === 0 && (
              <CardSection>
                <Banner type="info">
                  You are currently not engaged with any clients through Axiom.
                  Once you are, you'll find important information about that
                  engagement here.
                </Banner>
              </CardSection>
            )}
          </>
        )}
      />
    </Card>
  );
};

CurrentEngagementComponent.propTypes = {
  opportunities: arrayOf(shape({})).isRequired,
  opportunitiesState: CandidateOpportunitiesStore.getStateShape().isRequired,
  candidate: shape({}).isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
};

export const CurrentEngagement = connect(
  state => ({
    opportunities:
      CandidateOpportunitiesStore.selectCurrentOpportunities(state),
    opportunitiesState: CandidateOpportunitiesStore.select(state),
    practiceAreas: PreloadedPracticeAreasStore.selectData(state),
    candidate: CurrentCandidateStore.selectCandidate(state),
  }),
  null
)(CurrentEngagementComponent);
