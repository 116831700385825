import GenericStore from '../../classes/generic-store';
import { talentShape } from '../../models/talent';

import { CurrentCandidateStore } from './current-candidate-store';

// This is a modern version of the CurrentCandidateStore that uses the GenericStore
// Selecting data using the CurrentCandidateStore can cause some re-rendering issues
// CandidateStore was added to be compatible with StoreStateTreatment
// CurrentCandidateStore loads in the candidate object into the CandidateStore
class CandidateStoreClass extends GenericStore {
  load(candidate) {
    return this.setState(candidate);
  }

  getDataShape() {
    return talentShape;
  }

  selectData(state) {
    return this.select(state)?.data || [];
  }

  refresh(candidateId) {
    return this.dispatch(
      CurrentCandidateStore.actionFetchCandidate(candidateId)
    );
  }
}

export const CandidateStore = new CandidateStoreClass();
