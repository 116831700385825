import { Position } from '@axiom/validation';

const getRequiredBillingHoursPerWeek = (positions: Position[]) => {
  const positionsHours = (positions ?? []).reduce(
    (acc, { billingHoursPerWeek = null }) =>
      billingHoursPerWeek !== null ? [...acc, billingHoursPerWeek] : acc,
    [] as number[]
  );
  return positionsHours.length ? Math.ceil(Math.min(...positionsHours)) : null;
};

export const MlRecommendedUtil = {
  getRequiredBillingHoursPerWeek,
};
