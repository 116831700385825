import React from 'react';

import { DigitalSubmissionView } from '../components/DigitalSubmissionView/DigitalSubmissionView';
import { DigitalSubmissionDataProvider } from '../providers/DigitalSubmissionDataProvider';
import { PrintStylesWrapper } from '../themes/components';
import { PageLayout } from '../layouts/PageLayout';

export const DigitalSubmissionsPage = () => (
  <DigitalSubmissionDataProvider>
    <PrintStylesWrapper>
      <PageLayout name="DIGITALSUBMISSIONPAGE">
        <DigitalSubmissionView />
      </PageLayout>
    </PrintStylesWrapper>
  </DigitalSubmissionDataProvider>
);

DigitalSubmissionsPage.propTypes = {};
