import { PositionUtil } from './position-util';

export const OpportunityUtil = {
  getRole: opportunity => {
    if (!opportunity || !opportunity.position) {
      return '';
    }
    return PositionUtil.getRole(opportunity.position);
  },
  getWeeklyTimeCommitment: (candidate, opportunity) => {
    return PositionUtil.getWeeklyTimeCommitment(
      candidate,
      opportunity.position
    );
  },
  getContact: opportunity => {
    const { ownerUser } = opportunity;

    if (ownerUser) {
      const { email, fullName } = ownerUser;
      return {
        email,
        fullName,
      };
    }

    return null;
  },
  transformDisplayOpportunity: (candidate, opportunity) => {
    opportunity = { ...opportunity };
    if (!opportunity.position) {
      opportunity.position = {};
    }

    return {
      ...opportunity,
      description: opportunity.position.description,
      onsiteRemote: opportunity.position.worksite,
      role: OpportunityUtil.getRole(opportunity),
      startDate: opportunity.position.startDate,
      estimatedEngagementTotalMonths:
        opportunity.position.estimatedEngagementTotalMonths,
      weeklyTimeCommitment: OpportunityUtil.getWeeklyTimeCommitment(
        candidate,
        opportunity
      ),
    };
  },
};
