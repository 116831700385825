import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import isUuid from 'is-uuid';
import { func, node, shape } from 'prop-types';

import { CurrentCandidateStore } from '../stores/protected/current-candidate-store';
import { PreloadedPracticeAreasStore } from '../stores/preloaded-practice-areas-store';
import { PreloadedBarredLocationsStore } from '../stores/preloaded-barred-locations-store';
import { AppStore } from '../stores/app-store';
import RecordStateHelperTreatment from '../components/RecordStateHelperTreatment/RecordStateHelperTreatment';
import StoreStateTreatment from '../components/StoreStateTreatment/StoreStateTreatment';
import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import { LoadingCurtain } from '../components/LoadingCurtain/LoadingCurtain';
import { UserStore } from '../stores/protected/user-store';

const TalentDataProviderComponent = ({
  user,
  candidateState,
  initTalentAppState,
  children,
  practiceAreasState,
  barredLocationState,
}) => {
  const { candidateId } = useParams();

  useEffect(() => {
    const impersonateCandidateId =
      user.roles.some(r => r.startsWith('Envoy')) &&
      isUuid.anyNonNil(candidateId)
        ? candidateId
        : user.candidateId;

    initTalentAppState(impersonateCandidateId);
  }, [candidateId]);

  return (
    <RecordStateHelperTreatment
      recordState={candidateState}
      initialLoader={<LoadingTreatment />}
      refetchLoader={<LoadingCurtain isFullscreen />}
    >
      <StoreStateTreatment
        storeState={[practiceAreasState, barredLocationState]}
        renderLoadedView={() => children}
      />
    </RecordStateHelperTreatment>
  );
};

TalentDataProviderComponent.propTypes = {
  user: UserStore.getDataShape().isRequired,
  candidateState: shape({}).isRequired,
  children: node,
  initTalentAppState: func.isRequired,
  practiceAreasState: shape({}).isRequired,
  barredLocationState: shape({}).isRequired,
};

TalentDataProviderComponent.defaultProps = {
  children: null,
};

export const TalentDataProvider = connect(
  state => ({
    user: UserStore.selectData(state),
    candidateState: CurrentCandidateStore.selectCandidateState(state),
    practiceAreasState: PreloadedPracticeAreasStore.select(state),
    barredLocationState: PreloadedBarredLocationsStore.select(state),
  }),
  {
    initTalentAppState: AppStore.initTalentAppState,
  }
)(TalentDataProviderComponent);
