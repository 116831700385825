import React from 'react';
import { Grid, GridColumn, GridRow, Gutter } from '@axiom/ui';

import { CurrentEngagement } from '../CurrentEngagement/CurrentEngagement';
import { NewOpportunities } from '../NewOpportunities/NewOpportunities';
import { Submissions } from '../Submissions/Submissions';

export const EngagementsView = () => {
  return (
    <Grid name="ENGAGEMENTS-VIEW">
      <GridRow>
        <GridColumn largeScreenWidth={4} tabletWidth={4} mobileWidth={12}>
          <CurrentEngagement />
          <Gutter bottom="24px" />
        </GridColumn>
        <GridColumn largeScreenWidth={8} tabletWidth={8} mobileWidth={12}>
          <NewOpportunities />
          <Submissions />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
